import { setControlSubmission, setControlSubmissionIsLoading } from "../../store/slices/controlSubmissionSlice"
import { axiosInstance } from "../config/https"

export const getControlSubmissions = () => {
	return async dispatch => {
		dispatch(setControlSubmissionIsLoading(true))
		const res = await axiosInstance("submission/control")

		if (res.status === 200) {
			dispatch(setControlSubmission(res.data.controlSubmissions))
		}
		dispatch(setControlSubmissionIsLoading(false))
	}
}
