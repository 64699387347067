import React, { useEffect, useRef, useState } from "react"
import { axiosInstance } from "../../config/https"
import styles from "./index.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "../../functions/toast"
import { Loader } from "../Loader"
import { useLocation, useNavigate } from "react-router-dom"
import { SvgSprite } from "../SvgSprite"
import cn from "classnames"
import { ProfileImage } from "../ProfileImage/ProfileImage"
import { Btn } from "../Btn"
import favicon from "../../../assets/images/favicon.ico"

function Gpt3Form({
	activeChat,
	ListMessages,
	getChats,
	setActiveChat,
	question,
	secondQuestion,
	setQuestion,
	setSecondQuestion,
}) {
	const currentUser = useSelector(state => state.user.userInfo)
	const [Loading, setLoading] = useState(false)
	const dispatch = useDispatch()
	const location = useLocation()
	const [models] = useState(["gpt", "claude"])
	const [messages, setMessages] = useState([])
	const [inputValue, setInputValue] = useState("")
	const users = useSelector(state => state.organization.org.users)
	const scrollableRef = useRef(null)
	const [createResource, setCreateResource] = useState(false)
	const [resourceContent, setResourceContent] = useState(null)
	const navigate = useNavigate()

	const updateChatHistory = (question, response) => {
		setMessages(prevChatHistory => {
			const updatedChatHistory = prevChatHistory.map(chatMessage => {
				if (chatMessage.question === question) {
					return {
						question: chatMessage.question,
						response: response,
					}
				} else {
					return chatMessage
				}
			})
			return updatedChatHistory
		})
	}

	const handleSubmit = async () => {
		try {
			setLoading(true)

			if (!inputValue.length > 0) {
				setLoading(false)
				return
			}

			setMessages([...messages, { question: inputValue, response: "", sender: currentUser.id }])

			let path = "ask/claude"
			const searchParams = new URLSearchParams(location.search)
			const testModel = searchParams.get("test_model")

			if (models.includes(testModel?.toLowerCase())) {
				path = `ask/${testModel?.toLowerCase()}`
			}
			let callChat = activeChat?.id || false

			const data = {
				prompt: inputValue,
				title: inputValue,
				chat_id: activeChat?.id || null,
			}
			setInputValue("")
			await axiosInstance
				.post(path, data)
				.then(res => {
					updateChatHistory(inputValue, res.data.answer)
					setActiveChat(res.data.chat)
					if (secondQuestion?.length > 0) {
						setQuestion(secondQuestion)
						setSecondQuestion("")
					} else {
						setQuestion("")
					}
					if (!callChat) {
						getChats()
					}
				})
				.catch(err => {
					dispatch(toast("error", err?.response?.data?.message))
				})
				.finally(() => {
					setLoading(false)
				})
		} catch (error) {
			dispatch(toast("error", error))
			setLoading(false)
		}
	}

	useEffect(() => {
		setMessages([...ListMessages])
	}, [ListMessages])

	useEffect(() => {
		if (question) {
			setInputValue(question)
		}
	}, [question])

	useEffect(() => {
		const scrollable = scrollableRef?.current
		scrollable.scrollTop = scrollable?.scrollHeight
	}, [messages])

	const autoResize = element => {
		element.style.height = "auto"
		element.style.height = element.scrollHeight + "px"
	}

	useEffect(() => {
		if (question) {
			setLoading(true)
			handleSubmit()
			window.history.replaceState({}, document.title)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question, inputValue])

	const navigateToResource = () => {
		var formattedText = resourceContent.replace(/\n/g, "<br />")
		navigate("/resources", { state: formattedText })
	}
	return (
		<div className={styles.chat}>
			<div
				ref={scrollableRef}
				className={styles.chatHistory}
			>
				{messages.map((message, index) => (
					<div
						key={index}
						className={styles.chatMessage}
					>
						<div className={styles.messageUser}>
							<div className={styles.userIconDive}>
								<ProfileImage
									name={
										(users?.find(user => user.id === activeChat?.user_id)?.firstName || currentUser?.firstName) +
										" " +
										(users?.find(user => user.id === activeChat?.user_id)?.lastName || currentUser?.lastName)
									}
									className={styles.userIcon}
								/>
							</div>
							<div className={styles.textName}>
								<div className={styles.userName}>
									{(users?.find(user => user.id === activeChat?.user_id)?.firstName || currentUser?.firstName) +
										" " +
										(users?.find(user => user.id === activeChat?.user_id)?.lastName || currentUser?.lastName)}
								</div>
								<div className={styles.text}>{message.question}</div>
							</div>
						</div>
						<div className={styles.messageUser}>
							<div className={styles.userIconDive}>
								<ProfileImage
									name={"SecureLabs AI"}
									className={styles.userIcon}
								/>
							</div>
							{message?.response ? (
								<div className={styles.textName}>
									<div className={styles.userName}> SecureLabs AI </div>
									{/* <div className={styles.text} dangerouslySetInnerHTML={{
                                  __html: message.response
                              }}/> */}
									<div
										className={styles.relativDiv}
										onMouseEnter={() => {
											setCreateResource(message?.id)
											setResourceContent(message.response)
										}}
										onMouseLeave={() => {
											setCreateResource(false)
											setResourceContent(null)
										}}
									>
										<pre className={styles.text}>
											<div dangerouslySetInnerHTML={{ __html: message.response }}></div>
										</pre>
										{createResource === message?.id && (
											<Btn
												className={styles.toResourceBtn}
												onClick={navigateToResource}
											>
												+ Resource
											</Btn>
										)}
									</div>
								</div>
							) : (
								<div className={styles.dotsLoader}>
									<div className={cn(styles.dot, styles.dot1)}></div>
									<div className={cn(styles.dot, styles.dot2)}></div>
									<div className={cn(styles.dot, styles.dot3)}></div>
								</div>
							)}
						</div>
					</div>
				))}
				{messages?.length === 0 && (
					<div className={styles.chatLogo}>
						<img
							src={favicon}
							alt="Logo Chat"
						/>
						<div>How can I help you today?</div>
					</div>
				)}
			</div>
			<div className={styles.formInput}>
				<textarea
					type="text"
					value={inputValue}
					onChange={e => setInputValue(e.target.value)}
					placeholder="Type a message..."
					className={cn(styles.chatInput, styles.inputStyle)}
					rows="1"
					onInput={e => autoResize(e.currentTarget)}
				/>
				<button
					type="submit"
					className={styles.chatButton}
					onClick={() => handleSubmit()}
				>
					{Loading ? (
						<Loader />
					) : (
						<SvgSprite
							spriteID={"send"}
							className={styles.inputSend}
						/>
					)}
				</button>
			</div>
		</div>
	)
}

export default Gpt3Form
