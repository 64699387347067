import React, { useState } from "react"
import styles from "./index.module.scss"
import cn from "classnames"
import { SvgSprite } from "../../ui/SvgSprite"
import { axiosInstance } from "../../config/https"
import { toast } from "../../functions/toast"
import { useDispatch } from "react-redux"

export const AddControl = ({ closePopup, copyControlData, getAllControle }) => {
	const dispatch = useDispatch()

	const [name, setName] = useState(copyControlData?.name ?? "")
	const [description, setDescription] = useState(copyControlData?.description ?? "")
	const [category, setCategory] = useState(copyControlData?.category ?? "")
	const [controlQuestions, setControlQuestions] = useState(copyControlData?.controlQuestions ?? "")
	const [complyMethods, setComplyMethods] = useState(copyControlData?.complyMethods ?? "")
	const [evidenceCollection, setEvidenceCollection] = useState(copyControlData?.evidenceCollection ?? "")
	const [controlFunction, setControlFunction] = useState(copyControlData?.control_function ?? "")
	const [controlRisk, setControlRisk] = useState(copyControlData?.control_risk ?? "")

	const [addControlLoader, setAddControlLoader] = useState(false)

	const categories = [
		"Asset Management",
		"Business Continuity & Disaster Recovery",
		"Capacity & Performance Planning",
		"Change Management",
		"Cloud Security",
		"Compliance",
		"Configuration Management",
		"Continuous Monitoring",
		"Cryptographic Protections",
		"Data Classification & Handling",
		"Embedded Technology",
		"Endpoint Security",
		"Human Resources Security",
		"Identification & Authentication",
		"Incident Response",
		"Information Assurance",
		"Maintenance",
		"Mobile Device Management",
		"Network Security",
		"Physical & Environmental Security",
		"Privacy",
		"Project & Resource Management",
		"Risk Management",
		"Secure Engineering & Architecture",
		"Security & Privacy Governance",
		"Security Awareness & Training",
		"Security Operations",
		"Technology Development & Acquisition",
		"Third-Party Management",
		"Threat Management",
		"Vulnerability & Patch Management",
		"Web Security",
	]

	const controlFunctions = ["Detect", "Identify", "Protect", "Recover", "Respond"]

	const controlRisks = ["High", "Minor", "Moderate", "Negligible", "Significant"]

	const handleSubmit = async () => {
		setAddControlLoader(true)

		const data = {
			name: name,
			description: description,
			category: category,
			controlQuestions: controlQuestions,
			complyMethods: complyMethods,
			evidenceCollection: evidenceCollection,
			control_function: controlFunction,
			control_risk: controlRisk,
		}
		await axiosInstance
			.post("control", data)
			.then(res => {
				getAllControle()
			})
			.then(() => {
				closePopup()
				setAddControlLoader(false)
				dispatch(toast("success", "Control created Successfully"))
			})
			.catch(err => {
				console.log(err)
				let msg = ""
				if (err?.response?.status === 413) msg = err?.response?.statusText || ""
				else msg = err?.response?.data?.message || ""

				dispatch(toast("error", msg))
			})
	}

	return (
		<div className={styles.addPopup}>
			<div className={styles.controlPopupHeader}>
				<div>New Control</div>
				<div className={styles.BreakdownLeftHeader}>
					<SvgSprite
						spriteID={"close"}
						className={styles.closeIconPopup}
						onClick={closePopup}
					/>
				</div>
			</div>
			<div className={cn(styles.controlPopupBodyGod, styles.flexderec, styles.addscroll)}>
				<div className={styles.formContainer}>
					<form
						onSubmit={e => {
							e.preventDefault()
							handleSubmit()
						}}
					>
						<div className={styles.formGroup}>
							<label htmlFor="name">Name:</label>
							<input
								type="text"
								id="name"
								name="name"
								required
								onChange={e => setName(e.target.value)}
								value={name}
							/>
						</div>
						<div className={styles.formGroup}>
							<label htmlFor="description">Description:</label>
							<textarea
								id="description"
								name="description"
								rows="4"
								required
								onChange={e => setDescription(e.target.value)}
								value={description}
							></textarea>
						</div>
						<div className={styles.formGroup}>
							<label htmlFor="category">Category:</label>
							<select
								id="category"
								name="category"
								required
								onChange={e => setCategory(e.target.value)}
								value={category}
							>
								<option value="">Select a category</option>
								{categories.map((c, i) => (
									<option
										key={i}
										value={c}
									>
										{c}
									</option>
								))}
							</select>
						</div>
						<div className={styles.formGroup}>
							<label htmlFor="description">Control Questions:</label>
							<textarea
								id="description"
								name="description"
								rows="4"
								required
								onChange={e => setControlQuestions(e.target.value)}
								value={controlQuestions}
							></textarea>
						</div>
						<div className={styles.formGroup}>
							<label htmlFor="description">Comply Methods:</label>
							<textarea
								id="description"
								name="description"
								rows="4"
								required
								onChange={e => setComplyMethods(e.target.value)}
								value={complyMethods}
							></textarea>
						</div>
						<div className={styles.formGroup}>
							<label htmlFor="description">Evidence Collection:</label>
							<textarea
								id="description"
								name="description"
								rows="4"
								required
								onChange={e => setEvidenceCollection(e.target.value)}
								value={evidenceCollection}
							></textarea>
						</div>
						<div className={styles.formGroup}>
							<label htmlFor="category">Control Function:</label>
							<select
								id="category"
								name="category"
								required
								onChange={e => setControlFunction(e.target.value)}
								value={controlFunction}
							>
								<option value="">Select a control function</option>
								{controlFunctions.map((c, i) => (
									<option
										key={i}
										value={c}
									>
										{c}
									</option>
								))}
							</select>
						</div>
						<div className={styles.formGroup}>
							<label htmlFor="category">Control Risk:</label>
							<select
								id="category"
								name="category"
								required
								onChange={e => setControlRisk(e.target.value)}
								value={controlRisk}
							>
								<option value="">Select a control risk</option>
								{controlRisks.map((c, i) => (
									<option
										key={i}
										value={c}
									>
										{c}
									</option>
								))}
							</select>
						</div>
						<div className={styles.formGroup}>
							<button
								type="submit"
								disabled={addControlLoader}
							>
								{addControlLoader ? "Submitting..." : "Submit"}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}
