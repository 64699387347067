import React, { useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import styles from "./index.module.scss"
import cn from "classnames"
// components
import { Btn } from "../../ui/Btn"
import { Input } from "../../ui/Input"
// axois
import axios from "axios"
// hooks
import { useInput } from "../../hooks/useInput"
// functions
import { checkErrors } from "../../functions/checkErrors"
// API_URL
import { config } from "../../utils/Constants"
import { toast } from "../../functions/toast"
import { useDispatch } from "react-redux"
import { Loader } from "../../ui/Loader"

export const ResetPassword = () => {
	const password = useInput()
	const confirmPassword = useInput()
	const [queryParameters] = useSearchParams()
	const [errors, setErrors] = useState({})
	const [loader, setLoader] = useState(false)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const resetPwd = async () => {
		setLoader(true)
		const inputsInfo = [
			{ el: password, errorName: "password", errorText: "Enter Password" },
			{ el: confirmPassword, errorName: "confirmPassword", errorText: "Confirm Password" },
		]

		if (await checkErrors(setErrors, inputsInfo)) {
			setLoader(false)
			return
		}

		if (password.value !== confirmPassword.value) {
			dispatch(toast("error", "Passwords Do Not Match"))
			setLoader(false)
			return
		}

		const data = {
			token: queryParameters.get("token"),
			password: password.value,
			password_confirmation: confirmPassword.value,
			email: decodeURIComponent(queryParameters.get("email")),
		}

		await axios
			.post(`${config.url.API_URL}/api/reset-password`, data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(res => {
				if (res.data.success) dispatch(toast("success", "Password Updated With Success"))
				navigate("/auth/login")
			})
			.catch(err => {
				dispatch(toast("error", err?.response?.data?.message))
				setLoader(false)
			})
			.finally(() => {
				setLoader(false)
			})
	}

	return (
		<div className={styles.container}>
			<div className={styles.form}>
				<div className={styles.logo}></div>
				<div className={styles.Header}> Reset Your Password</div>

				<div className={styles.loginInputs}>
					<div className={styles.label}>Password</div>
					<Input
						onChange={password.onChange}
						type={"password"}
						className={styles.input}
						placeholder={"Password"}
						error={errors.password}
					/>

					<div className={styles.label}>Confirm Password</div>
					<Input
						onChange={confirmPassword.onChange}
						type={"password"}
						className={styles.input}
						placeholder={"Confirm Password"}
						error={errors.confirmPassword}
					/>
				</div>
				<div className={styles.loginBtn}>
					<Btn
						onClick={resetPwd}
						className={cn(styles.btn, styles.btnSubmit, styles.btnLoader)}
					>
						<div>Reset Password</div>
						{loader && <Loader />}
					</Btn>
				</div>
				<div className={styles.signUpLink}>
					<Link to={"/auth/login"}>Sign In</Link>
				</div>

				<footer className={styles.footer}>
					<nav>
						<ul className={styles.footerNav}>
							<li>
								<a href="https://app.termly.io/document/terms-of-use-for-website/f78e9e9b-bba1-4a94-983e-68a1005f28b0">
									Terms of Service
								</a>
							</li>
							<li>
								<a href="https://app.termly.io/document/privacy-policy/3754276d-7c3f-40ef-931d-584c9bc61e74">
									Privacy Policy
								</a>
							</li>
						</ul>
					</nav>
					<br></br>
					<h5>© 2023 SecureLabs Inc </h5>
				</footer>
			</div>
		</div>
	)
}
