export const AiQuestions = [
	{
		id: 1,
		question:
			"Can you provide an overview of the major security and privacy frameworks and controls that could apply to our organization?",
	},
	{ id: 2, question: "How does our organization comply with relevant security and privacy laws and regulations?" },
	{ id: 3, question: "Can you explain the difference between security and privacy and how they relate to each other?" },
	{
		id: 4,
		question: "How do we ensure that our employees are aware of and adhere to our security and privacy policies?",
	},
	{ id: 5, question: "What are the key risks to our organization's security and privacy, and how do we manage them?" },
	{
		id: 6,
		question:
			"Can you explain the role of risk assessments in determining our organization's security and privacy controls?",
	},
	{ id: 7, question: "How do we monitor and assess the effectiveness of our security and privacy controls?" },
	{
		id: 8,
		question:
			"How do we ensure that third-party vendors and partners comply with our security and privacy requirements?",
	},
	{
		id: 9,
		question: "What are the key components of a security and privacy program, and how do they relate to each other?",
	},
	{
		id: 10,
		question:
			"How do we ensure that our security and privacy program is aligned with our organization's overall business objectives?",
	},
	{
		id: 11,
		question: "Can you provide an overview of the security and privacy governance structure in our organization?",
	},
	{
		id: 12,
		question:
			"How do we ensure that security and privacy considerations are integrated into our overall business decision-making processes?",
	},
	{
		id: 13,
		question: "Can you explain the concept of security and privacy by design, and how it applies to our organization?",
	},
	{
		id: 14,
		question:
			"How do we ensure that our security and privacy policies and controls are regularly reviewed and updated to reflect changing threats and regulatory requirements?",
	},
	{
		id: 15,
		question:
			"Can you provide an overview of the incident response plan for security and privacy incidents in our organization?",
	},
	{ id: 16, question: "How do we ensure that our incident response plan is regularly tested and updated?" },
	{ id: 17, question: "What are the key elements of a security and privacy awareness training program?" },
	{
		id: 18,
		question: "How do we ensure that our employees are adequately trained on security and privacy best practices?",
	},
	{
		id: 19,
		question:
			"How do we ensure that our security and privacy monitoring is effective and aligned with our organization's risk appetite?",
	},
	{
		id: 20,
		question: "How do we manage identity and access management (IAM) to ensure appropriate access to systems and data?",
	},
	{
		id: 21,
		question: "Can you explain the role of encryption in protecting sensitive data, and how we manage encryption keys?",
	},
	{
		id: 22,
		question:
			"How do we manage vulnerability scanning and patch management to ensure timely identification and remediation of vulnerabilities?",
	},
	{
		id: 23,
		question:
			"What should our organization's network security controls, such as firewalls and intrusion detection systems look like?",
	},
	{ id: 24, question: "How do we ensure that our mobile device management (MDM) policies and controls are effective?" },
	{
		id: 25,
		question: "Can you explain the importance of data classification and how we classify our organization's data?",
	},
	{
		id: 26,
		question:
			"How do we manage data retention and destruction policies to ensure compliance with regulatory requirements?",
	},
	{ id: 27, question: "whats should our organization's incident management process look like?" },
	{ id: 28, question: "How do we ensure that our incident management process is effective and efficient?" },
	{ id: 29, question: "Can you explain the role of security and privacy audits in our organization?" },
	{ id: 30, question: "How do we ensure that our security and privacy audits are independent and effective?" },
	{
		id: 31,
		question:
			"How do we manage security and privacy risks associated with cloud services and other third-party providers?",
	},
	{
		id: 32,
		question:
			"What should an overview of our organization's disaster recovery and business continuity plans look like?",
	},
	{
		id: 33,
		question:
			"How do we ensure that our disaster recovery and business continuity plans are regularly tested and updated?",
	},
	{
		id: 34,
		question:
			"How do we manage security and privacy risks associated with remote work and bring your own device (BYOD) policies?",
	},
	{
		id: 35,
		question: "Can you explain the importance of incident response tabletop exercises, and how we conduct them?",
	},
	{ id: 36, question: "How do we manage security and privacy risks associated with mergers and acquisitions?" },
	{
		id: 37,
		question:
			"How do we ensure that our security and privacy requirements are incorporated into our contracts with third-party vendors and partners?",
	},
	{
		id: 38,
		question: "what should be provided in an overview of our organization's access control policies and procedures?",
	},
	{
		id: 39,
		question:
			"How do we manage security and privacy risks associated with social engineering attacks, such as phishing and spear-phishing?",
	},
	{
		id: 40,
		question:
			"How do we ensure that our organization's sensitive data is properly encrypted during transmission and storage?",
	},
	{
		id: 41,
		question:
			"Can you explain the role of security incident response teams in our organization, and how they are activated during an incident?",
	},
	{
		id: 42,
		question:
			"How do we ensure that our organization's security and privacy policies and procedures are aligned with industry best practices and standards?",
	},
	{
		id: 43,
		question:
			"What should an overview of our organization's physical security controls, such as access controls and surveillance look like?",
	},
	{
		id: 44,
		question:
			"How do we manage security and privacy risks associated with the use of personal devices for work purposes, such as smartphones and tablets?",
	},
	{
		id: 45,
		question:
			"Can you explain the importance of data backups in protecting against data loss and data breaches, and how we manage data backups?",
	},
	{
		id: 46,
		question: "How do we manage security and privacy risks associated with the use of social media in the workplace?",
	},
	{
		id: 47,
		question: "what does an overview of our organization's incident reporting and escalation process look like?",
	},
	{
		id: 48,
		question:
			"How do we ensure that our organization's security and privacy policies and controls are effectively communicated to all employees and stakeholders?",
	},
	{
		id: 49,
		question:
			"How do we ensure that our organization's security and privacy program is continuously improving and evolving to address emerging threats and new technologies?",
	},
	{ id: 50, question: "Explain (insert framework) Framework" },
	{ id: 51, question: "Discuss the Core Functions of (insert framework)" },
	{ id: 52, question: "Explain (insert framework) Controls" },
	{ id: 53, question: "Discuss the principle of least privilege" },
	{ id: 54, question: "Discuss the PCI DSS controls for credit card data security" },
	{ id: 55, question: "Explain the GDPR privacy requirements" },
	{ id: 56, question: "Explain the difference between confidentiality, integrity and availability" },
	{ id: 57, question: "Discuss the principle of separation of duties" },
	{ id: 58, question: "Explain the principle of role-based access control" },
	{ id: 59, question: "Discuss the principle of mandatory access control" },
	{ id: 60, question: "Explain encryption and its role in data security" },
	{ id: 61, question: "Discuss the risks of reusing passwords across systems" },
	{ id: 62, question: "Explain the risks of single factor authentication" },
	{ id: 63, question: "Discuss the benefits of multi-factor authentication" },
	{ id: 64, question: "Explain the risks of unpatched software vulnerabilities" },
	{ id: 65, question: "Discuss the role of penetration testing in cyber risk management" },
	{ id: 66, question: "Explain the role of an incident response plan" },
	{ id: 67, question: "Discuss the role of security awareness and training" },
	{ id: 68, question: "Explain the role of a Chief Information Security Officer" },
	{ id: 69, question: "Discuss the benefits of a well-defined security architecture" },
	{ id: 70, question: "Explain the concept of a \"crown jewel\" in cyber risk assessment" },
	{ id: 71, question: "Discuss the role of asset inventory and management" },
	{ id: 72, question: "Explain the risk of shadow IT systems and data" },
	{ id: 73, question: "Discuss the risk of insider threats" },
	{ id: 74, question: "Explain the risk of social engineering attacks" },
	{ id: 75, question: "Discuss the risk of ransomware attacks" },
	{ id: 76, question: "Explain the risk of business email compromise attacks" },
	{ id: 77, question: "Discuss the risk of nation-state cyber attacks" },
	{ id: 78, question: "Explain the role of threat intelligence in cyber risk management" },
	{ id: 79, question: "Discuss the benefits of a well-developed cyber incident response plan" },
	{ id: 80, question: "Explain the role of security monitoring and analytics" },
	{ id: 81, question: "Discuss the benefits of a password policy" },
	{ id: 82, question: "Explain the principle of data minimization" },
	{ id: 83, question: "Discuss privacy by design principles" },
	{ id: 84, question: "Explain personally identifiable information (PII)" },
	{ id: 85, question: "Discuss the role of data classification for security" },
	{ id: 86, question: "Explain risk assessment methods like FAIR" },
	{ id: 87, question: "Discuss the roles of risk appetite and risk tolerance" },
	{ id: 88, question: "Explain change management processes from a security perspective" },
	{ id: 89, question: "Discuss the benefits of executive management support for security programs" },
	{ id: 90, question: "Explain the relationship between cybersecurity and business continuity planning" },
	{ id: 91, question: "Discuss procurement lifecycle controls for vendor security risk" },
	{ id: 92, question: "Explain the role of audits, reviews and compliance assessments" },
	{ id: 93, question: "Discuss the benefits of well-defined key risk indicators and metrics" },
	{ id: 94, question: "Explain the importance of lessons learned reviews after incidents" },
	{ id: 95, question: "Discuss the role of tabletop exercises for security preparedness" },
]
