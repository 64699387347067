import React, { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { axiosInstance } from "../../config/https"
import { setCookie } from "../../utils/cookie"
import { useDispatch } from "react-redux"
import {
	setAccountPlan,
	setIsAuth,
	setNotificationNabr,
	setQrCode,
	setTeamSize,
	setUserInfo,
} from "../../../store/slices/userSlice"
import { toast } from "../../functions/toast"

export const GoogleCallback = ({ provider }) => {
	const [loading, setLoading] = useState(true)
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	useEffect(() => {
		axiosInstance(`auth/${provider}/callback${location.search}`)
			.then(async res => {
				if (res.data.org_account) {
					navigate("/auth/social/register", { state: res.data })
				} else if (res.data.subscription) {
					if (res.data.Two_Factor) {
						navigate("/auth/login/verify", { state: res.data })
					} else {
						setCookie("access_token", res.data.access_token, res.data.expires_in, true)
						dispatch(setUserInfo({ ...res.data.user }))
						dispatch(setQrCode(res.data.qr_code_url))
						dispatch(setAccountPlan(res.data?.account_plan))
						dispatch(setNotificationNabr(res.data.notifications_nbr))
						dispatch(setTeamSize(res.data.team_size))
						dispatch(setIsAuth(true))
					}
				} else {
					dispatch(setUserInfo({ ...res.data.user }))
					dispatch(setAccountPlan(res.data?.account_plan))
					dispatch(setTeamSize(res?.data?.team_size))
					navigate("/auth/payment", { state: res.data })
				}
			})
			.catch(err => {
				if (err?.response?.data?.errors) {
					for (const [key, value] of Object.entries(err?.response?.data?.errors)) {
						dispatch(toast("error", `${key}: ${value}`))
					}
				} else dispatch(toast("error", err?.response?.data?.message))
			})
		setLoading(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return <div>{loading && <div></div>}</div>
}
