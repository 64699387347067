export function filterControlsFn(controls, filterObject) {
	const storedFilter = JSON.parse(localStorage.getItem("controls-filter")) || []

	var data = controls?.filter(control => {
		return Object.entries(filterObject || storedFilter).every(([key, value]) => {
			if (value.length === 0) return true

			if (Array.isArray(control[key])) {
				return control[key].some(prop => value.includes(Number(prop) || prop))
			} else {
				return value.includes(Number(control[key]) || control[key])
			}
		})
	})

	return removeDuplicates(data, "control_id")
}

function removeDuplicates(array, idKey) {
	let seen = {}
	return array?.filter(item => {
		let id = item[idKey]
		return seen.hasOwnProperty(id) ? false : (seen[id] = true)
	})
}
