import React, { useState } from "react"
import cn from "classnames"
import { Search } from "../../../ui/Search/Search"
import { Btn } from "../../../ui/Btn"
import styles from "../index.module.scss"
import PropTypes from "prop-types"

export const ResourceHeader = ({ 
  activeResource, 
  changeResources, 
  setActiveRepository, 
  onSearchSubmit,
  onCreateResource,
  onUploadResource,
  onCreateFolder
}) => {
  const [showDrop, setShowDrop] = useState(false)

  return (
    <div className={styles.resourceHeader}>
      <div className={styles.resourceHeaderLeft}>
        <div
          className={cn(styles.btnResource, {
            [styles.active]: activeResource === 0,
          })}
          onClick={() => {
            changeResources(0)
            setActiveRepository(null)
          }}
        >
          Company Resources
        </div>
        <div
          className={cn(styles.btnResource, {
            [styles.active]: activeResource === 1,
          })}
          onClick={() => changeResources(1)}
        >
          SecureLabs Training & Education
        </div>
      </div>
      <div className={styles.resourceHeaderRight}>
        <Search
          setSearch={() => {}}
          onSubmit={onSearchSubmit}
          className={styles.searchInput}
          placeholder={"Search Resources..."}
        />
        {activeResource === 0 && (
          <div className={styles.addButtonWrapper}>
            <Btn
              className={styles.addButton}
              onClick={() => setShowDrop(!showDrop)}
            >
              +
            </Btn>
            {showDrop && (
              <div className={styles.dropdownMenu}>
                <div
                  className={styles.dropdownItem}
                  onClick={() => {
                    setShowDrop(false)
                    onUploadResource()
                  }}
                >
                  Upload Resources
                </div>
                <div
                  className={styles.dropdownItem}
                  onClick={() => {
                    onCreateResource()
                    setShowDrop(false)
                  }}
                >
                  New Resource
                </div>
                <div
                  className={styles.dropdownItem}
                  onClick={() => {
                    onCreateFolder()
                    setShowDrop(false)
                  }}
                >
                  Create folder
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

ResourceHeader.propTypes = {
  activeResource: PropTypes.number.isRequired,
  changeResources: PropTypes.func.isRequired,
  setActiveRepository: PropTypes.func.isRequired,
  onSearchSubmit: PropTypes.func.isRequired,
  onCreateResource: PropTypes.func.isRequired,
  onUploadResource: PropTypes.func.isRequired,
  onCreateFolder: PropTypes.func.isRequired
}
