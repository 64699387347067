import React, { useState } from "react"
import cn from "classnames"

import { SvgSprite } from "../SvgSprite/SvgSprite"

// Styles
import styles from "./index.module.scss"

export const Input = ({
	placeholder,
	register,
	forgotInput,
	forgotInputPwd,
	value,
	className,
	error,
	type,
	id,
	disabled,
	dark,
	noRedLine,
	comment,
	...typeHandle
}) => {
	const [passwordShown, setPasswordShown] = useState(false)

	const togglePasswordVisibility = () => setPasswordShown(!passwordShown)

	return (
		<div
			className={cn(styles.container, {
				[className]: className,
				[styles.inputWrapperError]: error && !noRedLine,
				[styles.commentContainer]: comment,
				[styles.noRedLine]: noRedLine,
			})}
		>
			<div
				className={cn({
					[styles.inputWrapper]: true,
				})}
			>
				{!comment ? (
					<input
						className={cn({
							[styles.input]: true,
							[styles.inputDisabled]: disabled,
							[styles.radius]: type === "password",
							[styles.font12]: noRedLine,
							[forgotInput]: forgotInput,
							[forgotInputPwd]: forgotInputPwd,
						})}
						type={type !== "password" ? type : passwordShown ? "text" : type}
						placeholder={placeholder}
						value={value}
						onChange={typeHandle.onChange}
						id={id}
					/>
				) : (
					<textarea
						className={cn({
							[styles.input]: true,
							[styles.inputDisabled]: disabled,
							[forgotInput]: forgotInput,
							[styles.font12]: noRedLine,
							[styles.comment]: comment,
						})}
						placeholder={placeholder}
						value={value}
						onChange={typeHandle.onChange}
						id={id}
						rows={2}
					/>
				)}
				{error && (
					<span
						className={cn(styles.inputError, {
							[styles.paddingStyles]: register,
						})}
					>
						{error}
					</span>
				)}
			</div>

			{type === "password" && (
				<div
					className={cn(styles.containerEyeIcon, {
						[styles.containerEyeIconDark]: dark,
						[forgotInput]: forgotInput,
						[forgotInputPwd]: forgotInputPwd,
					})}
					onClick={togglePasswordVisibility}
				>
					{!passwordShown ? (
						<SvgSprite
							className={styles.eyeIcon}
							spriteID={"eyePassword"}
						/>
					) : (
						<SvgSprite
							className={styles.eyeIcon}
							spriteID={"eyePasswordHide"}
						/>
					)}
				</div>
			)}
		</div>
	)
}
