import { setFramework, setFrameworkIsLoading, setNbrOfFrameworks } from "../../store/slices/frameworkSlice"
import { axiosInstance } from "../config/https"

export const getFrameworks = () => {
	return async dispatch => {
		dispatch(setFrameworkIsLoading(true))
		const res = await axiosInstance("framework")

		if (res.status === 200) {
			dispatch(setFramework(res.data.frameworks))
			dispatch(setNbrOfFrameworks(res.data.count))
		}
		dispatch(setFrameworkIsLoading(false))
	}
}
