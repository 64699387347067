import React, { useEffect, useState } from "react"
import { SvgSprite } from "../../ui/SvgSprite/SvgSprite"
import styles from "./index.module.scss"
import ProfileSection from "./ProfileSection"
import { ProfileImage } from "../../ui/ProfileImage/ProfileImage"
import { axiosInstance } from "../../config/https"
import { useDispatch, useSelector } from "react-redux"
import { setDeletedOrgUser } from "../../../store/slices/orgSlice"
import { setOrg } from "../../../store/slices/orgSlice"
import { getArchivedUsers } from "../../functions/getArchivedUsers"
import { setOrgsData } from "../../functions/setOrgData"
import { Loader } from "../../ui/Loader/Loader"
import { toast } from "../../functions/toast"
import { Btn } from "../../ui/Btn"
import { InvitedUsers } from "./InvitedUsers"
import { UpdatePlan } from "./UpdatePlan"

function UserSection({ access, plan = false, setPlan, activeUser = null, activities }) {
	const [activeClick, setActiveClick] = React.useState(true)
	const [archiveClick, setArchiveClick] = React.useState(false)
	const [invitedClick, setInvitedClick] = React.useState(false)
	const [userListActive, setUserListActive] = React.useState(!plan)
	const [profileActive, setProfileActive] = React.useState(false)
	const [userProfile, setUserProfile] = React.useState({})
	const [selectedUsers, setSelectedUsers] = React.useState([])
	const [selectAll, setSelectAll] = React.useState(false)
	const [users, setUsers] = React.useState([])
	const dispatch = useDispatch()
	const org = useSelector(state => state.organization.org.users)
	const organization = useSelector(state => state.organization.org)
	const archiveUsers = useSelector(state => state.archivedUsers.archivedUsers)
	const [loader, setLoader] = useState(false)
	const [userNumber, setUserNumber] = useState(0)
	const [changePlan, setChangePlan] = useState(plan)
	const accountPlan = useSelector(state => state.organization.org.account_plan)
	const teamSize = useSelector(state => state.user.teamSize)
	// flow data from API

	React.useEffect(() => {
		async function fetchData() {
			await axiosInstance("organization")
				.then(res => {
					dispatch(setOrg(res.data.organization))
				})
				.catch(err => {
					dispatch(toast("error", err?.response?.data?.message))
				})
		}
		fetchData()
	}, [dispatch])

	React.useEffect(() => {
		setUsers(activeClick ? org : archiveUsers)
		setSelectedUsers([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [org, activeClick, archiveUsers])

	const handleUserRemove = async () => {
		setLoader(true)
		let error = false
		const selectedUsersIds = selectedUsers.map(user => user.id)
		const deleteRequests = selectedUsersIds.map(async id => {
			await axiosInstance
				.delete(`/users/${id}`)
				.then(() => {
					const updatedUsers = users.filter(user => user.id !== id)
					dispatch(setDeletedOrgUser(id))
					setUsers(updatedUsers)
				})
				.catch(err => {
					error = true
					dispatch(toast("error", err?.response?.data?.message))
				})
		})
		dispatch(getArchivedUsers())
		await Promise.all(deleteRequests).then(() => {
			if (!error) {
				selectedUsers?.length > 0 && dispatch(toast("success", "User(s) Removed Successfully"))
			}
		})
		setLoader(false)
	}

	const handleUserRestore = async () => {
		setLoader(true)
		let error = false
		const selectedUsersIds = selectedUsers.map(user => user.id)
		const restoreRequests = selectedUsersIds.map(async id => {
			await axiosInstance.patch(`/restore/user/${id}`).catch(err => {
				error = true
				dispatch(toast("error", err?.response?.data?.message))
			})
		})
		dispatch(getArchivedUsers())
		dispatch(setOrgsData())
		await Promise.all(restoreRequests).then(() => {
			if (!error) {
				selectedUsers?.length > 0 && dispatch(toast("success", "User(s) Restored Successfully"))
			}
		})
		setLoader(false)
	}

	const handleUserSelection = user => {
		let newSelectedUsers = [...selectedUsers]
		if (user === "all") {
			if (selectAll) {
				newSelectedUsers = []
			} else {
				newSelectedUsers = users
			}
			setSelectAll(!selectAll)
		} else {
			const selectedIndex = selectedUsers.indexOf(user)

			if (selectedIndex === -1) {
				newSelectedUsers.push(user)
			} else {
				newSelectedUsers.splice(selectedIndex, 1)
			}
			setSelectAll(newSelectedUsers.length === users.length)
		}
		setSelectedUsers(newSelectedUsers)
	}

	const svgDeselect = (
		<SvgSprite
			className={styles.svgIconsEmpty}
			spriteID={"list"}
		></SvgSprite>
	)
	const svgSelect = (
		<SvgSprite
			className={styles.CustomSvgIcons}
			spriteID={"check"}
		></SvgSprite>
	)

	const archiveClickHandler = () => {
		setArchiveClick(true)
		setActiveClick(false)
		setInvitedClick(false)
		setChangePlan(false)
	}
	const activeClickHandler = () => {
		setActiveClick(true)
		setArchiveClick(false)
		setInvitedClick(false)
		setChangePlan(false)
	}
	const invitedClickHandler = () => {
		setActiveClick(false)
		setArchiveClick(false)
		setInvitedClick(true)
		setChangePlan(false)
	}
	const handleProfile = user => {
		setProfileActive(true)
		setUserListActive(false)
		setUserProfile(user)
	}

	const accountPlanHandler = () => {
		setChangePlan(true)
		setUserListActive(false)
	}
	const switchActive = {
		color: activeClick ? "#3361FF" : "#7D8FB3",
		cursor: "pointer",
		height: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderBottom: activeClick ? "2px solid blue" : "2px solid transparent",
	}
	const switchArchive = {
		color: archiveClick ? "#3361FF" : "#7D8FB3",
		marginLeft: "10px",
		cursor: "pointer",
		height: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderBottom: archiveClick ? "2px solid blue" : "2px solid transparent",
	}

	const switchInvited = {
		color: invitedClick ? "#3361FF" : "#7D8FB3",
		marginLeft: "10px",
		cursor: "pointer",
		height: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderBottom: invitedClick ? "2px solid blue" : "2px solid transparent",
	}

	useEffect(() => {
		setUserNumber(
			(org?.length < 2 && organization?.account_plan === "individual") || organization?.account_plan !== "individual",
		)
	}, [org, organization])

	useEffect(() => {
		if (plan) {
			setChangePlan(true)
			setUserListActive(false)
		}
		if (activeUser) {
			handleProfile(activeUser)
		}
	}, [plan, activeUser])
	return (
		<>
			{userListActive && (
				<div className={styles.BodyUserContainer}>
					<div className={styles.headerModel}>
						<ul style={{ display: "flex", alignItems: "center" }}>
							<li>
								<SvgSprite
									className={styles.settingsIcon}
									spriteID={"members"}
								></SvgSprite>
							</li>
							<li style={{ color: "#7D8FB3" }}>
								Current Plan :{" "}
								<span
									style={{ color: "#3361FF", cursor: "pointer" }}
									onClick={() => (accountPlan.toLowerCase() !== "standard" ? accountPlanHandler() : console.log(""))}
								>
									{accountPlan?.charAt(0).toUpperCase() + accountPlan?.slice(1, accountPlan?.length).toLowerCase()}
								</span>
							</li>
						</ul>
						<div style={{ display: "flex", alignItems: "center", marginLeft: " 15px", height: "100%", gap: "20px" }}>
							<div style={switchActive}>
								<span onClick={activeClickHandler}>Active</span>
							</div>
							<div
								style={switchArchive}
								onClick={archiveClickHandler}
							>
								<span>Archive</span>
							</div>
							<div
								style={switchInvited}
								onClick={invitedClickHandler}
							>
								<span>Invited</span>
							</div>
						</div>

						<div className={styles.headerRiegth}>
							<div>
								{teamSize - org?.length}/{teamSize} user slots available
							</div>
							{!access && userNumber ? (
								<Btn
									className={styles.AddUserBtn}
									disable={loader}
									onClick={() => {
										!loader && handleProfile()
									}}
								>
									<SvgSprite
										className={styles.AddUserBtnIcon}
										spriteID={"add"}
									></SvgSprite>
									<div>Invite User</div>
								</Btn>
							) : (
								<div></div>
							)}
						</div>
					</div>

					{!access && !invitedClick && (
						<div className={styles.headerUserSection}>
							<ul style={{ display: "flex", alignItems: "center", gap: "20px", padding: "12px" }}>
								<li onClick={() => handleUserSelection("all")}>{selectAll ? svgSelect : svgDeselect}</li>
								<li style={{ color: "#7D8FB3" }}>Select All</li>
							</ul>
							<div className={styles.menuUser}>
								{/* table  */}
								<ul className={styles.tableRow}>
									<li className={styles.tableCell}>
										{activeClick ? (
											<div
												style={{ display: "flex", cursor: "pointer" }}
												onClick={() => {
													if (window.confirm("Are you sure you wish to delete this user?")) handleUserRemove()
												}}
											>
												<SvgSprite
													className={styles.svgIcons}
													spriteID={"remove"}
												></SvgSprite>
												{loader ? <Loader /> : <p>Delete</p>}
											</div>
										) : (
											<div
												style={{ display: "flex", cursor: "pointer" }}
												onClick={() => {
													if (window.confirm("Are you sure you wish to restore this user?")) handleUserRestore()
												}}
											>
												<SvgSprite
													className={styles.svgIcons}
													spriteID={"restore"}
												></SvgSprite>
												{loader ? <Loader /> : <p>Restore</p>}
											</div>
										)}
									</li>
								</ul>
							</div>
							<ul></ul>
						</div>
					)}
					{invitedClick ? (
						<InvitedUsers />
					) : (
						<div className={styles.bodyContainer}>
							<table className={styles.tableContent}>
								<thead className={styles.theadStyles}>
									<tr>
										{!access && (
											<th>
												<SvgSprite
													className={styles.svgIconsEmpty}
													spriteID={"list"}
												></SvgSprite>
											</th>
										)}
										<th></th>
										<th>Name</th>
										<th>Role</th>
										<th style={{ textAlign: "left" }}>Email</th>
										{activeClick && <th>{!access ? "Edit" : "Show"}</th>}
									</tr>
								</thead>

								<tbody className={styles.tbodyStyles}>
									{users?.length > 0 &&
										users?.map((user, index) => (
											<tr key={index}>
												{!access && (
													<td onClick={() => handleUserSelection(user)}>
														{selectedUsers.includes(user) ? svgSelect : svgDeselect}
													</td>
												)}
												<td>
													{/* <div className={styles.nameInitial} style={generateColor()}>{nameInitials(user.firstName, user.lastName)}</div> */}
													{true ? (
														<ProfileImage name={user.firstName + " " + user.lastName} />
													) : (
														<SvgSprite
															spriteID={"user"}
															className={styles.userImgSvg}
														/>
													)}
												</td>
												<td>
													{user.firstName} {user.lastName}
												</td>
												<td>{user?.role?.charAt(0)?.toUpperCase() + user?.role?.substring(1)}</td>
												<td>{user.email}</td>
												{activeClick && (
													<td onClick={() => handleProfile(user)}>
														<SvgSprite
															className={styles.editSvg}
															spriteID={"more"}
														></SvgSprite>
													</td>
												)}
											</tr>
										))}
								</tbody>
							</table>
						</div>
					)}
				</div>
			)}
			{profileActive && (
				<ProfileSection
					userProfile={userProfile}
					access={access}
					activities={activities}
				/>
			)}
			{changePlan && <UpdatePlan />}
		</>
	)
}

export default UserSection
