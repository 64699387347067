import React, { useEffect, useState } from "react"
import styles from "./index.module.scss"
import { SvgSprite } from "../SvgSprite"
import cn from "classnames"
import { StrongPassword } from "../../functions/CheckPassword"

const PasswordInput = ({ value, setValidePwd, validePwd, placeholder, error, className, ...typeHandle }) => {
	const [isFocused, setIsFocused] = useState(false)
	const [check, setCheck] = useState({})

	const [passwordShown, setPasswordShown] = useState(false)

	const togglePasswordVisibility = () => setPasswordShown(!passwordShown)

	const handleFocus = () => {
		setIsFocused(true)
	}

	const handleBlur = () => {
		setIsFocused(false)
	}

	useEffect(() => {
		setCheck(StrongPassword(value))
		if (
			value?.length >= 8 &&
			check?.lowercaseValid &&
			check?.specialCharValid &&
			check?.uppercaseValid &&
			check?.numberValid
		) {
			setValidePwd(true)
		} else {
			setValidePwd(false)
		}
	}, [check?.lowercaseValid, check?.numberValid, check?.specialCharValid, check?.uppercaseValid, setValidePwd, value])

	return (
		<div
			className={cn(styles.passwordInputContainer, {
				[className]: className,
				[styles.inputWrapperError]: error,
			})}
		>
			<input
				type={passwordShown ? "text" : "password"}
				name="password"
				value={value || ""}
				onChange={typeHandle.onChange}
				placeholder={placeholder}
				onFocus={handleFocus}
				onBlur={handleBlur}
			/>
			<div
				className={cn(styles.containerEyeIcon)}
				onClick={togglePasswordVisibility}
			>
				{!passwordShown ? (
					<SvgSprite
						className={styles.eyeIcon}
						spriteID={"eyePassword"}
					/>
				) : (
					<SvgSprite
						className={styles.eyeIcon}
						spriteID={"eyePasswordHide"}
					/>
				)}
			</div>
			{error && (
				<span
					className={cn(styles.inputError, {
						[styles.paddingStyles]: true,
					})}
				>
					{error}
				</span>
			)}

			{!validePwd && isFocused && (
				<div className={styles.passwordTooltip}>
					<ul>
						<li
							className={cn({
								[styles.red]: !check?.charNumberValid,
							})}
						>
							Must contain at least 8 characters
						</li>
						<li
							className={cn({
								[styles.red]: !check?.uppercaseValid,
							})}
						>
							Must contain at least one uppercase letter
						</li>
						<li
							className={cn({
								[styles.red]: !check?.lowercaseValid,
							})}
						>
							Must contain at least one lowercase letter
						</li>
						<li
							className={cn({
								[styles.red]: !check?.numberValid,
							})}
						>
							Must contain at least one number{" "}
						</li>
						<li
							className={cn({
								[styles.red]: !check?.specialCharValid,
							})}
						>
							Must contain at least one special character
						</li>
					</ul>
				</div>
			)}
		</div>
	)
}

export default PasswordInput
