import {
	setNumberOfControls,
	setNumberOfControlsCompleted,
	setNumberOfControlsInProgress,
	setNumberOfControlsNeedAttention,
	setNumberOfControlsNotStarted,
	setNumberOfFrameworks,
	setNumberOfIncidentReports,
	setOrgIsLoading,
} from "../../store/slices/orgSlice"
import { axiosInstance } from "../config/https"

export const getControlsState = () => {
	return async dispatch => {
		dispatch(setOrgIsLoading(true))
		const res = await axiosInstance("controls/state")

		if (res.status === 200) {
			dispatch(setNumberOfControlsCompleted(res.data.number_of_controls_completed))
			dispatch(setNumberOfControlsInProgress(res.data.number_of_controls_in_Progress))
			dispatch(setNumberOfControlsNeedAttention(res.data.number_of_controls_need_attention))
			dispatch(setNumberOfControlsNotStarted(res.data.number_of_controls_not_started))
			dispatch(setNumberOfControls(res.data.number_of_controls))
			dispatch(setNumberOfFrameworks(res.data.number_of_frameworks))
			dispatch(setNumberOfIncidentReports(res.data.number_of_incident_reports))
		}
		dispatch(setOrgIsLoading(false))
	}
}
