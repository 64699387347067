import React, { useState } from "react"
import styles from "./index.module.scss"

export const Risk = ({ risk }) => {
	const [riskLevel] = useState({
		"#1": "#E02020",
		"#2": "#F7B500",
		"#3": "#FAFA00",
		"#4": "#6DD400",
		"#5": "#2EE6CA",
		z: "#ccc",
	})
	return (
		<div
			className={styles.riskComp}
			style={{ backgroundColor: riskLevel[risk?.toLowerCase()] }}
		>
			{}
		</div>
	)
}
