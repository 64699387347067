import React, { useEffect, useState } from "react"
import styles from "../../Documents/index.module.scss"
import cn from "classnames"
import { Search } from "../../../ui/Search/Search"
import { DotsLoader } from "../../../ui/Loader"
import { useQuery } from "@tanstack/react-query"
import { api } from "../../../services/api"
import { useNavigate, useParams } from "react-router"
import { ProfileImage } from "../../../ui/ProfileImage/ProfileImage"
import { Risk } from "../../Documents/Risk"
import { Status } from "./Status"
import { useSelector } from "react-redux"

export const Audit = () => {
	const users = useSelector(state => state.organization.org.users)
	const [riskLevel] = useState({ high: "#1", significant: "#2", moderate: "#3", minor: "#4", negligible: "#5" })
	const [sortItem, setSortItem] = useState("default")
	const [search, setSearch] = useState("")
	const [data, setData] = useState()
	const [searchData, setSearchData] = useState([])

	const { id } = useParams()
	const navigate = useNavigate()

	const frameworksDataQuery = useQuery({
		queryKey: ["auditor", "frameworks"],
		queryFn: () => api.fetchData("auditor/frameworks"),
	})

	const fetchData = id => {
		if (id === "AllControls") {
			return api.fetchData("auditor/selected/controls")
		} else if (id === "NonApplicable") {
			return api.fetchData("auditor/na/controls")
		} else {
			return api.fetchData(`auditor/framework/${id}/controls`)
		}
	}

	const controlsQuery = useQuery({
		queryKey: ["auditor", "controls", id],
		queryFn: () => fetchData(id),
	})

	const handleClick = frameworkId => {
		navigate(`/framework/control/${frameworkId}/submission`)
	}

	const getOwner = submission => {
		if (submission?.length > 0) {
			const user = users?.find(u => u.id === submission[0]?.user_id)

			if (user) {
				return `${user.firstName} ${user.lastName}`
			}
			return "N / A"
		} else {
			return "N / A"
		}
	}

	useEffect(() => {
		setSearchData(
			controlsQuery.data?.controls?.filter(item => item?.name?.toLowerCase()?.includes(search?.toLowerCase())),
		)
	}, [controlsQuery.data, search])

	const compareBy = key => {
		return function (a, b) {
			if (a[key] < b[key]) return -1
			if (a[key] > b[key]) return 1
			return 0
		}
	}

	const sortBy = (key, direc) => {
		let arrayCopy = [...controlsQuery.data?.controls]
		if (direc === -1) {
			arrayCopy.sort(compareBy(key)).reverse()
		} else {
			arrayCopy.sort(compareBy(key))
		}
		setData([...arrayCopy])
	}
	return (
		<div className={styles.main}>
			<div className={styles.header}>
				<div className={styles.rieghtHeader}>
					<div
						className={cn(styles.headerFramework, { [styles.activeFramework]: "AllControls" === id })}
						onClick={() => {
							navigate("/framework/AllControls")
						}}
					>
						All controls
					</div>
					{/* <div  className={cn(styles.headerFramework, {[styles.activeFramework]  : "NonApplicable" === id})} onClick={()=>{navigate(`/framework/NonApplicable`);}}>Non applicable</div> */}
					{frameworksDataQuery.isFetched ? (
						frameworksDataQuery.data.frameworks?.map((framework, i) => (
							<div
								key={i}
								className={cn(styles.headerFramework)}
								onClick={() => {
									navigate(`/framework/${framework.id}`)
								}}
							>
								{framework?.name}
							</div>
						))
					) : (
						<div className={styles.headerFramework}>
							<DotsLoader />
						</div>
					)}
				</div>
			</div>
			<div className={styles.subHeader}>
				<div className={styles.controls}>{controlsQuery.isFetched && controlsQuery.data.controls.length} Controls</div>
				<div className={styles.gridSearch}>
					<div className={styles.search}>
						<Search
							setSearch={setSearch}
							className={styles.search}
							placeholder={"Search Controls"}
						/>
					</div>
				</div>
				<div className={styles.sort}>
					Sort By:
					<div
						className={cn(styles.sortItem, { [styles.sortItemActive]: sortItem === "default" })}
						onClick={() => {
							setSortItem("default")
							sortBy("default")
						}}
					>
						Default
					</div>
					<div
						className={cn(styles.sortItem, { [styles.sortItemActive]: sortItem === "A-Z" })}
						onClick={() => {
							setSortItem("A-Z")
							sortBy("name")
						}}
					>
						A-Z
					</div>
					<div
						className={cn(styles.sortItem, { [styles.sortItemActive]: sortItem === "Z-A" })}
						onClick={() => {
							setSortItem("Z-A")
							sortBy("name", -1)
						}}
					>
						Z-A
					</div>
				</div>
			</div>
			<div className={styles.body}>
				<div className={styles.table}>
					<div className={styles.tableHeader}>
						<div className={cn(styles.owner, styles.ownerHeader)}>Owner</div>
						<div className={cn(styles.name, styles.nameHeader)}>Name</div>
						<div className={styles.description}>Description</div>
						<div className={cn(styles.risk, styles.riskheader)}>Risk</div>
						<div className={cn(styles.status, styles.statusHeader)}>Status</div>
						<div className={styles.tableAction}></div>
					</div>
					<div className={styles.TableBody}>
						{controlsQuery.isSuccess &&
							(search ? searchData : data || controlsQuery.data?.controls).map(control => (
								<div
									key={control.id}
									onClick={() => handleClick(control.id)}
								>
									<div className={cn(styles.row)}>
										<div className={styles.owner}>
											<ProfileImage
												name={getOwner(control?.control_submissions)}
												className={styles.imageProfil}
											/>
										</div>
										<div className={cn(styles.name, { [styles.childName]: false })}>{control.name}</div>
										<div className={styles.description}>
											<div dangerouslySetInnerHTML={{ __html: control.description }} />
										</div>
										<div className={styles.risk}>
											<Risk
												risk={
													riskLevel[
														(
															(control?.control_submissions?.length > 0 &&
																control?.control_submissions[0]?.riskLevel) ||
															control?.control_risk
														)?.toLowerCase()
													]
												}
											></Risk>
										</div>
										<div className={styles.status}>
											<Status
												status={control?.control_submissions?.length > 0 && control?.control_submissions[0]?.status}
												small
											/>
										</div>
										<div className={styles.tableAction}></div>
									</div>
								</div>
							))}
						{controlsQuery.isPending && !controlsQuery.data && (
							<div className={styles.controlsPending}>
								<DotsLoader />
							</div>
						)}
						{controlsQuery.isSuccess && controlsQuery.data.controls.length === 0 && (
							<div className={styles.controlsPending}>No Controls Found</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
