import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	isAuth: false,
	userInfo: {},
	color: null,
	qrCodeUrl: null,
	accountPlan: null,
	teamSize: null,
	notificationNbr: 0,
}

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUserInfo(state, action) {
			state.userInfo = action.payload
		},
		setIsAuth(state, action) {
			state.isAuth = action.payload
		},
		setColor(state, action) {
			state.color = action.payload
		},
		setQrCode(state, action) {
			state.qrCodeUrl = action.payload
		},
		setAccountPlan(state, action) {
			state.accountPlan = action.payload
		},
		setNotificationNabr(state, action) {
			state.notificationNbr = action.payload
		},
		setTeamSize(state, action) {
			state.teamSize = action.payload
		},
	},
})

export default userSlice.reducer
export const { setUserInfo, setIsAuth, setColor, setQrCode, setAccountPlan, setNotificationNabr, setTeamSize } =
	userSlice.actions
