import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Datastates } from "../../../StaticData/states"
import { axiosInstance } from "../../config/https"
import { setOrgsData } from "../../functions/setOrgData"
import { useInput } from "../../hooks/useInput"
import { Input } from "../../ui/Input/Input"
import { Select } from "../../ui/Select/Select"
import { SvgSprite } from "../../ui/SvgSprite/SvgSprite"
import styles from "./index.module.scss"
import UserSection from "./UserSection"
import cn from "classnames"
import { Img } from "../../ui/Img/Img"
import { getArchivedUsers } from "../../functions/getArchivedUsers"
import { Loader } from "../../ui/Loader/Loader"
import { toast } from "../../functions/toast"
import ProfileSection from "./ProfileSection"
import { Btn } from "../../ui/Btn"
import { TimeZone } from "../../../StaticData/TimeZone"

export const SettingModel = ({ closePopup, plan = false, setPlan, activeUser, activities }) => {
	// flow data from API
	const org = useSelector(state => state.organization.org)
	const dispatch = useDispatch()
	const currentUser = useSelector(state => state.user.userInfo)

	// for dynamic style
	const [generalClick, setGeneralClick] = React.useState(false)
	const [userClick, setUserClick] = React.useState(false)
	const [myProfil, setMyProfil] = useState(true)
	// get data

	const inputOrgName = useInput("")
	const inputDescription = useInput("")
	const inputCity = useInput("")
	const inputAddress = useInput("")
	const [newstates, setNewstates] = useState("")
	const [newCurrency, setNewCurrency] = useState("")
	// const [newCountry, setNewCountry] = useState('')
	const [selectedTimezone, setSelectedTimezone] = useState("")
	const [file, setFile] = useState(null)
	const [loader, setLoader] = useState(false)
	const [access, setAccess] = useState(true)
	const [riskStatus, setRiskStatus] = useState()

	useEffect(() => {
		inputOrgName.setNewValue(org?.name)
		inputAddress.setNewValue(org?.address)
		inputCity.setNewValue(org?.city)
		setNewstates(org?.state)
		inputDescription.setNewValue(org?.description)
		setNewCurrency(org?.currency)
		setSelectedTimezone(TimeZone.find(t => t.timezone === org?.timezone)?.value)
		setRiskStatus(org?.risk_status ? "automatic" : "manual")
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [org])

	const [listRiskStatus] = useState([
		{
			id: 1,
			name: "Manual",
			value: "manual",
		},
		{
			id: 2,
			name: "Automatic",
			value: "automatic",
		},
	])
	// const [countries] = useState([
	//   {
	//     id : 1,
	//     name : 'United States',
	//     value : 'United States',
	//   }])
	const [Currencies] = useState([
		{
			id: 1,
			name: "USD",
			value: "USD",
		},
	])

	const userClickProfile = () => {
		setMyProfil(true)
		setUserClick(false)
		setGeneralClick(false)
		setPlan && setPlan(false)
	}

	const userClickHandler = () => {
		setUserClick(true)
		setGeneralClick(false)
		setMyProfil(false)
		setPlan && setPlan(false)
	}

	const generalClickHandler = () => {
		setGeneralClick(true)
		setUserClick(false)
		setMyProfil(false)
		setPlan && setPlan(false)
	}
	const handlerSaveSetting = async () => {
		setLoader(true)
		const data = JSON.stringify({
			name: inputOrgName.value,
			description: inputDescription.value,
			address: inputAddress.value,
			timezone: TimeZone.find(t => t.value === selectedTimezone)?.timezone,
			currency: newCurrency,
			state: newstates,
			riskStatus: riskStatus === "manual" ? false : true,
		})
		if (file) {
			const dataFile = {
				logo: file,
			}
			await axiosInstance
				.post(`update/organization/${org.id}/logo`, dataFile, {
					headers: {
						"content-type": "multipart/form-data",
					},
				})
				.then(res => {
					dispatch(setOrgsData())
					closePopup()
					dispatch(toast("success", "Organization Info Updated Successfully"))
				})
				.catch(err => {
					dispatch(toast("error", err?.response?.data?.message))
				})
				.finally(() => {
					setLoader(false)
				})
		}
		await axiosInstance
			.patch(`/organizations/${org.id}`, data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(res => {
				dispatch(setOrgsData())
				setLoader(false)
				closePopup()
				dispatch(toast("success", "Organization Info Updated Successfully"))
			})
			.catch(err => {
				dispatch(toast("error", err?.response?.data?.message))
			})
			.finally(() => {
				setLoader(false)
			})
	}

	useEffect(() => {
		if (plan || activeUser) {
			setUserClick(true)
			setGeneralClick(false)
			setMyProfil(false)
		}
	}, [plan, activeUser])

	const switchGeneral = {
		color: generalClick ? "#3361FF" : "#7D8FB3",
		cursor: "pointer",
		height: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderBottom: generalClick ? "2px solid blue" : "2px solid transparent",
	}
	const switchUser = {
		color: userClick ? "#3361FF" : "#7D8FB3",
		marginLeft: "10px",
		cursor: "pointer",
		height: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderBottom: userClick ? "2px solid blue" : "2px solid transparent",
	}

	const switchProfile = {
		color: myProfil ? "#3361FF" : "#7D8FB3",
		marginLeft: "10px",
		cursor: "pointer",
		height: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderBottom: myProfil ? "2px solid blue" : "2px solid transparent",
	}
	useEffect(() => {
		(async () => {
			dispatch(getArchivedUsers())
		})()
	}, [dispatch])

	useEffect(() => {
		setAccess(currentUser?.role?.toLowerCase() === "admin" ? false : true)
	}, [currentUser])
	return (
		<div className={styles.containerModel}>
			<div className={styles.headerModel}>
				<div className={styles.headerContainer}>
					<ul style={{ display: "flex", alignItems: "center" }}>
						<li>
							<SvgSprite
								className={styles.settingsIcon}
								spriteID={"settings"}
							></SvgSprite>
						</li>
						<li style={{ color: "#7D8FB3" }}>Settings</li>
					</ul>
					<div className={styles.menu}>
						<div
							style={switchProfile}
							onClick={() => userClickProfile()}
						>
							<span>My Profile</span>
						</div>
						<div
							style={switchGeneral}
							onClick={() => generalClickHandler()}
						>
							<span>General</span>
						</div>
						{!access && (
							<div
								style={switchUser}
								onClick={() => userClickHandler()}
							>
								<span>Account management</span>
							</div>
						)}
					</div>
					<div></div>
				</div>
				{/* {!access &&<ul className={styles.settingIcon} onClick={() => {!loader && handlerSaveSetting()}}>
                    <li><SvgSprite className={styles.settingsIconNoBackground} spriteID={'settings'}></SvgSprite></li>
                    {loader ? <Loader /> : <li>Save Settings</li>}
                  </ul>} */}
				{!access && (
					<Btn
						className={cn(styles.AddUserBtn, styles.btnwhite)}
						disable={loader}
						onClick={() => (!loader ? handlerSaveSetting() : console.log(""))}
					>
						<SvgSprite
							className={styles.AddUserBtnIcon}
							spriteID={"settings"}
						/>
						{loader ? <Loader /> : <div>Save Settings</div>}
					</Btn>
				)}
			</div>
			{/* body model */}
			{generalClick && (
				<div className={styles.bodyContainer}>
					<div className={styles.forScroll}>
						<div className={styles.moduleBody}>
							<div style={{ display: "flex", justifyContent: "space-between", padding: "15px" }}>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<div className={styles.inputContainer}>
										<label className={cn(styles.label, styles.labelColor)}>Company Name</label>

										<Input
											onChange={inputOrgName.onChange}
											type={"text"}
											className={styles.input}
											value={inputOrgName.value || ""}
											disabled={currentUser?.role?.toLowerCase() !== "admin"}
										/>
										<label className={styles.comments}>Company Name or title</label>
									</div>
									<div className={styles.inputContainer}>
										<label className={cn(styles.label, styles.labelColor)}>Timezone</label>
										{currentUser?.role?.toLowerCase() !== "admin" ? (
											<Input
												type={"text"}
												className={styles.input}
												value={selectedTimezone || ""}
												onChange={() => console.log("")}
												disabled={true}
											/>
										) : (
											<Select
												selectFullWidth
												className={styles.selectClassName}
												setNewValue={setSelectedTimezone}
												initValue={selectedTimezone}
												values={TimeZone}
											/>
										)}
										<label className={styles.comments}>
											Choose either a city in the same timezone as you or a UTC timezone offset.
										</label>
									</div>
								</div>
								<div className={styles.labelContainer}>
									<div className={cn(styles.label, styles.labeltoDiv)}>Company Logo</div>
									<div className={styles.ImageLogo}>
										{((!org.logo && file) || (org.logo && file)) && (
											<img
												src={URL.createObjectURL(file)}
												className={styles.orgLogo}
												alt="Organization Logo"
											/>
										)}
										{!org.logo && !file && currentUser?.role?.toLowerCase() === "admin" && (
											<>
												<input
													type="file"
													accept="image/*"
													onChange={e => setFile(e.target.files[0])}
												/>
												<p className={styles.guide}>
													<SvgSprite
														spriteID={"image"}
														className={styles.uploadIcon}
													/>
												</p>
											</>
										)}
										{org.logo && !file && (
											<Img
												path={`public/${org.logo}`}
												img
												className={styles.orgLogo}
											/>
										)}
									</div>
									{currentUser?.role?.toLowerCase() === "admin" && (
										<div className={styles.upload}>
											<div className={styles.formUp}>
												<input
													type="file"
													accept="image/*"
													onChange={e => setFile(e.target.files[0])}
												/>
												<p className={styles.comments}>Upload New Logo</p>
											</div>
										</div>
									)}
								</div>
							</div>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div style={{ display: "flex", justifyContent: "spaceBetween", padding: "15px", marginBottom: "5%" }}>
									<div style={{ display: "flex", flexDirection: "column" }}>
										<div className={styles.inputContainer}>
											<label className={styles.label}>Address</label>
											<Input
												value={inputAddress.value || ""}
												type={"text"}
												className={styles.input}
												onChange={inputAddress.onChange}
												disabled={currentUser?.role?.toLowerCase() !== "admin"}
											/>
										</div>
										<div className={styles.inputContainer}>
											<label className={styles.label}>City</label>
											<Input
												value={inputCity.value || ""}
												type={"text"}
												className={styles.input}
												onChange={inputCity.onChange}
												disabled={currentUser?.role?.toLowerCase() !== "admin"}
											/>
										</div>
										<div className={styles.inputContainer}>
											<label className={styles.label}>State</label>
											{currentUser?.role?.toLowerCase() !== "admin" ? (
												<Input
													type={"text"}
													className={styles.input}
													value={newstates || ""}
													onChange={() => console.log("")}
													disabled={true}
												/>
											) : (
												<Select
													selectFullWidth
													className={styles.selectClassName}
													setNewValue={setNewstates}
													initValue={newstates}
													values={Datastates}
												/>
											)}
										</div>
										<div className={styles.inputContainer}>
											<label className={styles.label}>Description</label>
											<Input
												value={inputDescription.value || ""}
												type={"text"}
												className={styles.input}
												onChange={inputDescription.onChange}
												disabled={currentUser?.role?.toLowerCase() !== "admin"}
											/>
										</div>
										<div className={styles.inputContainer}>
											<label className={styles.label}>Currency</label>
											{currentUser?.role?.toLowerCase() !== "admin" ? (
												<Input
													type={"text"}
													className={styles.input}
													value={newCurrency || ""}
													onChange={() => console.log("")}
													disabled={true}
												/>
											) : (
												<Select
													selectFullWidth
													className={styles.selectClassName}
													setNewValue={setNewCurrency}
													initValue={newCurrency}
													values={Currencies}
												/>
											)}
										</div>
										<div className={styles.inputContainer}>
											<label className={styles.label}>Control risk level</label>
											<Select
												selectFullWidth
												className={styles.selectClassName}
												setNewValue={setRiskStatus}
												initValue={riskStatus}
												values={listRiskStatus}
											/>
										</div>
										{/* <div className={styles.inputContainer}>
                            <label className={styles.label} >Country</label>
                            <Select selectFullWidth 
                              className={styles.selectClassName}  
                              setNewValue={setNewCountry} 
                              initValue={newCountry} 
                              values={countries}
                            />
                          </div>    */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{userClick && (
				<UserSection
					access={access}
					plan={plan}
					setPlan={setPlan}
					activeUser={activeUser}
					activities={activities}
				/>
			)}

			{myProfil && (
				<ProfileSection
					userProfile={currentUser}
					access={access}
					withBack={false}
				/>
			)}
		</div>
	)
}
