import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	resources: null,
}

const resourcesSlice = createSlice({
	name: "resources",
	initialState,
	reducers: {
		setResources(state, action) {
			state.resources = action.payload
		},
	},
})

export default resourcesSlice.reducer
export const { setResources } = resourcesSlice.actions
