import styles from "./index.module.scss";
import { config } from "../../utils/Constants";
import { getCookie } from "../../utils/cookie";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "../../ui/Loader";

const Avatar = ({ imagePath }) => {
  const { data: imageBlob, isLoading } = useQuery({
    queryKey: ["fetchImageData", imagePath],
    queryFn: async () => {
      const res = await fetch(`${config.url.API_URL}/api/public/${imagePath}`, {
        headers: {
          Authorization: `Bearer ${getCookie("access_token")}`,
        },
      });
      if (!res.ok) {
        throw new Error("Failed to fetch image");
      }
      return await res.blob();
    },
    enabled: !!imagePath, // Only fetch data when `path` exists
    refetchOnWindowFocus: false, // Disable refetching on window focus
  });

  return (
    <>
      {isLoading ? (
        <div className={styles.center}>
          <Loader />
        </div>
      ) : (
        <img
          src={imageBlob ? URL.createObjectURL(imageBlob) : ""}
          alt="avatar"
          className={styles.avatar}
        />
      )}
    </>
  );
};

export default Avatar;
