import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	incidentReport: null,
	isLoading: false,
	NbrofIncidentReport: 0,
}

const incidentReportSlice = createSlice({
	name: "incidentReport",
	initialState,
	reducers: {
		setIncidentReportIsLoading(state, action) {
			state.isLoading = action.payload
		},

		setIncidentReport(state, action) {
			state.incidentReport = action.payload
		},

		setNbrOfIncidentReport(state, action) {
			state.NbrofIncidentReport = action.payload
		},
	},
})

export default incidentReportSlice.reducer
export const { setIncidentReportIsLoading, setIncidentReport, setNbrOfIncidentReport } = incidentReportSlice.actions
