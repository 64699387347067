import {
	setIncidentReport,
	setIncidentReportIsLoading,
	setNbrOfIncidentReport,
} from "../../store/slices/incidentReportSlice"
import { axiosInstance } from "../config/https"

export const getIncidentReport = () => {
	return async dispatch => {
		dispatch(setIncidentReportIsLoading(true))
		const res = await axiosInstance("incident/report")

		if (res.status === 200) {
			dispatch(setIncidentReport(res.data.incident_reports))
			dispatch(setNbrOfIncidentReport(res.data.count))
		}
		dispatch(setIncidentReportIsLoading(false))
	}
}
