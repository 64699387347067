/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useMemo, useRef, useState } from "react"
import { EditorState, convertToRaw } from "draft-js"
import Editor from "@draft-js-plugins/editor"
import createMentionPlugin, { defaultSuggestionsFilter } from "@draft-js-plugins/mention"
import editorStyles from "./MentionEditor.module.css"
import mentionsStyles from "./MentionsStyles.module.css"
import { Btn } from "../Btn"
import { Loader } from "../Loader"
import { axiosInstance } from "../../config/https"
import { toast } from "../../functions/toast"
import { useDispatch } from "react-redux"

export default function MentionEditor({
	mentions,
	submissionId,
	activeControlId,
	activities,
	setActivities,
	listComment,
	setListComment,
}) {
	const [commentLoader, setCommentLoader] = useState(false)
	const dispatch = useDispatch()
	const ref = useRef(null)
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
	const [open, setOpen] = useState(false)
	const [suggestions, setSuggestions] = useState(mentions)

	const { MentionSuggestions, plugins } = useMemo(() => {
		const mentionPlugin = createMentionPlugin({
			entityMutability: "IMMUTABLE",
			theme: mentionsStyles,
			mentionPrefix: "@",
			supportWhitespace: true,
		})
		const { MentionSuggestions } = mentionPlugin
		const plugins = [mentionPlugin]
		return { plugins, MentionSuggestions }
	}, [])

	const onOpenChange = useCallback(_open => {
		setOpen(_open)
	}, [])
	const onSearchChange = useCallback(({ value }) => {
		setSuggestions(defaultSuggestionsFilter(value, mentions))
	}, [])

	const onExtractData = () => {
		const contentState = editorState.getCurrentContent()
		const raw = convertToRaw(contentState)
		const comment = raw.blocks.map(data => data.text).join("\n")
		return comment
	}

	const onExtractMentions = () => {
		const contentState = editorState.getCurrentContent()
		const raw = convertToRaw(contentState)
		let mentionedUsers = []
		for (let key in raw.entityMap) {
			const ent = raw.entityMap[key]
			if (ent.type === "mention") {
				mentionedUsers.push(ent.data.mention)
			}
		}
		return mentionedUsers
	}

	const addComment = async () => {
		var comment = onExtractData()
		var mentionedUsers = onExtractMentions()?.map(u => {
			return u.id
		})
		if (comment?.length > 0) {
			setEditorState(EditorState.createEmpty())
			setCommentLoader(true)
			const data = {
				control_id: activeControlId,
				control_submission_id: submissionId,
				comment: comment,
				mentionedUsers: mentionedUsers,
			}

			await axiosInstance
				.post("comment/log/control", data)
				.then(res => {
					listComment?.length > 0 ? setListComment([res.data.data, ...listComment]) : setListComment([res.data.data])
					activities?.length > 0
						? setActivities([res.data.meta_data, activities])
						: setActivities([res.data.meta_data])
					dispatch(toast("success", "Comment added Successfully"))
				})
				.catch(err => {
					dispatch(toast("error", err?.response?.data?.message))
				})
				.finally(() => {
					setCommentLoader(false)
				})
		}
	}

	return (
		<div className={mentionsStyles.commentContainer}>
			<div
				className={editorStyles.editor}
				onClick={() => {
					ref.current.focus()
				}}
			>
				<Editor
					editorKey={"editor"}
					editorState={editorState}
					onChange={setEditorState}
					plugins={plugins}
					ref={ref}
				/>
				<MentionSuggestions
					open={open}
					onOpenChange={onOpenChange}
					suggestions={suggestions}
					onSearchChange={onSearchChange}
					onAddMention={() => {
						// get the mention object selected
					}}
				/>
			</div>
			<div>
				{/* <button onClick={() => onExtractData()}>Extract data</button>
        <button onClick={() => onExtractMentions()}>
          Extract mentions
        </button> */}
				<Btn
					className={mentionsStyles.submitBtn}
					onClick={() => addComment()}
					noHover
				>
					<div>Comment</div>
					{commentLoader && <Loader />}
				</Btn>
			</div>
		</div>
	)
}
