import React, { useState } from "react"
import cn from "classnames"
// react Router
import { Link, useLocation } from "react-router-dom"

// styles
import styles from "./index.module.scss"

// component
import { Input } from "../../ui/Input/Input"
import { Btn } from "../../ui/Btn"
import { Loader } from "../../ui/Loader"

// functions
import { checkErrors } from "../../functions/checkErrors"
import { axiosInstance } from "../../config/https"
import { useDispatch } from "react-redux"
import { toast } from "../../functions/toast"
import PasswordInput from "../../ui/PasswordInput/PasswordInput"
import { useInput } from "../../hooks/useInput"
import { Select } from "../../ui/Select/Select"
import { setCookie } from "../../utils/cookie"
import {
	setAccountPlan,
	setIsAuth,
	setNotificationNabr,
	setQrCode,
	setTeamSize,
	setUserInfo,
} from "../../../store/slices/userSlice"

export const RegisterSocial = () => {
	const dispatch = useDispatch()
	const state = useLocation()

	const [validePwd, setValidePwd] = useState(false)
	const inputPassword = useInput("")
	const inputConfirmPassword = useInput("")
	const [type, setType] = useState()
	const inputOrgName = useInput("")

	const [errors, setErrors] = useState({})

	const [register, setRegister] = useState(false)

	const [newPlan, setNewPlan] = useState()
	const [newSeat, setNewSeat] = useState()

	const [listSeats] = useState([
		{
			id: 0,
			name: "3 seats",
			value: "3",
		},
		{
			id: 1,
			name: "5 seats",
			value: "5",
		},
		{
			id: 2,
			name: "10 seats",
			value: "10",
		},
		{
			id: 3,
			name: "15 seats",
			value: "15",
		},
		{
			id: 4,
			name: "20 seats",
			value: "20",
		},
		{
			id: 5,
			name: "25 seats",
			value: "25",
		},
		{
			id: 6,
			name: "30 seats",
			value: "30",
		},
		{
			id: 7,
			name: "40 seats",
			value: "40",
		},
		{
			id: 8,
			name: "50 seats",
			value: "50",
		},
		{
			id: 9,
			name: "100 seats",
			value: "100",
		},
	])

	const [listType] = useState([
		{
			id: 0,
			name: "Monthly",
			value: "month",
		},
		{
			id: 1,
			name: "Yearly",
			value: "year",
		},
	])

	const [listPlan] = useState([
		{
			id: 0,
			name: "Individual",
			value: "individual",
		},
		{
			id: 1,
			name: "Basic",
			value: "basic",
		},
		{
			id: 2,
			name: "Standard",
			value: "standard",
		},
	])

	const handelRegister = async () => {
		setRegister(true)
		const inputsInfo = [
			{ el: inputPassword, errorName: "password", errorText: "Enter Password" },
			{ el: inputConfirmPassword, errorName: "confirmPassword", errorText: "Enter Password" },
			{ el: inputOrgName, errorName: "orgName", errorText: "Enter organization Name" },
		]

		if (await checkErrors(setErrors, inputsInfo)) {
			setRegister(false)
			return
		}

		if (!validePwd) {
			let tempErrors = []
			tempErrors["password"] = "Choose a stronger password"
			setErrors(tempErrors)
			setRegister(false)
			return
		}

		if (inputPassword.value !== inputConfirmPassword.value) {
			let tempErrors = []
			tempErrors["confirmPassword"] = "Passwords do not match"
			setErrors(tempErrors)
			setRegister(false)
			return
		}

		const data = {
			userId: state.state.user.id,
			password: inputPassword.value,
			name: inputOrgName.value,
			account_plan: newPlan,
			type: type,
			plan: newPlan,
			quantity: newSeat,
			mode: process.env.NODE_ENV === "development" ? "test" : "",
		}

		axiosInstance
			.post("/auth/social/register", data)
			.then(res => {
				setCookie("access_token", res.data.access_token, res.data.expires_in, true)
				dispatch(setUserInfo({ ...res.data.user }))
				dispatch(setQrCode(res.data.qr_code_url))
				dispatch(setAccountPlan(res.data?.account_plan))
				dispatch(setNotificationNabr(res.data.notifications_nbr))
				dispatch(setTeamSize(res.data.team_size))
				dispatch(setIsAuth(true))
			})
			.catch(err => dispatch(toast("error", err?.response?.data?.message)))
	}

	return (
		<div className={styles.container}>
			<div className={styles.form}>
				<div className={styles.logo}></div>
				<div className={styles.Header}> Create Account </div>

				<div className={styles.loginInputs}>
					<div className={cn(styles.label, styles.headerLabel)}>
						<div>Profile</div>
						<hr className={styles.rounded} />
					</div>

					<div className={styles.label}>Password</div>
					<PasswordInput
						className={styles.input}
						setValidePwd={setValidePwd}
						onChange={inputPassword.onChange}
						value={inputPassword.value}
						error={errors.password}
						validePwd={validePwd}
					/>

					<div className={styles.label}>Confirm Password</div>
					<Input
						onChange={inputConfirmPassword.onChange}
						type="password"
						className={styles.input}
						error={errors.confirmPassword}
					/>

					<div className={cn(styles.label, styles.headerLabel)}>
						<div>Organization</div>
						<hr className={styles.rounded} />
					</div>

					<div className={styles.label}>Company Name</div>
					<Input
						register
						onChange={inputOrgName.onChange}
						type={"text"}
						className={styles.input}
						placeholder={"Name"}
						error={errors.orgName}
					/>

					<div className={styles.label}>Account Plan</div>
					<Select
						selectFullWidth
						className={cn(styles.input)}
						setNewValue={setNewPlan}
						initValue={newPlan?.toLowerCase()}
						values={listPlan}
					/>

					{newPlan !== "individual" && (
						<div className={styles.seatsStyles}>
							<div className={styles.label}>Subscription Type </div>
							<Select
								selectFullWidth
								className={cn(styles.input)}
								setNewValue={setType}
								initValue={type ? (type === "monthly" ? "month" : "year") : ""}
								values={listType}
							/>
						</div>
					)}

					{newPlan !== "individual" && (
						<div className={styles.seatsStyles}>
							<div className={styles.label}>Choose team size: </div>
							<Select
								selectFullWidth
								className={cn(styles.input)}
								setNewValue={setNewSeat}
								initValue={newSeat}
								values={listSeats}
							/>
						</div>
					)}
				</div>
				<div className={styles.loginBtn}>
					<Btn
						onClick={handelRegister}
						type={"email"}
						className={cn(styles.btn, styles.btnSubmit)}
						disable={register}
					>
						<div className={styles.submitText}>Register</div>
						{/* Loader */}
						{register && <Loader />}
					</Btn>
				</div>
				<div className={styles.signUpLink}>
					Do you have an account ? <Link to={"/auth/login"}> Sign In</Link>
				</div>

				<footer className={styles.footer}>
					<nav>
						<ul className={styles.footerNav}>
							<li>
								<a href="https://app.termly.io/document/terms-of-use-for-website/f78e9e9b-bba1-4a94-983e-68a1005f28b0">
									Terms of Service
								</a>
							</li>
							<li>
								<a href="https://app.termly.io/document/privacy-policy/3754276d-7c3f-40ef-931d-584c9bc61e74">
									Privacy Policy
								</a>
							</li>
						</ul>
					</nav>
					<br></br>
					<h5>© 2023 SecureLabs Inc </h5>
				</footer>
			</div>
		</div>
	)
}
