import React, { useState } from "react"
import styles from "../../Documents/index.module.scss"
import cn from "classnames"
import { SvgSprite } from "../../../ui/SvgSprite"

export const Breakdown = ({ closePopup, activeControl, framework, guidance = true }) => {
	const [red, setRed] = useState(true)
	const [blue, setBlue] = useState(true)
	const [green, setgreen] = useState(true)

	return (
		<div className={styles.addPopup}>
			<div className={styles.controlPopupHeader}>
				<div>Breakdown</div>
				<div className={styles.BreakdownLeftHeader}>
					<SvgSprite
						spriteID={"close"}
						className={styles.closeIconPopup}
						onClick={closePopup}
					/>
				</div>
			</div>
			<div className={cn(styles.controlPopupBodyGod, styles.flexderec, styles.addscroll)}>
				<div className={styles.controlCardPopup}>
					<div className={styles.controlCardHeaderPopup}>
						<div className={styles.controlCardHeaderLeftPopup}>
							<SvgSprite
								spriteID={"question"}
								className={styles.controlCardHeaderIconPopup}
							/>
							<div>{"Control Question"}</div>
						</div>
						<div className={styles.controlCardHeaderReigthPopup}>
							{" "}
							<SvgSprite
								spriteID={"arrow"}
								className={styles.arrowInverst}
								onClick={() => setRed(!red)}
							/>
						</div>
					</div>
					{red && activeControl?.controlQuestions && (
						<div className={cn(styles.controlCardBodyPopupBrekdown, styles.borderRed)}>
							<div className={styles.textPopup}>
								<div
									dangerouslySetInnerHTML={{
										__html: activeControl.controlQuestions,
									}}
								/>
							</div>
						</div>
					)}
				</div>

				<div className={styles.controlCardPopup}>
					<div className={styles.controlCardHeaderPopup}>
						<div className={styles.controlCardHeaderLeftPopup}>
							<SvgSprite
								spriteID={"info"}
								className={styles.controlCardHeaderIconPopup}
							/>
							<div>{"Methods To Comply"}</div>
						</div>
						<div className={styles.controlCardHeaderReigthPopup}>
							{" "}
							<SvgSprite
								spriteID={"arrow"}
								className={styles.arrowInverst}
								onClick={() => setBlue(!blue)}
							/>
						</div>
					</div>
					{blue && activeControl?.complyMethods && (
						<div className={cn(styles.controlCardBodyPopupBrekdown, styles.borderBlue)}>
							<div className={styles.textPopup}>
								<div
									dangerouslySetInnerHTML={{
										__html: activeControl.complyMethods,
									}}
								/>
							</div>
						</div>
					)}
				</div>

				<div className={styles.controlCardPopup}>
					<div className={styles.controlCardHeaderPopup}>
						<div className={styles.controlCardHeaderLeftPopup}>
							<SvgSprite
								spriteID={"info"}
								className={styles.controlCardHeaderIconPopup}
							/>
							<div>{"Evidence Collection"}</div>
						</div>
						<div className={styles.controlCardHeaderReigthPopup}>
							{" "}
							<SvgSprite
								spriteID={"arrow"}
								className={styles.arrowInverst}
								onClick={() => setgreen(!green)}
							/>
						</div>
					</div>
					{green && activeControl?.evidenceCollection && (
						<div className={cn(styles.controlCardBodyPopupBrekdown, styles.borderGreen)}>
							<div className={styles.textPopup}>
								<div
									dangerouslySetInnerHTML={{
										__html: activeControl.evidenceCollection,
									}}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
