import { combineReducers } from "redux"
import { configureStore } from "@reduxjs/toolkit"

// Slices
import userSlice from "./slices/userSlice"
import orgSlice from "./slices/orgSlice"
import incidentReportSlice from "./slices/incidentReportSlice"
import controlSubmissionSlice from "./slices/controlSubmissionSlice"
import controlSlice from "./slices/controlSlice"
import frameworkSlice from "./slices/frameworkSlice"
import allFrameworkSlice from "./slices/allFrameworkSlice"
import controlLogsSlice from "./slices/controlLogsSlice"
import archivedUsersSlice from "./slices/archivedUsersSlice"
import resourcesSlice from "./slices/resourcesSlice"
import eventsSlice from "./slices/eventsSlice"
import notificationsSlice from "./slices/notificationsSlice"
import documentsSlice from "./slices/documentsSlice"

const rootReducer = combineReducers({
	user: userSlice,
	organization: orgSlice,
	incidentReport: incidentReportSlice,
	controlSubmission: controlSubmissionSlice,
	control: controlSlice,
	framework: frameworkSlice,
	allFramework: allFrameworkSlice,
	controlLogs: controlLogsSlice,
	archivedUsers: archivedUsersSlice,
	resources: resourcesSlice,
	events: eventsSlice,
	notifications: notificationsSlice,
	documents: documentsSlice,
})

export const store = configureStore({
	reducer: rootReducer,
})
