import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	controls: null,
	isLoading: false,
	nbrOfControls: 0,
}

const controlSlice = createSlice({
	name: "controls",
	initialState,
	reducers: {
		setControlIsLoading(state, action) {
			state.isLoading = action.payload
		},

		setControl(state, action) {
			state.controls = action.payload
		},

		setNbrOfControls(state, action) {
			state.nbrOfControls = action.payload
		},
	},
})

export default controlSlice.reducer
export const { setControlIsLoading, setControl, setNbrOfControls } = controlSlice.actions
