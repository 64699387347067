import { setArchivedUsers } from "../../store/slices/archivedUsersSlice"
import { axiosInstance } from "../config/https"

export const getArchivedUsers = () => {
	return async dispatch => {
		const res = await axiosInstance("/archived/users")

		if (res.status === 200) {
			dispatch(setArchivedUsers(res.data.data))
		}
	}
}
