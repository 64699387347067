import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import styles from "./index.module.scss"
import cn from "classnames"

// components
import { Btn } from "../../ui/Btn"
import { Loader } from "../../ui/Loader"
import { Input } from "../../ui/Input"

// hooks
import { useInput } from "../../hooks/useInput"
import { useDispatch } from "react-redux"

// Functions
import { checkErrors } from "../../functions/checkErrors"
import { toast } from "../../functions/toast"

// Utils
import { setCookie } from "../../utils/cookie"

// slices
import {
	setAccountPlan,
	setIsAuth,
	setNotificationNabr,
	setQrCode,
	setTeamSize,
	setUserInfo,
} from "../../../store/slices/userSlice"

// axois
import { axiosInstance } from "../../config/https"
import { SvgSprite } from "../../ui/SvgSprite/SvgSprite"

export const Login = () => {
	const email = useInput()
	const password = useInput()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [login, setLogin] = useState(false)
	const [errors, setErrors] = useState({})
	const [loginUrl, setLoginUrl] = useState(null)
	const [loginUrlMicrosoft, setLoginUrlMicrosoft] = useState(null)

	const handelConnect = async () => {
		setLogin(true)
		const inputsInfo = [
			{ el: email, errorName: "email", errorText: "Please Enter Your Email" },
			{ el: password, errorName: "password", errorText: "Enter Password" },
		]

		if (await checkErrors(setErrors, inputsInfo)) {
			setLogin(false)
			return
		}
		const data = {
			email: email.value,
			password: password.value,
		}

		await axiosInstance
			.post("/auth/login", data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(async res => {
				if (res.data.subscription) {
					if (res.data.Two_Factor) {
						navigate("/auth/login/verify", { state: res.data })
					} else {
						setCookie("access_token", res.data.access_token, res.data.expires_in, true)
						dispatch(setUserInfo({ ...res.data.user }))
						dispatch(setQrCode(res.data.qr_code_url))
						dispatch(setAccountPlan(res.data?.account_plan))
						dispatch(setNotificationNabr(res.data.notifications_nbr))
						dispatch(setTeamSize(res.data.team_size))
						dispatch(setIsAuth(true))
						navigate("/dashboard")
					}
				} else {
					dispatch(setUserInfo({ ...res.data.user }))
					dispatch(setAccountPlan(res.data?.account_plan))
					dispatch(setTeamSize(res?.data?.team_size))
					navigate("/auth/payment", { state: res.data })
				}
			})
			.catch(err => {
				setErrors({ email: "The email or password entered does not match our records." })
				if (err?.response?.data?.errors) {
					for (const [key, value] of Object.entries(err?.response?.data?.errors)) {
						dispatch(toast("error", `${key}: ${value}`))
					}
				} else dispatch(toast("error", err?.response?.data?.message))
			})
		setLogin(false)
	}

	useEffect(() => {
		axiosInstance("auth/google")
			.then(res => setLoginUrl(res.data.url))
			.catch(error => console.error(error))
	}, [])

	useEffect(() => {
		axiosInstance("auth/microsoft")
			.then(res => setLoginUrlMicrosoft(res.data.url))
			.catch(error => console.error(error))
	}, [])

	return (
		<div className={styles.container}>
			<div className={styles.form}>
				<div className={styles.logo}></div>
				<div className={styles.Header}> Login Into Your Account</div>

				<div className={styles.loginInputs}>
					<div className={styles.label}>Email</div>

					<Input
						onChange={email.onChange}
						type={"email"}
						className={styles.input}
						placeholder={"Email"}
						error={errors.email}
					/>

					<div className={styles.label}>Password</div>

					<Input
						onChange={password.onChange}
						type={"password"}
						className={styles.input}
						error={errors.password}
					/>
				</div>
				<div className={styles.loginBtn}>
					<Btn
						onClick={handelConnect}
						type={"email"}
						className={cn(styles.btn, styles.btnSubmit)}
						disable={login}
					>
						<div className={styles.submitText}>Login</div>
						{/* Loader */}
						{login && <Loader />}
					</Btn>
					<div className={styles.connectWith}>
						<a
							href={loginUrl}
							className={cn(styles.btn, styles.btnGoogle, styles.btnGoogleBorder)}
						>
							<SvgSprite
								spriteID={"google"}
								className={styles.googleIcon}
							/>
							<div>Login With Google</div>
						</a>
						<a
							href={loginUrlMicrosoft}
							className={cn(styles.btn, styles.btnGoogle, styles.btnMicroBorder)}
						>
							<SvgSprite
								spriteID={"microsoft"}
								className={styles.googleIcon}
							/>
							<div>Login With Microsoft</div>
						</a>
					</div>
				</div>
				<div className={styles.signUpLink}>
					Don't have an account? <Link to={"/auth/register"}>Create account</Link>
				</div>
				<div className={styles.signUpLink}>
					<Link to={"/auth/forgot-password"}>Forgot your password?</Link>
				</div>
				{/* Place Footer Elemenent at  in the div */}
				<footer>
					<nav>
						<ul>
							<li>
								<a href="https://app.termly.io/document/terms-of-use-for-website/f78e9e9b-bba1-4a94-983e-68a1005f28b0">
									Terms of Service
								</a>
							</li>
							<li>
								<a href="https://app.termly.io/document/privacy-policy/3754276d-7c3f-40ef-931d-584c9bc61e74">
									Privacy Policy
								</a>
							</li>
						</ul>
					</nav>
					<br></br>
					<h5>© 2023 SecureLabs Inc </h5>
				</footer>
			</div>
		</div>
	)
}
