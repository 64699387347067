import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { Audit, AuditDocumentForm, Controls } from "../core/modules/Auditor"

export const AuditorRoutes = () => {
	return (
		<Routes>
			<Route
				path="/framework/:id"
				element={<Audit />}
				exact
			/>
			<Route
				path="/framework/control/:controlId/submission"
				element={<AuditDocumentForm />}
				exact
			/>
			<Route
				path="/controls"
				element={<Controls />}
				exact
			/>

			<Route
				path="*"
				element={
					<Navigate
						to="/controls"
						replace
					/>
				}
			/>
		</Routes>
	)
}
