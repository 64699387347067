import React, { useEffect, useState } from "react"
import styles from "./index.module.scss"
import cn from "classnames"

// components
import { Btn } from "../../ui/Btn"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Input } from "../../ui/Input/Input"
import { Loader } from "../../ui/Loader"
import { Select } from "../../ui/Select/Select"

// hooks
import { useInput } from "../../hooks/useInput"
import { useDispatch } from "react-redux"

// functions
import { checkErrors } from "../../functions/checkErrors"
import { toast } from "../../functions/toast"

// axois
import { axiosInstance } from "../../config/https"
import PasswordInput from "../../ui/PasswordInput/PasswordInput"

export const Register = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [registerForm, setRegisterForm] = useState(false)
	const [type, setType] = useState()
	const inputFirstName = useInput("")
	const inputLastName = useInput("")
	const inputPhone = useInput("")
	const inputEmail = useInput("")
	const inputPassword = useInput("")
	const inputConfirmPassword = useInput("")
	const inputOrgName = useInput("")
	const [register, setRegister] = useState(false)
	const location = useLocation()

	const [listSeats] = useState([
		{
			id: 0,
			name: "3 seats",
			value: "3",
		},
		{
			id: 1,
			name: "5 seats",
			value: "5",
		},
		{
			id: 2,
			name: "10 seats",
			value: "10",
		},
		{
			id: 3,
			name: "15 seats",
			value: "15",
		},
		{
			id: 4,
			name: "20 seats",
			value: "20",
		},
		{
			id: 5,
			name: "25 seats",
			value: "25",
		},
		{
			id: 6,
			name: "30 seats",
			value: "30",
		},
		{
			id: 7,
			name: "40 seats",
			value: "40",
		},
		{
			id: 8,
			name: "50 seats",
			value: "50",
		},
		{
			id: 9,
			name: "100 seats",
			value: "100",
		},
	])
	const [errors, setErrors] = useState({})

	const [newPlan, setNewPlan] = useState()
	const [newSeat, setNewSeat] = useState()

	const [validePwd, setValidePwd] = useState(false)

	const [listType] = useState([
		{
			id: 0,
			name: "Monthly",
			value: "month",
		},
		{
			id: 1,
			name: "Yearly",
			value: "year",
		},
	])

	const [listPlan] = useState([
		// {
		//     id: 0,
		//     name:  'Individual',
		//     value: 'individual',
		// },
		{
			id: 1,
			name: "Basic",
			value: "basic",
		},
		{
			id: 2,
			name: "Standard",
			value: "standard",
		},
		{
			id: 3,
			name: "Enterprise",
			value: "enterprise",
		},
	])

	const handelRegister = async () => {
		setRegister(true)
		const inputsInfo = [
			{ el: inputFirstName, errorName: "firstName", errorText: "Enter First Name" },
			{ el: inputLastName, errorName: "lastName", errorText: "Enter Last Name" },
			{ el: inputPhone, errorName: "phone", errorText: "Enter phone" },
			{ el: inputEmail, errorName: "email", errorText: "Please enter your email" },

			{ el: inputPassword, errorName: "password", errorText: "Enter Password" },
			{ el: inputConfirmPassword, errorName: "confirmPassword", errorText: "Enter Password" },
			{ el: inputOrgName, errorName: "orgName", errorText: "Enter organization Name" },
		]

		let mode = ""
		if (process.env.NODE_ENV === "development") {
			mode = "test"
		}

		const searchParams = new URLSearchParams(location.search)
		const nbrSeats = searchParams.get("nbr_seats")

		if (await checkErrors(setErrors, inputsInfo)) {
			setRegister(false)
			return
		}

		if (!validePwd) {
			let tempErrors = []
			tempErrors["password"] = "Choose a stronger password"
			setErrors(tempErrors)
			setRegister(false)
			return
		}

		if (inputPassword.value !== inputConfirmPassword.value) {
			let tempErrors = []
			tempErrors["confirmPassword"] = "Passwords do not match"
			setErrors(tempErrors)
			setRegister(false)
			return
		}
		const data = {
			firstName: inputFirstName.value,
			lastName: inputLastName.value,
			phone: inputPhone.value,
			email: inputEmail.value,
			password: inputPassword.value,
			name: inputOrgName.value,
			account_plan: newPlan,
			teamSize: nbrSeats ? nbrSeats : newPlan.toLowerCase() === "individual" ? 2 : newSeat,
			type: type,
			plan: newPlan,
			quantity: nbrSeats ? nbrSeats : newPlan.toLowerCase() === "individual" ? 2 : newSeat,
			mode: mode,
		}

		await axiosInstance
			.post("/auth/register", data, {
				headers: {
					"content-type": "multipart/form-data",
				},
			})
			.then(async res => {
				if (res.status === 200) {
					navigate("/auth/verify-email", { state: inputEmail.value })
				}
			})
			.catch(err => {
				if (err?.response?.data?.errors) {
					for (const [key, value] of Object.entries(err?.response?.data?.errors)) {
						dispatch(toast("error", `${key}: ${value}`))
					}
				} else dispatch(toast("error", err?.response?.data?.message))
			})
			.finally(() => {
				setRegister(false)
			})
	}

	const handelCheckEmail = async () => {
		setRegister(true)
		const inputsInfo = [{ el: inputEmail, errorName: "email", errorText: "Please enter your email" }]

		if (await checkErrors(setErrors, inputsInfo)) {
			setRegister(false)
			return
		}
		const data = {
			email: inputEmail.value,
		}
		await axiosInstance
			.post("/email/check", data)
			.then(async res => {
				if (res.status === 200) {
					setRegisterForm(true)
				}
			})
			.catch(err => {
				if (err.response.data.errors) {
					for (const [key, value] of Object.entries(err.response.data.errors)) {
						dispatch(toast("error", `${key}: ${value}`))
					}
				} else {
					dispatch(toast("error", err?.response?.data?.message))
				}
			})
			.finally(() => {
				setRegister(false)
			})
	}

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search)
		if (searchParams) {
			setNewPlan(searchParams.get("plan"))
			setNewSeat(searchParams.get("seats"))
			setType(searchParams.get("billing"))
		}
	}, [location])
	return (
		<div className={styles.container}>
			<div className={cn(styles.form)}>
				<div className={styles.logo}></div>
				<div className={styles.Header}> Create Account </div>

				<div className={styles.loginInputs}>
					{!registerForm ? (
						<div className={styles.loginInputs}>
							{/* <div className={styles.label}>Email</div> */}
							<Input
								register
								onChange={inputEmail.onChange}
								type={"email"}
								className={styles.input}
								placeholder={"Email"}
								error={errors.email}
							/>
						</div>
					) : (
						<div className={styles.loginInputs}>
							<div className={cn(styles.label, styles.headerLabel)}>
								<div>Profile</div>
								<hr className={styles.rounded} />
							</div>

							<div className={styles.label}>First Name</div>
							<Input
								register
								onChange={inputFirstName.onChange}
								type={"text"}
								className={styles.input}
								placeholder={"First Name"}
								error={errors.firstName}
							/>

							<div className={styles.label}>Last Name</div>
							<Input
								register
								onChange={inputLastName.onChange}
								type={"text"}
								className={styles.input}
								placeholder={"Last Name"}
								error={errors.lastName}
							/>

							<div className={styles.label}>Phone</div>
							<Input
								register
								onChange={inputPhone.onChange}
								type={"tel"}
								className={styles.input}
								placeholder={"Phone"}
								error={errors.phone}
							/>

							<div className={styles.label}>Email</div>
							<Input
								register
								onChange={inputEmail.onChange}
								type={"email"}
								className={styles.input}
								value={inputEmail.value || ""}
								placeholder={"Email"}
								disabled={registerForm}
								error={errors.email}
							/>

							<div className={styles.label}>Password</div>
							{/* <Input 
                        register
                        onChange={inputPassword.onChange} 
                        type='password'
                        className={styles.input}
                        error={errors.password}/> */}

							<PasswordInput
								className={styles.input}
								setValidePwd={setValidePwd}
								onChange={inputPassword.onChange}
								value={inputPassword.value}
								error={errors.password}
								validePwd={validePwd}
							/>
							<div className={styles.label}>Confirm Password</div>
							<Input
								register
								onChange={inputConfirmPassword.onChange}
								type="password"
								className={styles.input}
								error={errors.confirmPassword}
							/>

							<div className={cn(styles.label, styles.headerLabel)}>
								<div>Organization</div>
								<hr className={styles.rounded} />
							</div>

							<div className={styles.label}>Company Name</div>
							<Input
								register
								onChange={inputOrgName.onChange}
								type={"text"}
								className={styles.input}
								placeholder={"Name"}
								error={errors.orgName}
							/>

							<div className={styles.label}>Account Plan</div>
							<Select
								selectFullWidth
								className={cn(styles.input)}
								setNewValue={setNewPlan}
								initValue={newPlan?.toLowerCase()}
								values={listPlan}
							/>

							{newPlan !== "individual" && (
								<div className={styles.seatsStyles}>
									<div className={styles.label}>Subscription Type </div>
									<Select
										selectFullWidth
										className={cn(styles.input)}
										setNewValue={setType}
										initValue={type ? (type === "monthly" ? "month" : "year") : ""}
										values={listType}
									/>
								</div>
							)}

							{newPlan !== "individual" && (
								<div className={styles.seatsStyles}>
									<div className={styles.label}>Choose team size: </div>
									<Select
										selectFullWidth
										className={cn(styles.input)}
										setNewValue={setNewSeat}
										initValue={newSeat}
										values={listSeats}
									/>
								</div>
							)}
						</div>
					)}
					<div className={styles.loginBtn}>
						<Btn
							onClick={registerForm ? handelRegister : handelCheckEmail}
							type={"email"}
							className={cn(styles.btn, styles.btnSubmit)}
							disable={register}
						>
							<div className={styles.submitText}>{registerForm ? "Register" : "Next"}</div>

							{/* Loader */}
							{register && <Loader />}
						</Btn>
					</div>
					<div className={cn(styles.signUpLink, styles.signUpLinkPadding)}>
						Already have an account? <Link to={"/auth/login"}> Login here</Link>
					</div>
					<footer className={styles.footer}>
						<nav>
							<ul className={styles.footerNav}>
								<li>
									<a href="https://app.termly.io/document/terms-of-use-for-website/f78e9e9b-bba1-4a94-983e-68a1005f28b0">
										Terms of Service
									</a>
								</li>
								<li>
									<a href="https://app.termly.io/document/privacy-policy/3754276d-7c3f-40ef-931d-584c9bc61e74">
										Privacy Policy
									</a>
								</li>
							</ul>
						</nav>
						<br></br>
						<h5>© 2023 SecureLabs Inc </h5>
					</footer>
				</div>
			</div>
		</div>
	)
}
