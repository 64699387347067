import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useInput } from "../../hooks/useInput"
import { Btn } from "../../ui/Btn"
import { Input } from "../../ui/Input"
import { Loader } from "../../ui/Loader"
import styles from "./index.module.scss"
import cn from "classnames"
import { axiosInstance } from "../../config/https"

export const Invite = () => {
	const email = useInput()
	const role = useInput()

	const [login, setLogin] = useState(false)

	const handelInvite = async () => {
		setLogin(true)

		const data = {
			email: email.value,
			role: role.value,
		}

		await axiosInstance.post("/auth/invite/send", data).finally(() => {
			setLogin(false)
		})
	}

	return (
		<div className={styles.form}>
			<div className={styles.logo}></div>

			<div className={styles.Header}> Invite members to your organization</div>

			<div className={styles.loginInputs}>
				<div className={styles.label}>Email</div>

				<Input
					onChange={email.onChange}
					type={"email"}
					className={styles.input}
					placeholder={"Email"}
				/>

				<div className={styles.label}>Role</div>

				<Input
					onChange={role.onChange}
					type={"text"}
					className={styles.input}
					placeholder={"Role"}
				/>
			</div>
			<div className={styles.loginBtn}>
				<Btn
					onClick={handelInvite}
					type={"email"}
					className={cn(styles.btn, styles.btnSubmit)}
					disable={login}
				>
					<div className={styles.submitText}>Submit</div>
					{/* Loader */}
					{login && <Loader />}
				</Btn>
			</div>

			<footer className={styles.footer}>
				<nav>
					<ul className={styles.footerNav}>
						<li>
							<a href="https://app.termly.io/document/terms-of-use-for-website/f78e9e9b-bba1-4a94-983e-68a1005f28b0">
								Terms of Service
							</a>
						</li>
						<li>
							<a href="https://app.termly.io/document/privacy-policy/3754276d-7c3f-40ef-931d-584c9bc61e74">
								Privacy Policy
							</a>
						</li>
					</ul>
				</nav>
				<br></br>
				<h5>© 2023 SecureLabs Inc </h5>
			</footer>
			<div className={styles.signUpLink}>
				{" "}
				<Link to={"/home"}>Home</Link>
			</div>
		</div>
	)
}
