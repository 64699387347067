// Hooks
import { useAuth } from "./core/hooks/useAuth"
import { useSelector } from "react-redux"
import { useCallback, useEffect, useState } from "react"
import { useMobile } from "./core/hooks/useMobile"

// Components
import { Notifications } from "./core/ui/Notifications"

// Routes
import { AuthRoutes } from "./routes/AuthRoutes"
import { hotjar } from "react-hotjar"
import { useNavigate, useSearchParams } from "react-router-dom"
import { setCookie } from "./core/utils/cookie"
import { DashboardWrapper } from "./core/ui/DashboardWrapper"
import { ErrorBoundary } from "./core/utils/ErrorBoundary "

hotjar.initialize("3467781", "6")

const App = () => {
	const isAuth = useSelector(state => state.user.isAuth)
	const { checkIsAuth, isChecked } = useAuth()
	const [expanded, setExpanded] = useState(true)
	const isMobile = useMobile(1300)
	const [queryParameters] = useSearchParams()
	const navigate = useNavigate()

	const authenticate = useCallback(() => {
		checkIsAuth()
		if (queryParameters.get("chatId") && isAuth) {
			setCookie("active_chat", queryParameters.get("chatId"))
			navigate("/help")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuth])

	useEffect(() => {
		authenticate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		isMobile ? setExpanded(false) : setExpanded(true)
	}, [isMobile])

	return (
		isChecked && (
			<ErrorBoundary>
				{isAuth ? (
					<DashboardWrapper
						expanded={expanded}
						setExpanded={setExpanded}
					/>
				) : (
					<AuthRoutes />
				)}
				<Notifications />
			</ErrorBoundary>
		)
	)
}

export default App
