import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import styles from "./index.module.scss";
import cn from "classnames";
import { DotsLoader } from "../../ui/Loader";
import { getControlsState } from "../../functions/getControlsState";
import { getEvents } from "../../functions/getEvents";
import { Popup } from "../../ui/Popup";
import { AddEventComponent } from "../Calendar/AddEventComponent";
import Avatar from "../../ui/Avatar";
import { ProfileImage } from "../../ui/ProfileImage/ProfileImage";
import moment from "moment";

const colors = [
  {
    id: 0,
    name: (
      <div className={styles.colorType}>
        <div
          className={styles.typePlays}
          style={{ backgroundColor: "#8833FF" }}
        >
          <div className={styles.typecercel}></div>
        </div>
        General
      </div>
    ),
    value: "#8833FF",
    color: "#8833FF",
    typeName: "General",
  },
  {
    id: 1,
    name: (
      <div className={styles.colorType}>
        <div
          className={styles.typePlays}
          style={{ backgroundColor: "#E62E2E" }}
        >
          <div className={styles.typecercel}></div>
        </div>
        Audit
      </div>
    ),
    value: "#E62E2E",
    color: "#E62E2E",
    typeName: "Audit",
  },
  {
    id: 2,
    name: (
      <div className={styles.colorType}>
        <div
          className={styles.typePlays}
          style={{ backgroundColor: "#33BFFF" }}
        >
          <div className={styles.typecercel}></div>
        </div>
        Review
      </div>
    ),
    value: "#33BFFF",
    color: "#33BFFF",
    typeName: "Review",
  },
  {
    id: 3,
    name: (
      <div className={styles.colorType}>
        <div
          className={styles.typePlays}
          style={{ backgroundColor: "#29CC39" }}
        >
          <div className={styles.typecercel}></div>
        </div>
        Task
      </div>
    ),
    value: "#29CC39",
    color: "#29CC39",
    typeName: "Task",
  },
  {
    id: 4,
    name: (
      <div className={styles.colorType}>
        <div
          className={styles.typePlays}
          style={{ backgroundColor: "#000000" }}
        >
          <div className={styles.typecercel}></div>
        </div>
        Incident
      </div>
    ),
    value: "#000000",
    color: "#000000",
    typeName: "Incident",
  },
];

export const CalendarWidget = ({
  allEvents,
  setDropSort,
  sortBy,
  events,
  setEvents,
  setDropStatus,
  setDropType,
  dropStatusValue,
  dropTypeValue,
  dropdownRef,
  dropdownRefStatus,
  dropdownRefType,
}) => {
  const dispatch = useDispatch();

  const getHomeData = useCallback(async () => {
    await Promise.all([dispatch(getControlsState()), dispatch(getEvents())]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getHomeData();
  }, [getHomeData]);

  useEffect(() => {
    const handleClickOutside = event => {
      // Close the dropdown if the click is outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropSort(false);
      }

      if (dropdownRefStatus.current && !dropdownRefStatus.current.contains(event.target)) {
        setDropStatus(false);
      }

      if (dropdownRefType.current && !dropdownRefType.current.contains(event.target)) {
        setDropType(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  return (
    <div className={styles.calendarWidget}>
      <div className={styles.list}>
        <EventsList
          events={events || []}
          loader={allEvents.isLoading}
          sortBy={sortBy}
          status={dropStatusValue}
          type={dropTypeValue}
          setEvents={setEvents}
        />
      </div>
    </div>
  );
};

function EventsList({ events, loader, sortBy, status, type, setEvents }) {
  const [event, setEvent] = useState(false);
  console.log(events);
  return (
    <div className={styles.containerListUser}>
      {!loader && (
        <table className={styles.eventsTable}>
          <thead>
            <tr>
              <th>Owner</th>
              <th>Type</th>
              <th>Title</th>
              <th>Scheduled Dates</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {events?.map(data => (
              <tr
                onClick={() => setEvent(data)}
                // className={cn(styles.userList, styles.item)}
                className={styles.tbodyRow}
                key={data.id}
              >
                <td>
                  {data?.members[0]?.image ? (
                    <Avatar imagePath={data?.members[0]?.image} />
                  ) : (
                    <ProfileImage name={data?.members[0]?.firstName || 'N/A'} />
                  )}
                </td>
                <td>{colors.find(item => item.color === data?.colorEvent)?.typeName}</td>
                <td>{data.title}</td>
                <td>
                  {moment(data.start).format("M/D/YYYY")} - {moment(data.end).format("M/D/YYYY")}
                </td>
                <td>
                  {!data?.completed_at && moment().isAfter(moment(data.end)) && (
                    <span
                      className={styles.eventLabel}
                      style={{ color: "red" }}
                    >
                      Past Due
                    </span>
                  )}
                  {!data?.completed_at && moment().isBefore(moment(data.end)) && (
                    <span
                      className={styles.eventLabel}
                      style={{ color: "#ffca00" }}
                    >
                      Pending
                    </span>
                  )}
                  {data?.completed_at && (
                    <span
                      className={styles.eventLabel}
                      style={{ color: "#32bf32" }}
                    >
                      Completed
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {event && (
        <Popup
          calendar
          isOpenedPopup={event}
          closePopup={() => {
            setEvent(null);
          }}
        >
          <AddEventComponent
            closePopup={() => {
              setEvent(null);
            }}
            title={"Add Event"}
            setEvents={setEvents}
            events={events}
            startAccessor="start"
            endAccessor="end"
            stateData={event}
            newEvent={event}
            report_id={event?.report_id}
            report_title={event?.title}
            report={event?.report_id || event?.report}
            updateEvent
          />
        </Popup>
      )}
      {!loader && events?.length === 0 && (
        <div className={cn(styles.loaderCenter, styles.loaderStyles)}>
          No {status !== "all" ? status : ""} {type !== "all" ? type : ""} events available{" "}
          {sortBy !== "all" ? `this ${sortBy}` : ""}
        </div>
      )}
      {loader && (
        <div className={cn(styles.loaderCenter, styles.loaderStyles)}>
          <DotsLoader />
        </div>
      )}
    </div>
  );
}
