import React, { useState } from "react"
import { Link } from "react-router-dom"
import styles from "./index.module.scss"
import cn from "classnames"
// components
import { Btn } from "../../ui/Btn"
import { Input } from "../../ui/Input"
// axois
import axios from "axios"
// hooks
import { useInput } from "../../hooks/useInput"
// functions
import { checkErrors } from "../../functions/checkErrors"
// API_URL
import { config } from "../../utils/Constants"
import { useDispatch } from "react-redux"
import { toast } from "../../functions/toast"
import { Loader } from "../../ui/Loader/Loader"

export const ForgotPassword = () => {
	const email = useInput()
	const dispatch = useDispatch()
	const [loader, setLoader] = useState(false)

	const [errors, setErrors] = useState({})

	const forgetPwd = async () => {
		setLoader(true)
		const inputsInfo = [{ el: email, errorName: "email", errorText: "Please enter your email to reset your password" }]

		if (await checkErrors(setErrors, inputsInfo)) {
			setLoader(false)
			return
		}

		const data = {
			email: email.value,
		}

		await axios
			.post(`${config.url.API_URL}/api/forgot-password`, data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(() => {
				dispatch(toast("success", "Mail send With Success"))
			})
			.catch(err => {
				if (err?.response?.data?.errors) {
					for (const [key, value] of Object.entries(err?.response?.data?.errors)) {
						dispatch(toast("error", `${key}: ${value}`))
					}
				} else dispatch(toast("error", err?.response?.data?.message))
			})
			.finally(() => {
				setLoader(false)
			})
	}
	return (
		<div className={styles.container}>
			<div className={styles.form}>
				<div className={styles.logo}></div>
				<div className={styles.Header}> Forgot Password</div>

				<div className={styles.loginInputs}>
					{/* <div className={styles.label}>Email</div> */}

					<Input
						onChange={email.onChange}
						type={"email"}
						className={styles.input}
						placeholder={"Email"}
						error={errors.email}
					/>
				</div>
				<div className={styles.loginBtn}>
					<Btn
						onClick={forgetPwd}
						className={cn(styles.btn, styles.btnSubmit, styles.btnLoader)}
					>
						<div>Send Email </div>
						{loader && <Loader />}
					</Btn>
				</div>
				<div className={styles.signUpLink}>
					<Link to={"/auth/login"}>Cancel</Link>
				</div>

				<footer className={styles.footer}>
					<nav>
						<ul className={styles.footerNav}>
							<li>
								<a href="https://app.termly.io/document/terms-of-use-for-website/f78e9e9b-bba1-4a94-983e-68a1005f28b0">
									Terms of Service
								</a>
							</li>
							<li>
								<a href="https://app.termly.io/document/privacy-policy/3754276d-7c3f-40ef-931d-584c9bc61e74">
									Privacy Policy
								</a>
							</li>
						</ul>
					</nav>
					<br></br>
					<h5>© 2023 SecureLabs Inc </h5>
				</footer>
			</div>
		</div>
	)
}
