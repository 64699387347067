import React, { useEffect, useState } from "react"
import styles from "../../Documents/index.module.scss"
import cn from "classnames"
import { Btn } from "../../../ui/Btn"
import { Search } from "../../../ui/Search/Search"
import { DotsLoader, Loader } from "../../../ui/Loader"
import { useQuery } from "@tanstack/react-query"
import { api } from "../../../services/api"
import { SvgSprite } from "../../../ui/SvgSprite"
import { axiosInstance } from "../../../config/https"
import { useDispatch } from "react-redux"
import { toast } from "../../../functions/toast"

export const Controls = () => {
	const [data, setData] = useState()
	const [searchData, setSearchData] = useState([])
	const [loader, setLoader] = useState(false)
	const [sortItem, setSortItem] = useState("default")
	const [search, setSearch] = useState("")
	const [selectedItems, setSelectedItems] = useState([])
	const dispatch = useDispatch()

	const controlsQuery = useQuery({
		queryKey: ["controls"],
		queryFn: () => api.fetchData("auditor/controls"),
	})

	const controlsAuditQuery = useQuery({
		queryKey: ["auditor", "controls"],
		queryFn: () => api.fetchData("audit-controls"),
	})

	const svgDeselect = (
		<SvgSprite
			className={styles.svgIconsEmpty}
			spriteID={"list"}
		></SvgSprite>
	)
	const svgSelect = (
		<SvgSprite
			className={styles.CustomSvgIcons}
			spriteID={"check"}
		></SvgSprite>
	)

	const auditControl = async () => {
		setLoader(true)
		await axiosInstance
			.post("audit-controls/update", { controlsIds: selectedItems })
			.then(response => {
				dispatch(toast("success", response.data.message))
				controlsAuditQuery.refetch()
			})
			.catch(err => {
				dispatch(toast("error", err))
			})
			.finally(() => {
				setLoader(false)
			})
	}

	useEffect(() => {
		setSelectedItems(controlsAuditQuery.data?.controls?.map(c => c.id))
	}, [controlsAuditQuery.data])

	useEffect(() => {
		setSearchData(
			controlsQuery.data?.controls?.filter(item => item?.name?.toLowerCase()?.includes(search?.toLowerCase())),
		)
	}, [controlsQuery.data, search])

	const compareBy = key => {
		return function (a, b) {
			if (a[key] < b[key]) return -1
			if (a[key] > b[key]) return 1
			return 0
		}
	}

	const sortBy = (key, direc) => {
		let arrayCopy = [...controlsQuery.data?.controls]
		if (direc === -1) {
			arrayCopy.sort(compareBy(key)).reverse()
		} else {
			arrayCopy.sort(compareBy(key))
		}
		setData([...arrayCopy])
	}
	return (
		<div className={styles.main}>
			<div className={styles.header}>
				<div className={cn(styles.rieghtHeader, styles.rieghtHeaderHiegth)}>
					<div className={styles.auditHeaderTitle}> All Controls </div>
				</div>
				<div className={styles.leftHeader}>
					<Btn
						disable={loader}
						className={styles.addBtn}
						onClick={() => auditControl()}
					>
						{loader ? <Loader /> : "Done"}
					</Btn>
				</div>
			</div>
			<div className={styles.subHeader}>
				<div className={styles.controls}>
					{selectedItems?.length} / {controlsQuery?.data?.controls?.length} Controls
				</div>
				<div className={styles.gridSearch}>
					<div className={styles.search}>
						<Search
							setSearch={setSearch}
							className={styles.search}
							placeholder={"Search Controls"}
						/>
					</div>
				</div>
				<div className={styles.sort}>
					Sort By:
					<div
						className={cn(styles.sortItem, { [styles.sortItemActive]: sortItem === "default" })}
						onClick={() => {
							setSortItem("default")
							sortBy("default")
						}}
					>
						Default
					</div>
					<div
						className={cn(styles.sortItem, { [styles.sortItemActive]: sortItem === "A-Z" })}
						onClick={() => {
							setSortItem("A-Z")
							sortBy("name")
						}}
					>
						A-Z
					</div>
					<div
						className={cn(styles.sortItem, { [styles.sortItemActive]: sortItem === "Z-A" })}
						onClick={() => {
							setSortItem("Z-A")
							sortBy("name", -1)
						}}
					>
						Z-A
					</div>
				</div>
			</div>
			<div className={styles.body}>
				<div className={styles.tableAuditor}>
					<div className={styles.tableHeaderAuditor}>
						<div className={styles.nameAuditor}>Name</div>
						<div className={styles.descriptionAuditor}>Description</div>
						<div className={styles.tableActionAuditor}>Select</div>
					</div>
					<div className={styles.TableBodyAuditor}>
						{controlsQuery.isLoading ? (
							<div className={styles.bodyDataControls}>
								<DotsLoader />
							</div>
						) : (
							(search ? searchData : data || controlsQuery.data?.controls).map((c, i) => (
								<div
									key={i}
									className={cn(styles.rowAuditor, styles.newFrameworkRow)}
									onClick={() => {
										if (!selectedItems.includes(c?.id)) {
											setSelectedItems([...selectedItems, c?.id])
										} else {
											setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== c?.id))
										}
									}}
								>
									<div className={cn(styles.name, styles.newFrameworkName)}>{c?.name}</div>
									<div className={cn(styles.description, styles.newFrameworkDescrption)}>
										<div
											dangerouslySetInnerHTML={{
												__html: c?.description,
											}}
										/>
									</div>
									<div className={cn(styles.tableAction, styles.newFrameworkAction)}>
										{selectedItems?.includes(c?.id) ? svgSelect : svgDeselect}
									</div>
								</div>
							))
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
