import React, { useEffect, useState } from "react"
import cn from "classnames"

// styles
import styles from "./index.module.scss"
import { Link, useLocation } from "react-router-dom"
import { SvgSprite } from "../SvgSprite/SvgSprite"
import { useSelector } from "react-redux"

export const Sidebar = ({ expanded, role }) => {
	const accountPlan = useSelector(state => state.organization.org.account_plan)
	const location = useLocation()
	const path = location.pathname
	const [active, setActive] = useState(null)
	const [items, setItems] = useState([])

	const auditorItems = [
		{
			id: 1,
			name: "Controls",
			iconName: "customize",
			type: "solid",
			to: "/audit",
			route: "/controls",
		},
		{
			id: 2,
			name: "Audit",
			iconName: "envelope",
			type: "solid",
			to: "/controls/all-controls",
			route: "/controls/all-controls",
		},
	]

	const menuItems = [
		{
			id: 1,
			name: "Home",
			iconName: "customize",
			type: "solid",
			to: "/home",
			route: "/home",
		},
		{
			id: 2,
			name: "Controls/Evidence",
			iconName: "envelope",
			type: "solid",
			to: "/controls/all-controls",
			route: "/controls/all-controls",
		},
		{
			id: 3,
			name: "Incident Report",
			iconName: "warning",
			type: "solid",
			to: "/report/all",
			route: "/report",
		},
		{
			id: 4,
			name: "Emergency Plan",
			iconName: "male",
			type: "solid",
			to: "/plan",
			route: "/plan",
		},
		{
			id: 5,
			name: "Resources",
			iconName: "messenger",
			type: "logo",
			to: "/resources",
			route: "/resource",
		},
		{
			id: 6,
			name: "Calendar",
			iconName: "calendar",
			type: "solid",
			to: "/calendar",
			route: "/calendar",
		},
		{
			id: 7,
			name: "FAQ",
			iconName: "help-circle",
			type: "solid",
			to: "/about",
			route: "/about",
		},
		{
			id: 8,
			name: "SecureLabs.ai",
			iconName: "chatgpt",
			type: "solid",
			to: "/help",
			route: "/help",
		},
	]

	useEffect(() => {
		const roleItems = role === "auditor" ? auditorItems : menuItems
		setItems(roleItems)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [role])

	useEffect(() => {
		const currentItem = menuItems.find(item => path?.startsWith(item.route))
		setActive(currentItem ? currentItem.id : null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div
			className={cn(styles.sidebar, {
				[styles.expanded]: expanded,
				[styles.sidebar7]: accountPlan?.toLowerCase() !== "individual",
				[styles.sidebar6]: accountPlan?.toLowerCase() === "individual",
			})}
		>
			{/* <div className={styles.sideHeader}>{expanded && ''}</div> */}
			{items.map((item, index) => {
				return (
					<Link
						to={item.to}
						onClick={() => setActive(item.id)}
						className={cn(styles.boxicon, styles.boxiconContainer, {
							[styles.expandedBoxiconContainer]: expanded,
							[styles.active]: active === item.id,
							// [styles.hideLink] : (
							//   (item.name === 'Calendar' && accountPlan && accountPlan?.toLowerCase() === 'individual') ||
							//   (item.name === 'SecureLabs.ai' && accountPlan && (accountPlan?.toLowerCase() === 'individual' || accountPlan?.toLowerCase() === 'basic') )
							// )
						})}
						key={index}
					>
						<div className={styles.boxIconText}>
							<SvgSprite
								className={cn(styles.svgIcons, {
									[styles.activeSvg]: active === item.id,
									[styles.hideSvg]: !expanded,
								})}
								spriteID={item.iconName}
							/>
							<div
								className={cn(styles.description, {
									[styles.showDescription]: expanded,
									[styles.hideDescription]: !expanded,
									[styles.activeDescription]: active === item.id,
								})}
							>
								{item.name}
							</div>
						</div>
					</Link>
				)
			})}
		</div>
	)
}
