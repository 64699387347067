import axios from "axios"
import { getCookie, setCookie, deleteCookie } from "../utils/cookie"
import { config } from "../utils/Constants"

export const axiosInstance = axios.create({
	baseURL: `${config.url.API_URL}/api/`,
})

axiosInstance.interceptors.request.use(
	function (config) {
		const token = getCookie("access_token")
		if (token) {
			config.headers.Authorization = `Bearer ${token}`
		}
		return config
	},
	function (error) {
		return Promise.reject(error)
	},
)

axiosInstance.interceptors.response.use(
	function (response) {
		return response
	},
	async function (error) {
		const originalRequest = error?.config

		if (error?.response && error?.response?.status === 401 && error?.response?.data?.access_token) {
			try {
				const token = error?.response?.data?.access_token
				setCookie("access_token", token, error?.response?.data?.expires_in, true)
				originalRequest.headers.Authorization = `Bearer ${token}`
				return await axiosInstance(originalRequest)
			} catch (error) {
				return Promise.reject(error)
			}
		}
		if (
			error.response.data.message === "token-not-found" ||
			error.response.data.message === "token-blacklisted" ||
			error.response.data.message === "invalid-token"
		) {
			deleteCookie("access_token")
			window.location.replace("/auth/login")
		}

		return Promise.reject(error)
	},
)
