import React from "react"
import styles from "./index.module.scss"
import cn from "classnames"
import { DotsLoader } from "../../ui/Loader"
import { ResourceItem } from "./components/ResourceItem"
import { RepositoryPopups } from "./components/RepositoryPopups"
import { toast } from "../../functions/toast"
import { axiosInstance } from "../../config/https"

export const Repository = ({
	repository,
	handelMove,
	allRepositories,
	setMoveRepToRep,
	setShowDeleteRepository,
	setActiveRepository,
	dropFolder,
	setDropFolder,
	repositores,
	resources,
	getExt,
	loader,
	deleteLoad,
	dispatch,
	setResources,
	editResource,
	downloadFile,
	delete_resource,
	setDropResource,
	dropResource,
	cloneResource,
    setRepositoryLoader,
	repositoryLoader,
	setResourcesNew,
	allData,
	setData,
}) => {
	const [showListRepositoriesPopup, setShowListRepositoriesPopup] = React.useState(false)
	const [moveToRepository, setMoveToRepository] = React.useState(null)
	const [showRenameRepository, setShowRenameRepository] = React.useState(null)
	const [newRepositoryName, setNewRepositoryName] = React.useState("")
	const [renameLoader, setRenameLoader] = React.useState(false)

	const repoRef = React.useRef(null)
	const ressourcesRef = React.useRef(null)

	React.useEffect(() => {
		const handleClickOutside = event => {
			if (repoRef.current && !repoRef.current.contains(event.target)) {
				setDropFolder(null)
			}

			if (ressourcesRef.current && !ressourcesRef.current.contains(event.target)) {
				setDropResource(null)
			}
		}

		document.addEventListener("mousedown", handleClickOutside)
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [repoRef, ressourcesRef, setDropFolder, setDropResource])

	const detach = id => {
		const data = {
			repository_id: repository.id,
			resource_id: id,
		}

    	setRepositoryLoader(true)

		axiosInstance
			.patch("repositories/resources", data)
			.then(() => {
				const resource = resources.find(r => r.id === id)
				setResourcesNew([...resources.filter(r => r.id !== id)])
				setData([resource, ...allData])
				dispatch(toast("success", "Resource Moved To Parent Directory"))
			})
			.catch(err => {
				dispatch(toast("error", err?.response?.data?.message))
				console.log(err)
			})
			.finally(() => {
				setShowListRepositoriesPopup(false)
				setMoveToRepository(null)
        		setRepositoryLoader(false)
			})
	}

	const handelMoveTo = () => {
		setDropResource(null)
		if (moveToRepository) {
			handelMove()
		} else {
			detach(showListRepositoriesPopup)
		}
	}

	const renameRepository = () => {
		if (!newRepositoryName.trim()) {
			dispatch(toast("error", "Repository name cannot be empty"))
			return
		}

		setRenameLoader(true)
		axiosInstance
			.put(`repositories/${showRenameRepository.id}`, {
				name: newRepositoryName,
			})
			.then(res => {
				const updatedRepositories = repositores?.map(repo =>
					repo.id === res.data.repository.id ? res.data.repository : repo,
				)
				setResources(updatedRepositories)

				if (repository?.id === res.data.repository.id) {
					setActiveRepository(res.data.repository)
				}

				dispatch(toast("success", res.data.message))
			})
			.catch(err => {
				dispatch(toast("error", err?.response?.data?.message))
			})
			.finally(() => {
				setRenameLoader(false)
				setShowRenameRepository(null)
				setNewRepositoryName("")
			})
	}

	const folderActions = [
		{
			type: "rename",
			label: "Rename",
			icon: "edit_pen",
			onClick: repo => {
				setShowRenameRepository(repo)
				setNewRepositoryName(repo.name)
				setDropFolder(null)
			},
		},
		{
			type: "move",
			label: "Move",
			icon: "move",
			onClick: repo => setMoveRepToRep(repo.id),
		},
		{
			type: "delete",
			label: "Delete",
			icon: "trash",
			onClick: repo => {
				setShowDeleteRepository(repo?.id)
				setDropFolder(null)
			},
		},
	]

	const resourceActions = [
		{
			type: "clone",
			label: "Clone",
			icon: "filePaper",
			onClick: resource => {
				cloneResource(resource)
				setDropResource(null)
			},
			showIf: resource => resource?.html,
		},
		{
			type: "edit",
			label: "Edit",
			icon: "edit_pen",
			onClick: resource => {
				editResource(resource)
				setDropResource(null)
			},
			showIf: resource => resource?.organization_id && resource?.html,
		},
		{
			type: "download",
			label: "Download",
			icon: "download",
			onClick: resource => {
				downloadFile(resource.policyDocument)
				setDropResource(null)
			},
		},
		{
			type: "move",
			label: "Move",
			icon: "move",
			onClick: resource => {
				setShowListRepositoriesPopup(resource.id)
				setDropResource(null)
			},
		},
		{
			type: "delete",
			label: "Delete",
			icon: "trash",
			onClick: resource => delete_resource(resource?.id),
			showIf: resource => resource?.organization_id,
		},
	]

	return (
		<div className={styles.resourcesMain}>
			{!loader ? (
				<div className={styles.gridRow}>
					{repositores?.map((repo, i) => (
						<ResourceItem
							key={i}
							resource={repo}
							isFolder={true}
							dropResource={dropFolder}
							setDropResource={setDropFolder}
							resourceRef={repoRef}
							actions={folderActions.filter(action => action.type !== "delete" || repo?.organization_id)}
							onAction={(type, resource) => {
								const action = folderActions.find(a => a.type === type)
								if (action) {
									action.onClick(resource)
								} else if (type === "select") {
									setActiveRepository(resource)
								}
							}}
						/>
					))}

					{resources?.map((resource, i) => (
						<ResourceItem
							key={i}
							resource={{
								...resource,
								extension: getExt(resource.policyDocument),
								name: resource.policyName,
							}}
							dropResource={dropResource}
							setDropResource={setDropResource}
							resourceRef={ressourcesRef}
							deleteLoad={deleteLoad}
							actions={resourceActions.filter(action => !action.showIf || action.showIf(resource))}
							onAction={(type, res) => {
								const action = resourceActions.find(a => a.type === type)
								if (action) {
									action.onClick(res)
								}
							}}
						/>
					))}
				</div>
			) : (
				<div className={styles.centerLoader}>
					<DotsLoader />
				</div>
			)}

			<div className={styles.centerLoader}>
				{!loader && repositores?.length === 0 && resources?.length === 0 && (
					<div className={styles.noResource}>No resources</div>
				)}
			</div>

			<RepositoryPopups
				showListRepositoriesPopup={showListRepositoriesPopup}
				setShowListRepositoriesPopup={setShowListRepositoriesPopup}
				moveToRepository={moveToRepository}
				setMoveToRepository={setMoveToRepository}
				showRenameRepository={showRenameRepository}
				setShowRenameRepository={setShowRenameRepository}
				newRepositoryName={newRepositoryName}
				setNewRepositoryName={setNewRepositoryName}
				renameLoader={renameLoader}
				allRepositories={allRepositories}
				repository={repository}
				handelMoveTo={handelMoveTo}
				renameRepository={renameRepository}
				repositoryLoader={repositoryLoader}
			/>
		</div>
	)
}
