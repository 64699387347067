import { v4 as uuid } from "uuid"
import { addNotification, removeNotification } from "../../store/slices/notificationsSlice"

export const toast = (type, text) => {
	return async dispatch => {
		const errorID = uuid()
		dispatch(addNotification({ id: errorID, type: type, text: text }))
		setTimeout(() => {
			dispatch(removeNotification({ id: errorID }))
		}, 3000)
	}
}
