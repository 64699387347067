import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	archivedUsers: {},
}

const archivedUsersSlice = createSlice({
	name: "archivedUsers",
	initialState,
	reducers: {
		setArchivedUsers(state, action) {
			state.archivedUsers = action.payload
		},
	},
})

export default archivedUsersSlice.reducer
export const { setArchivedUsers } = archivedUsersSlice.actions
