import React, { useCallback, useEffect, useRef, useState } from "react"
import styles from "./index.module.scss"
import { SvgSprite } from "../SvgSprite"
import cn from "classnames"

export const Chips = ({
	tags: chips = [],
	setTags: setChips,
	listOfTags = [],
	max,
	maxLength,
	placeholder = "Add a Tag...",
	noTags,
}) => {
	const [maxChips, setMaxChips] = useState(0)
	const [inputValue, setInputValue] = useState("")
	const [filtreTags, setFiltreTags] = useState([])
	const inputRef = useRef()

	const KEY = {
		backspace: 8,
		tab: 9,
		enter: 13,
	}
	const INVALID_CHARS = /[^a-zA-Z0-9 ]/g

	const onKeyDown = event => {
		let keyPressed = event.which

		if (keyPressed === KEY.enter || (keyPressed === KEY.tab && event.target.value)) {
			event.preventDefault()
			updateChips(event)
		} else if (keyPressed === KEY.backspace) {
			let updatedChips = [...chips]

			if (!event.target.value && updatedChips.length) {
				deleteChip(updatedChips[updatedChips.length - 1])
			}
		}
	}

	const clearInvalidChars = event => {
		let value = event.target.value

		if (INVALID_CHARS.test(value)) {
			event.target.value = value.replace(INVALID_CHARS, "")
		} else if (value.length > maxLength) {
			event.target.value = value.substr(0, maxLength)
		}
	}

	const updateChips = event => {
		if (!max || chips.length < max) {
			let value = event.target.value

			if (!value) return

			let chip = value.trim().toLowerCase()

			if (chip && chips.indexOf(chip) < 0) {
				setChips(prevChips => [...prevChips, chip])
			}
		}

		event.target.value = ""
	}

	const deleteChip = chip => {
		let index = chips.indexOf(chip)

		if (index >= 0) {
			setChips(prevChips => {
				let updatedChips = [...prevChips]
				updatedChips.splice(index, 1)
				return updatedChips
			})
		}
	}

	const focusInput = event => {
		let children = event.target.children

		if (children.length) children[children.length - 1].focus()
	}

	useEffect(() => {
		setMaxChips(noTags ? 20 : 5)
	}, [noTags])

	const handleInputChange = useCallback(
		e => {
			setInputValue(e.target.value)
			setFiltreTags(
				listOfTags?.filter(
					t => t.name.toLowerCase().includes(inputValue) && !chips.some(existingTag => existingTag.id === t.id),
				),
			)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[inputValue],
	)

	const handleAddChip = tag => {
		chips.length > 0 ? setChips([...chips, tag.name]) : setChips([tag.name])
		setFiltreTags(listOfTags?.filter(t => !chips.some(existingTag => existingTag === t.name)))
		inputRef.current.value = ""
	}

	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				<div className={styles.chipsInput}>
					<div
						className={cn(styles.inner, {
							[styles.removePadding]: chips.length === 0,
							[styles.noTags]: noTags,
						})}
						onClick={focusInput}
					>
						{!noTags && (
							<div className={cn({ [styles.chipContainer]: chips?.length > 0 })}>
								{chips.map((chip, index) => (
									<div
										className={styles.chip}
										key={index}
									>
										<div>{chip}</div>
										<SvgSprite
											spriteID={"close"}
											onClick={() => deleteChip(chip)}
											className={styles.closeChipsIcon}
										/>
									</div>
								))}
							</div>
						)}
						{chips?.length < maxChips && (
							<input
								ref={inputRef}
								disabled={chips?.length === maxChips}
								type="text"
								className={cn(styles.chipsInput, { [styles.noTags]: noTags })}
								placeholder={!max || chips.length < max ? placeholder : ""}
								onKeyDown={onKeyDown}
								onKeyUp={clearInvalidChars}
								onChange={handleInputChange}
							/>
						)}
						{listOfTags.length > 0 && chips?.length < 5 && inputRef.current.value.length > 0 && (
							<div className={styles.relativeDive}>
								{filtreTags?.length > 0 && inputValue.length > 0 && (
									<div className={styles.tagsDropDown}>
										<ul className={styles.listOfTags}>
											{filtreTags.map((tag, i) => (
												<li
													key={i}
													className={styles.tagItem}
													onClick={() => handleAddChip(tag)}
												>
													{tag.name}
												</li>
											))}
										</ul>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
