import { axiosInstance } from "../config/https"

export const api = {
	fetchData: async path => {
		return await axiosInstance.get(path).then(res => res.data)
	},

	postData: async (path, data) => {
		return await axiosInstance.post(path, data).then(res => res.data)
	},

	patchData: async (path, data) => {
		return await axiosInstance.patch(path, data).then(res => res.data)
	},
}
