import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import styles from "./index.module.scss"
import cn from "classnames"

function CustomDropdown({ buttonContent, dropdownContent, dropdownStyle, dropdownClass, isOpen, setIsOpen }) {
	const dropdownRef = useRef(null)

	useEffect(() => {
		function handleClickOutside(event) {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false)
			}
		}

		document.addEventListener("mousedown", handleClickOutside)
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [dropdownRef, setIsOpen])

	const toggleDropdown = () => {
		setIsOpen(!isOpen)
	}

	return (
		<div
			ref={dropdownRef}
			className={cn(styles.dropdownContainer, dropdownClass)}
			style={dropdownStyle}
		>
			<button
				onClick={toggleDropdown}
				className={styles.dropdownButton}
			>
				{buttonContent}
			</button>
			{isOpen && <div className={styles.dropdownContent}>{dropdownContent}</div>}
		</div>
	)
}

CustomDropdown.propTypes = {
	buttonContent: PropTypes.node.isRequired,
	dropdownContent: PropTypes.node.isRequired,
	dropdownStyle: PropTypes.object,
}

export default CustomDropdown
