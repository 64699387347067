import { setResources } from "../../store/slices/resourcesSlice"
import { axiosInstance } from "../config/https"

export const getResources = () => {
	return async dispatch => {
		const res = await axiosInstance("resources")

		if (res.status === 200) dispatch(setResources(res.data.data))
	}
}
