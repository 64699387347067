import React, { useState } from "react"
import { Btn } from "../../ui/Btn"
import { SvgSprite } from "../../ui/SvgSprite"
import { Img } from "../../ui/Img/Img"
import { axiosInstance } from "../../config/https"
import { toast } from "../../functions/toast"
import styles from "./index.module.scss"
import { Loader } from "../../ui/Loader"

const DocumentFormAttachments = ({
	resources,
	attachments,
	users,
	dispatch,
	submission_id,
	setAttachments,
	auditor,
	frameworkId,
}) => {
	const [downloadLoader, setDownloadLoader] = useState(false)

	const handelDownload = async () => {
		setDownloadLoader(true)
		await axiosInstance(`download/submission/${submission_id}/files`, { responseType: "blob" })
			.then(async response => {
				const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement("a")
				link.href = url
				link.setAttribute("download", "attachments.zip") //or any other extension
				document.body.appendChild(link)
				link.click()
			})
			.catch(err => {
				if (err?.response?.status === 404) {
					dispatch(toast("error", "No Attachments"))
				} else dispatch(toast("error", err?.response?.data?.message))
			})
			.finally(() => setDownloadLoader(false))
	}

	return (
		<div className={styles.controlCardattachement}>
			<div className={styles.attachement}>
				<div className={styles.attachementTitle}>Attachment</div>
				<div className={styles.attachementBtns}>
					{attachments?.length > 0 && (
						<Btn
							disable={downloadLoader}
							className={styles.downloadBtn}
							onClick={() => handelDownload()}
						>
							<SvgSprite
								spriteID={"download"}
								className={styles.downloadIcon}
							/>
							{downloadLoader ? <Loader /> : <div>Download All</div>}
						</Btn>
					)}
				</div>
			</div>
			<div className={styles.gridImgs}>
				{attachments?.map((m, i) => (
					<Img
						noAction={auditor}
						key={i}
						setAttachments={setAttachments}
						attachments={attachments}
						controlLogId={m.id}
						date={m.updated_at}
						path={m.attachment}
						className={styles.img}
						name={
							users?.find(u => u.id === m.user_id)?.firstName + " " + users?.find(u => u.id === m.user_id)?.lastName
						}
						submission_id={submission_id}
						frameworkId={frameworkId}
						canEvaluate={true}
					/>
				))}

				{resources?.map((m, i) => (
					<Img
						key={i}
						controlLogId={m.id}
						date={m.updated_at}
						path={m.policyDocument}
						className={styles.img}
						name={
							users?.find(u => u.id === m.user_id)?.firstName + " " + users?.find(u => u.id === m.user_id)?.lastName
						}
						filename={m.policyName}
						noAction={false}
						submission_id={submission_id}
						frameworkId={frameworkId}
						canEvaluate={true}
					/>
				))}
			</div>
		</div>
	)
}

export default DocumentFormAttachments
