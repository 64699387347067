import { setOrg } from "../../store/slices/orgSlice"
import { axiosInstance } from "../config/https"

export const setOrgsData = () => {
	return async dispatch => {
		const res = await axiosInstance("organization")

		if (res.status === 200) {
			dispatch(setOrg(res.data.organization))
		}
	}
}
