import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	frameworks: null,
	isLoading: false,
	nbrOfFrameworks: 0,
}

const frameworkSlice = createSlice({
	name: "frameworks",
	initialState,
	reducers: {
		setFrameworkIsLoading(state, action) {
			state.isLoading = action.payload
		},

		setFramework(state, action) {
			state.frameworks = action.payload
		},

		setNbrOfFrameworks(state, action) {
			state.nbrOfFrameworks = action.payload
		},
	},
})

export default frameworkSlice.reducer
export const { setFrameworkIsLoading, setFramework, setNbrOfFrameworks } = frameworkSlice.actions
