import React, { useEffect, useRef, useState } from "react"
import styles from "./index.module.scss"
import cn from "classnames"
import { SvgSprite } from "../SvgSprite/SvgSprite"
import { DotsLoader, Loader } from "../Loader"
import { ProfileImage } from "../ProfileImage/ProfileImage"
import { useNavigate } from "react-router-dom"
import { Popup } from "../Popup"
import { SettingModel } from "../../modules/SettingsPopup"
import { axiosInstance } from "../../config/https"

const EventsLog = ({ data, user }) => {
	const [newDate, setNewDate] = useState(0)
	const [type, setType] = useState("")
	const navigate = useNavigate()

	const logs = {
		OWNER_ASSIGNED: `Assigned ${data?.meta_data?.first_name} ${data?.meta_data?.last_name} as owner of this control`,
		OWNER_REMOVED: `Removed ${data?.meta_data?.first_name} ${data?.meta_data?.last_name} as owner of this control`,
		STAKEHOLDER_ADDED: `Added ${data?.meta_data?.first_name} ${data?.meta_data?.last_name} as a stakeholder of this control`,
		STAKEHOLDER_REMOVED: `Removed ${data?.meta_data?.first_name} ${data?.meta_data?.last_name} as a stakeholder of this control`,
		COMMENT_ADDED: "Added a comment to control",
		COMMENT_UPDATED: "",
		COMMENT_REMOVED: "",
		ATTACHMENT_ADDED: `Added ${data?.meta_data?.type} attachment`,
		ATTACHMENT_UPDATED: `Updated  ${data?.meta_data?.type} attachment`,
		ATTACHMENT_REMOVED: `Removed ${data?.meta_data?.type} from control`,
		CONTROL_EVENT_ADDED: "Added event to control",
		CONTROL_EVENT_UPDATED: "Updated event from control",
		CONTROL_EVENT_REMOVED: "Removed event from control",
		RISK_ADDED: "Added a risk level to this control",
		RISK_UPDATED: "Updated the risk level of this control",
		RISK_REMOVED: "Removed the risk level of this control",
	}

	const handleClick = () => {
		navigate(`/framework/control/${data?.control_id}/submission`)
	}

	const diffDate = (dt2, dt1, value = 60 * 60 * 24) => {
		var diff = (dt2.getTime() - dt1.getTime()) / 1000
		diff /= value
		return Math.abs(Math.round(diff))
	}

	useEffect(() => {
		let dt1 = new Date()
		let dt2 = new Date(data.updated_at)
		if (diffDate(dt1, dt2, 1) < 60) {
			setNewDate(diffDate(dt1, dt2, 1))
			setType("Seconds Ago")
		} else if (diffDate(dt1, dt2, 60) < 60) {
			setNewDate(diffDate(dt1, dt2, 60))
			setType("Minutes Ago")
		} else if (diffDate(dt1, dt2, 60 * 60) < 24) {
			setNewDate(diffDate(dt1, dt2, 60 * 60))
			setType("Hours Ago")
		} else {
			setNewDate(dt2.toLocaleString("default", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" }))
		}
	}, [data])
	return (
		<div className={styles.userInfo}>
			<ProfileImage
				name={user?.firstName + " " + user?.lastName}
				className={styles.userImgSvg}
			/>
			<div className={styles.eventsDetails}>
				<div className={styles.eventsTitle}>
					{" "}
					{newDate} {type} {logs[data.activities]}{" "}
				</div>
				<div
					onClick={handleClick}
					className={styles.eventsText}
				>
					{" "}
					{data?.control_name}{" "}
				</div>
			</div>
		</div>
	)
}
export const List = ({ data, header, eventsLogs, icon, users, activeLoad }) => {
	const [addSettingPopup, setAddSettingPopup] = React.useState(false)
	const [activeUser, setActiveUser] = useState(null)
	const [loading, setLoading] = useState(false)
	const [activities, setActivities] = useState([])
	const lastItemRef = useRef(null)
	const containerRef = useRef(null)
	const [loader, setLoader] = useState(false)
	const [offset, setOffset] = useState(0)

	const handleScroll = event => {
		const container = event.target
		const scrollHeight = container.scrollHeight
		const scrollTop = container.scrollTop
		const clientHeight = container.clientHeight
		if (scrollHeight - scrollTop === clientHeight) {
			setLoading(true)
		}
	}

	const getActivities = () => {
		(async () => {
			if (offset === 0 || offset !== activities?.length) {
				setOffset(activities?.length)
				setLoading(true)
				await axiosInstance(`controls/activities/org?offset=${activities?.length || 0}&limit=${8}`)
					.then(res => {
						if (activities.length > 0) setActivities([...activities, ...res.data.activities])
						else setActivities([...res.data.activities])
					})
					.catch(err => {
						console.log(err)
					})
					.finally(() => {
						setLoading(false)
						setLoader(false)
					})
			}
		})()
	}

	useEffect(() => {
		(async () => {
			if (loading && eventsLogs) {
				await getActivities()
			}
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading])

	useEffect(() => {
		getActivities()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={styles.list}>
			<div className={cn(styles.headerList, styles.item)}>
				<div className={styles.headerText}>{header}</div>
			</div>
			<div
				className={styles.containerListUser}
				ref={containerRef}
				onScroll={handleScroll}
			>
				{!eventsLogs ? (
					<>
						{data?.length > 0 ? (
							data?.map(data => (
								<div
									className={cn(styles.userList, styles.item)}
									key={data.id}
								>
									<div
										className={styles.userInfo}
										onClick={() => {
											setAddSettingPopup(true)
											setActiveUser(data)
										}}
									>
										{icon ? (
											<ProfileImage name={data.firstName + " " + data.lastName} />
										) : (
											<SvgSprite
												spriteID={"user"}
												className={styles.userImgSvg}
											/>
										)}
										<div className={styles.userInfoName}>
											{" "}
											{data.firstName} {data.lastName}{" "}
										</div>
										<div className={styles.userInfoRole}>
											{" "}
											{data?.role?.charAt(0)?.toUpperCase() + data?.role?.substring(1)}{" "}
										</div>
									</div>
									{/* <SvgSprite spriteID={'more'} className={styles.userSvg}/> */}
								</div>
							))
						) : (
							<div className={styles.loaderStyles}> {!data || activeLoad ? <DotsLoader /> : <>No Data</>} </div>
						)}
					</>
				) : (
					<>
						{!loader ? (
							<>
								{activities?.map((item, index) => (
									<div
										key={index}
										className={cn(styles.userList, styles.item)}
									>
										<EventsLog
											data={item}
											user={users?.find(u => u.id === item.user_id)}
										/>
									</div>
								))}
								{loading && (
									<div className={cn(styles.userList, styles.item, styles.loaderEventsLogs)}>
										{" "}
										<Loader />{" "}
									</div>
								)}
								{!loading && activities.length === 0 && (
									<div className={cn(styles.loaderCenter, styles.loaderStyles)}>No Data</div>
								)}
								<div ref={lastItemRef}></div>
							</>
						) : (
							<div className={cn(styles.loaderCenter, styles.loaderStyles)}>
								<DotsLoader />
							</div>
						)}
					</>
				)}
			</div>
			{addSettingPopup && (
				<Popup
					extraBig
					grayBackground
					isOpenedPopup={addSettingPopup}
					closePopup={() => setAddSettingPopup(false)}
				>
					<SettingModel
						closePopup={() => setAddSettingPopup(false)}
						activeUser={activeUser}
						activities={true}
					/>
				</Popup>
			)}
		</div>
	)
}
