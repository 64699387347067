import React, { useEffect, useState } from "react"
import styles from "./index.module.scss"
import cn from "classnames"
import { Btn } from "../../ui/Btn"
import { Search } from "../../ui/Search/Search"
import { useDispatch, useSelector } from "react-redux"
import { axiosInstance } from "../../config/https"
import { toast } from "../../functions/toast"
import { SvgSprite } from "../../ui/SvgSprite"
import { DotsLoader, Loader } from "../../ui/Loader"
import { useInput } from "../../hooks/useInput"
import { useNavigate } from "react-router-dom"
import { setAllFrameworkFunction } from "../../functions/getAllFrameworks"
import { getFrameworks } from "../../functions/getFrameworks"
import { AddControl } from "../Documents/AddControl"
import { Popup } from "../../ui/Popup"
import copy from "../../../assets/images/controls/copy.png"

export const NewFramework = () => {
	const [controls, setControls] = useState(null)
	const [nbrControls, setNbrControls] = useState(0)
	const [sortItem, setSortItem] = useState("default")
	const [searchData, setSearchData] = useState([])
	const [search, setSearch] = React.useState("")
	const [selectedItems, setSelectedItems] = useState([])
	const title = useInput("")
	const [loader, setLoader] = useState(false)
	const [data, setData] = useState()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const framework = useSelector(state => state.framework.frameworks)
	const [frameworkold, setframeworkold] = useState(framework)
	const [isShowAddControl, setIsShowAddControl] = useState(false)
	const [copyControlData, setCopyControlData] = useState(null)

	const compareBy = key => {
		return function (a, b) {
			if (a[key] < b[key]) return -1
			if (a[key] > b[key]) return 1
			return 0
		}
	}
	useEffect(() => {
		if (title?.value !== "" && frameworkold !== framework) {
			setframeworkold(framework)
			setLoader(false)
			navigate(`/controls/${framework[framework?.length - 1]?.id}`)
			dispatch(toast("success", "Framework Created Successfully"))
		}
	}, [framework, navigate, title, loader, frameworkold, dispatch])
	const sortBy = (key, direc) => {
		let arrayCopy = [...controls]
		if (direc === -1) {
			arrayCopy.sort(compareBy(key)).reverse()
		} else {
			arrayCopy.sort(compareBy(key))
		}
		setData([...arrayCopy])
	}

	const svgDeselect = (
		<SvgSprite
			className={styles.svgIconsEmpty}
			spriteID={"list"}
		></SvgSprite>
	)
	const svgSelect = (
		<SvgSprite
			className={styles.CustomSvgIcons}
			spriteID={"check"}
		></SvgSprite>
	)

	const getAllControle = async () => {
		axiosInstance("allControls")
			.then(res => {
				setControls(
					res.data.controls.sort((a, b) => {
						if (a.organization_id === null && b.organization_id !== null) return 1
						if (a.organization_id !== null && b.organization_id === null) return -1
						return b.id - a.id
					}),
				)
				setNbrControls(res.data.nbrControls)
			})
			.catch(err => {
				dispatch(toast("error", err?.response?.data?.message))
			})
	}
	useEffect(() => {
		Promise.all([getAllControle()])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setSearchData(controls?.filter(item => item?.name?.toLowerCase()?.includes(search?.toLowerCase())))
	}, [controls, search])

	const handleClick = () => {
		setLoader(true)
		const data = {
			name: title.value,
			ids: selectedItems,
		}
		axiosInstance
			.post("add/custom/framework", data)
			.then(res => {
				dispatch(getFrameworks())
			})
			.catch(err => {
				dispatch(toast("error", err?.response?.data?.message))
				setLoader(false)
			})
			.finally(() => {
				dispatch(setAllFrameworkFunction())
				// dispatch(getControls())
			})
	}

	const handleCloseAddPopup = () => {
		setCopyControlData(null)
		setIsShowAddControl(false)
	}

	return (
		<div>
			<div className={styles.main}>
				<div className={styles.header}>
					<div className={cn(styles.rieghtHeader, styles.rieghtHeaderHiegth)}>
						<div className={styles.titleCustomFramework}>Title Custom Framework</div>
						<input
							className={styles.titleCustomFrameworkInput}
							placeholder={"Framework Title"}
							onChange={title.onChange}
						/>
					</div>
					<div className={styles.leftHeader}>
						<Btn
							className={styles.addControlBtn}
							onClick={() => setIsShowAddControl(true)}
						>
							Create Control
						</Btn>
						<Btn
							disable={loader}
							className={styles.addBtn}
							onClick={handleClick}
						>
							{loader ? <Loader /> : "Save Framework"}
						</Btn>
					</div>
				</div>
				<div className={styles.subHeader}>
					<div className={styles.controls}>
						{selectedItems?.length} / {nbrControls} Controls
					</div>
					<div className={styles.gridSearch}>
						<div className={styles.search}>
							<Search
								setSearch={setSearch}
								className={styles.search}
								placeholder={"Search Controls"}
							/>
						</div>
					</div>
					<div className={styles.sort}>
						Sort By:
						<div
							className={cn(styles.sortItem, { [styles.sortItemActive]: sortItem === "default" })}
							onClick={() => {
								setSortItem("default")
								sortBy("default")
							}}
						>
							Default
						</div>
						<div
							className={cn(styles.sortItem, { [styles.sortItemActive]: sortItem === "A-Z" })}
							onClick={() => {
								setSortItem("A-Z")
								sortBy("name")
							}}
						>
							A-Z
						</div>
						<div
							className={cn(styles.sortItem, { [styles.sortItemActive]: sortItem === "Z-A" })}
							onClick={() => {
								setSortItem("Z-A")
								sortBy("name", -1)
							}}
						>
							Z-A
						</div>
					</div>
				</div>
				<div className={styles.body}>
					<div className={styles.table}>
						<div className={styles.tableHeader}>
							<div className={cn(styles.name, styles.nameHeader)}>Name</div>
							<div className={styles.description}>Description</div>
							<div className={styles.tableAction}>Select</div>
						</div>
						<div className={styles.TableBody}>
							{!controls ? (
								<div className={styles.bodyDataControls}>
									<DotsLoader />
								</div>
							) : (
								(search ? searchData : data || controls).map((c, i) => (
									<div
										key={i}
										className={cn(styles.row, styles.newFrameworkRow)}
										onClick={() => {
											if (!selectedItems.includes(c?.id)) {
												setSelectedItems([...selectedItems, c?.id])
											} else {
												setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== c?.id))
											}
										}}
									>
										<img
											src={copy}
											alt="copy"
											className={styles.copyBtn}
											onClick={e => {
												e.stopPropagation()
												setCopyControlData(c)
												setIsShowAddControl(true)
											}}
										/>
										<div className={cn(styles.name, styles.newFrameworkName)}>{c?.name}</div>
										<div className={cn(styles.description, styles.newFrameworkDescrption)}>
											<div
												dangerouslySetInnerHTML={{
													__html: c?.description,
												}}
											/>
										</div>
										<div className={cn(styles.tableAction, styles.newFrameworkAction)}>
											{selectedItems.includes(c?.id) ? svgSelect : svgDeselect}
										</div>
									</div>
								))
							)}
						</div>
					</div>
				</div>
			</div>
			{isShowAddControl && (
				<Popup
					bigger
					isOpenedPopup={isShowAddControl}
					closePopup={handleCloseAddPopup}
				>
					<AddControl
						closePopup={handleCloseAddPopup}
						copyControlData={copyControlData}
						getAllControle={getAllControle}
					/>
				</Popup>
			)}
		</div>
	)
}
