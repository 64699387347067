// Controls.js
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import ControlsHeader from "./ControlsHeader"
import ControlsBody from "./ControlsBody"
import styles from "./Controls.module.scss"
import {
	fetchControls,
	fetchControlsByFramework,
	fetchFrameworks,
	fetchNonApplicableControls,
	selectTab,
} from "../../../store/slices/documentsSlice"
import { useParams } from "react-router-dom"
import { DotsLoader } from "../../ui/Loader"
import { Popup } from "../../ui/Popup"
import { AddFramework } from "../Documents/AddFramework"
import { filterControlsFn } from "../../functions/filterControlsFn"

const Controls = () => {
	const dispatch = useDispatch()
	const controls = useSelector(state => state.documents.controls)
	const frameworks = useSelector(state => state.documents.frameworks)
	const controlsByFramework = useSelector(state => state.documents.controlsByFramework)
	const nonApplicableControls = useSelector(state => state.documents.nonApplicableControls)
	const status = useSelector(state => state.documents.status)
	const [data, setData] = useState([])
	const { id } = useParams()
	const users = useSelector(state => state.organization.org.users)
	const [addFramework, setAddFramework] = useState(false)
	const [filterList, setFilterList] = useState({
		control_function: [],
		submission_stakeholders: [],
		submission_owner: [],
		article_numbers: [],
	})
	const [sortControls, setSortControls] = useState([])
	const [search, setSearch] = useState("")
	const [searchData, setSearchData] = useState([])
	const [selectedFilter, setSelectedFilter] = useState([])

	const risk = [
		{ name: "high", color: "#E02020", value: 5 },
		{ name: "significant", color: "#F7B500", value: 4 },
		{ name: "moderate", color: "#FAFA00", value: 3 },
		{ name: "minor", color: "#6DD400", value: 2 },
		{ name: "negligible", color: "#2EE6CA", value: 1 },
	]

	useEffect(() => {
		dispatch(fetchControlsByFramework(id))
		dispatch(fetchFrameworks())
	}, [dispatch, id])

	useEffect(() => {
		if (!frameworks?.length) {
			dispatch(fetchFrameworks())
		}
		const storedFilter = JSON.parse(localStorage.getItem("controls-filter"))
		if (storedFilter) {
			setFilterList(storedFilter)
			const activeFilters = Object.keys(storedFilter).filter(key => storedFilter[key].length > 0)
			setSelectedFilter(activeFilters)
		}
		if (status === "idle") {
			switch (id) {
			case "all-controls":
				dispatch(fetchControls())
				break
			case "non-applicable":
				dispatch(fetchNonApplicableControls())
				break
			default:
				dispatch(fetchControlsByFramework(id))
				break
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, dispatch])

	useEffect(() => {
		switch (id) {
		case "all-controls":
			setData(controls)
			setSortControls(filterControlsFn(controls, null))
			break
		case "non-applicable":
			setData(nonApplicableControls)
			setSortControls(filterControlsFn(nonApplicableControls, null))
			break
		default:
			setData(controlsByFramework[id]?.controls)
			setSortControls(filterControlsFn(controlsByFramework[id]?.controls, null))
			break
		}
	}, [controls, controlsByFramework, id, nonApplicableControls])

	const handleTabClick = tab => {
		dispatch(selectTab(tab))
		if (tab !== "all-controls" && tab !== "non-applicable" && !controlsByFramework[tab]?.controls.length) {
			dispatch(fetchControlsByFramework(tab))
		}
		if (tab === "non-applicable" && !nonApplicableControls?.length) {
			dispatch(fetchNonApplicableControls())
		}
		if (tab === "all-controls" && !controls?.length) {
			dispatch(fetchControls())
		}
	}

	return (
		<div className={styles.controlsContainer}>
			<ControlsHeader
				frameworks={frameworks}
				onSelectFramework={handleTabClick}
				activeTab={id}
				setAddFramework={setAddFramework}
				users={users}
				filterList={filterList}
				setFilterList={setFilterList}
				setSortControls={setSortControls}
				controls={data}
				sortControls={sortControls}
				search={search}
				setSearch={setSearch}
				searchData={searchData}
				setSearchData={setSearchData}
				selectedFilter={selectedFilter || []}
				setSelectedFilter={setSelectedFilter}
			/>
			{status === "loading" && (
				<div className={styles.DotsLoader}>
					<DotsLoader />{" "}
				</div>
			)}
			{status === "failed" && <div>Error fetching data</div>}
			{status === "succeeded" && (
				<ControlsBody
					activeTab={id}
					controls={data}
					risk={risk}
					users={users}
					setSortControls={setSortControls}
					sortControls={sortControls}
					searchControls={searchData}
					search={search}
				/>
			)}
			<Popup
				isOpenedPopup={addFramework}
				closePopup={() => setAddFramework(false)}
				big
			>
				<AddFramework closePopup={() => setAddFramework(false)} />
			</Popup>
		</div>
	)
}

export default Controls
