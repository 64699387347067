import React from "react"
import styles from "./index.module.scss"
import cn from "classnames"

export const Loader = ({ className, small }) => {
	return (
		<div
			className={cn(styles.loader, {
				[className]: className,
				[styles.small]: small,
			})}
		></div>
	)
}
