import React, { useCallback } from "react"
import styles from "./index.module.scss"
import { SvgSprite } from "../SvgSprite"
import cn from "classnames"
import PropTypes from "prop-types"
import { debounce } from "lodash"

export const Search = ({ setSearch, className, placeholder, onSubmit }) => {
	// Create a debounced version of the search function
	const debouncedSearch = useCallback(
		debounce((value) => {
			if (value.length >= 2) {
				onSubmit(value)
			} else if (value.length === 0) {
				onSubmit("")
			}
		}, 300),
		[onSubmit]
	)

	const handleSubmit = (e) => {
		e.preventDefault()
		const value = e.target.search.value
		if (value.length >= 2) {
			onSubmit(value)
		}
	}

	const handleChange = (e) => {
		const value = e.target.value
		setSearch(value)
		debouncedSearch(value)
	}

	return (
		<form 
			className={cn(styles.search, className)} 
			onSubmit={handleSubmit}
		>
			<input
				name="search"
				type="text"
				placeholder={placeholder}
				onChange={handleChange}
			/>
			<button type="submit">
				<SvgSprite spriteID={"search"} />
			</button>
		</form>
	)
}

Search.propTypes = {
	setSearch: PropTypes.func.isRequired,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	onSubmit: PropTypes.func,
}
