import React from "react"
import { Popup } from "../../../ui/Popup"
import { Btn } from "../../../ui/Btn"
import { Loader } from "../../../ui/Loader"
import styles from "../index.module.scss"

export const RepositoryPopups = ({
	showListRepositoriesPopup,
	setShowListRepositoriesPopup,
	moveToRepository,
	setMoveToRepository,
	showRenameRepository,
	setShowRenameRepository,
	newRepositoryName,
	setNewRepositoryName,
	renameLoader,
	allRepositories,
	repository,
	handelMoveTo,
	renameRepository,
	repositoryLoader,
}) => {
	return (
		<>
			<Popup
				small={true}
				isOpenedPopup={showListRepositoriesPopup}
				closePopup={() => {
					setShowListRepositoriesPopup(false)
					setMoveToRepository(null)
				}}
			>
				<div className={styles.repositoriesContainer}>
					<div className={styles.listOfRepositories}>
						<div
							className={styles.radioRepository}
							onClick={() => setMoveToRepository(0)}
						>
							<input
								type="radio"
								name="repo"
								checked={0 === moveToRepository}
								className={styles.repositoryInputRadio}
								onChange={() => setMoveToRepository(0)}
							/>
							{"/"}
						</div>
						{allRepositories?.map(
							(r, i) =>
								r.id !== repository?.id && (
									<div
										key={i}
										className={styles.radioRepository}
										onClick={() => setMoveToRepository(r.id)}
									>
										<input
											type="radio"
											name="repo"
											checked={r.id === moveToRepository}
											className={styles.repositoryInputRadio}
											onChange={() => setMoveToRepository(r.id)}
										/>
										{r.name}
									</div>
								),
						)}
						{allRepositories.length === 0 && <div className={styles.NoRepoMessage}>No Repositories</div>}
					</div>
					<Btn
						className={styles.repositoryBtn}
						onClick={handelMoveTo}
						disable={repositoryLoader}
					>
						{repositoryLoader ? <Loader /> : "Move"}
					</Btn>
				</div>
			</Popup>

			<Popup
				small={true}
				isOpenedPopup={showRenameRepository}
				closePopup={() => {
					setShowRenameRepository(null)
					setNewRepositoryName("")
				}}
			>
				<div className={styles.divRepository}>
					<input
						className={styles.repositoryInput}
						placeholder="Repository name"
						value={newRepositoryName}
						onChange={e => setNewRepositoryName(e.target.value)}
						autoFocus
					/>
					<Btn
						disable={renameLoader}
						className={styles.repositoryBtn}
						onClick={renameRepository}
					>
						{renameLoader ? <Loader /> : "Rename Repository"}
					</Btn>
				</div>
			</Popup>
		</>
	)
}
