import React from "react"
import styles from "./index.module.scss"
import { useSelector } from "react-redux"

const UserActivitiesCard = ({ index, activity, handlerNameActivity }) => {
	// user activities time calculation
	const controls = useSelector(state => state.control.controls)
	const submission = useSelector(state => state.organization.org.control_submissions)

	const handleControl = data => {
		const control =
			controls?.length > 0 &&
			controls?.find(c => c.id === submission?.find(s => s.id === data.control_submission_id).control_id)
		return control?.name
	}
	const currentUser = useSelector(state => state.user.userInfo)
	const users = useSelector(state => state.organization.org.users)
	const [owner, setOwner] = React.useState(null)
	const [newDate, setNewDate] = React.useState(0)
	const [type, setType] = React.useState("")
	const logs = {
		OWNER_ASSIGNED: `Assigned ${activity?.meta_data?.first_name} ${activity?.meta_data?.last_name} as owner of this control`,
		OWNER_REMOVED: `Removed ${activity?.meta_data?.first_name} ${activity?.meta_data?.last_name} as owner of this control`,
		STAKEHOLDER_ADDED: `Added ${activity?.meta_data?.first_name} ${activity?.meta_data?.last_name} as a stakeholder of this control`,
		STAKEHOLDER_REMOVED: `Removed ${activity?.meta_data?.first_name} ${activity?.meta_data?.last_name} as a stakeholder of this control`,
		COMMENT_ADDED: "Added a comment to control",
		COMMENT_UPDATED: "",
		COMMENT_REMOVED: "",
		ATTACHMENT_ADDED: `Added ${activity?.meta_data?.type} attachment to this control`,
		ATTACHMENT_UPDATED: `Updated  ${activity?.meta_data?.type} attachment`,
		ATTACHMENT_REMOVED: `Removed ${activity?.meta_data?.type} from control`,
		CONTROL_EVENT_ADDED: "Added event to control",
		CONTROL_EVENT_UPDATED: "Updated event from control",
		CONTROL_EVENT_REMOVED: "Removed event from control",
		RISK_ADDED: "Added a risk level to this control",
		RISK_UPDATED: "Updated the risk level of this control",
		RISK_REMOVED: "Removed the risk level of this control",
	}
	const diffDate = (dt2, dt1, value = 60 * 60 * 24) => {
		var diff = (dt2.getTime() - dt1.getTime()) / 1000
		diff /= value
		return Math.abs(Math.round(diff))
	}

	React.useEffect(() => {
		let dt1 = new Date()
		let dt2 = new Date(activity.updated_at)
		if (diffDate(dt1, dt2, 1) < 60) {
			setNewDate(diffDate(dt1, dt2, 1))
			setType("Seconds Ago")
		} else if (diffDate(dt1, dt2, 60) < 60) {
			setNewDate(diffDate(dt1, dt2, 60))
			setType("Minutes Ago")
		} else if (diffDate(dt1, dt2, 60 * 60) < 24) {
			setNewDate(diffDate(dt1, dt2, 60 * 60))
			setType("Hours Ago")
		} else {
			setNewDate(dt2.toLocaleString("default", { month: "short", day: "2-digit", hour: "2-digit", minute: "2-digit" }))
		}
	}, [activity])

	React.useEffect(() => {
		if (activity.user_id === currentUser?.id) setOwner("You")
		else {
			let u = users?.find(u => u.id === activity.user_id)
			setOwner(u.firstName + " " + u.lastName)
		}
	}, [activity, currentUser?.id, users])

	return (
		<div
			key={index}
			className={styles.eachArchive}
		>
			<p>
				<span style={{ color: "blue" }}> {owner} </span>
				{logs[activity.activities]} <span style={{ color: "blue" }}> {handleControl(activity)} </span>{" "}
			</p>
			<p>
				{" "}
				{newDate} {type}
			</p>
		</div>
	)
}

export default UserActivitiesCard
