import React from "react"
import { SvgSprite } from "../../../ui/SvgSprite"
import { Loader } from "../../../ui/Loader"
import cn from "classnames"
import styles from "../index.module.scss"

export const ResourceItem = ({
	resource,
	onAction,
	dropResource,
	setDropResource,
	resourceRef,
	isFolder = false,
	deleteLoad,
	actions = [],
}) => {
	const handleDropdownToggle = () => {
		setDropResource(dropResource === resource.id ? null : resource.id)
	}

	const renderContent = () => {
		if (isFolder) {
			return (
				<>
					<div
						onClick={() => onAction("select", resource)}
						className={styles.bodyTypeContainer}
					>
						<SvgSprite
							spriteID="folder"
							className={styles.folderStyles}
						/>
					</div>
					<div
						onClick={() => onAction("select", resource)}
						className={styles.centerFrameworkName}
					>
						{resource.name}
					</div>
				</>
			)
		}

		return (
			<div
				className={styles.documentDetailsContainer}
				onClick={() => onAction("view", resource)}
			>
				<div className={styles.bodyTypeContainer}>
					<div className={styles.extention}>{resource.extension}</div>
				</div>
				<div className={styles.centerFrameworkName}>{resource.name}</div>
				{resource.category && (
					<div className={styles.frameworkCategory}>
						{resource.category} {resource.deleted_at}
					</div>
				)}
			</div>
		)
	}

	return (
		<div className={cn(styles.gridItem)}>
			<div className={styles.resourcesHeaderIcon}>
				<SvgSprite
					spriteID="dots"
					className={cn(styles.resourcesIcon, styles.dotsStyles)}
					onClick={handleDropdownToggle}
				/>
				{dropResource === resource.id && (
					<div
						ref={resourceRef}
						className={cn(styles.actionsContent, styles.dropResourcActionsContent)}
					>
						{actions.map((action, index) => (
							<div
								key={index}
								className={cn(styles.dropResourceBtn, {
									[styles.deleteLoad]: action.type === "delete" && deleteLoad,
								})}
								onClick={() => onAction(action.type, resource)}
							>
								<div>{action.label}</div>
								{action.type === "delete" && deleteLoad ? (
									<Loader />
								) : (
									<SvgSprite
										spriteID={action.icon}
										className={styles.resourcesIcon}
									/>
								)}
							</div>
						))}
					</div>
				)}
			</div>
			{renderContent()}
		</div>
	)
}
