import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { Provider } from "react-redux"
import { store } from "./store"
import "./assets/styles/general.scss"

import { BrowserRouter as Router } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 1, //Will retry to fectch the data 3 times before displayinh an error
			staleTime: 5000, // the data will be considered fresh for 5000ms (5s)
		},
	},
})
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<Provider store={store}>
		<QueryClientProvider client={queryClient}>
			<Router>
				<App />
				{/* <ReactQueryDevtools /> */}
			</Router>
		</QueryClientProvider>
	</Provider>,
)
