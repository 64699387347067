import React, { useState } from "react"
import styles from "./index.module.scss"
import { Btn } from "../../ui/Btn"
import FilesDragAndDrop from "./FilesDragAndDrop"
import { DotsLoader, Loader } from "../../ui/Loader"
import { SvgSprite } from "../../ui/SvgSprite"
import { axiosInstance } from "../../config/https"
import cn from "classnames"
import { toast } from "../../functions/toast"
import { Popup } from "../../ui/Popup"
import { Breakdown } from "./Breakdown"
import { getControlSubmissions } from "../../functions/getControlSubmission"

const DocumentFormControlAndEvidence = ({
	controlLoader,
	activeControl,
	activeFramework,
	submission,
	dispatch,
	resources,
	setResources,
	attachments,
	setAttachments,
}) => {
	const [file, setFile] = useState([])
	const [uploadLoader, setUploadLoader] = useState(false)
	const [resetFiles, setResetFiles] = useState(false)
	const [breakdown, setBreakdown] = useState(false)

	const addAttachment = async () => {
		setUploadLoader(true)
		if (file.length > 0) {
			const data = {
				control_id: activeControl?.id,
				control_submission_id: submission?.id,
				attachment: file,
			}
			setFile([])
			await axiosInstance
				.post("attachment/log/control", data, {
					headers: {
						"content-type": "multipart/form-data",
					},
				})
				.then(async res => {
					console.log(res)
					const modifiedAttachments = res.data.attachments.map(attachment => ({
						...attachment,
						attachment: attachment.attachments,
					}))
					if (attachments?.length > 0) {
						setAttachments([...attachments, ...modifiedAttachments])
					} else {
						setAttachments([...modifiedAttachments])
					}
					dispatch(toast("success", "Files Uploaded Successfully"))
					await dispatch(getControlSubmissions())
				})
				.catch(err => {
					console.log(err)
					let msg = ""
					if (err?.response?.status === 413) msg = err?.response?.statusText || ""
					else msg = err?.response?.data?.message || ""

					dispatch(toast("error", msg))
				})
				.finally(() => {
					setUploadLoader(false)
					setResetFiles(true)
				})
		} else {
			dispatch(toast("error", "Upload Files"))
			setUploadLoader(false)
		}
	}

	return (
		<>
			<div className={styles.controlCard}>
				<div>
					<div className={styles.controlCardLabel}>
						<div>Title</div>
						<Btn
							noHover
							className={cn(styles.saveBtn, styles.breakDown)}
							onClick={() => setBreakdown(true)}
						>
							Breakdown
						</Btn>
					</div>
					<div className={styles.controlCardValue}>
						{controlLoader ? (
							<div className={styles.controlLoader}>
								<DotsLoader />
							</div>
						) : (
							activeControl?.name
						)}
					</div>
				</div>
				<div>
					<div className={cn(styles.controlCardLabel, styles.cardSpace)}>Description</div>
					<div className={styles.controlCardValue}>
						{controlLoader ? (
							<div className={styles.controlLoader}>
								<DotsLoader />
							</div>
						) : (
							<div
								dangerouslySetInnerHTML={{
									__html: activeControl?.description,
								}}
							/>
						)}
					</div>
				</div>

				<div>
					<div className={cn(styles.controlCardLabel, styles.cardSpace)}>Evidence</div>
					<div className={styles.evidence}>
						<div className={styles.commentInput}>
							<FilesDragAndDrop
								controlId={activeControl?.id}
								listFiles={file}
								onUpload={setFile}
								resetFiles={resetFiles}
								setResetFiles={setResetFiles}
								submissionId={submission?.id}
								ResourcesIds={resources?.map(r => {
									return r.id
								})}
								resources={resources}
								setResources={setResources}
							/>
						</div>
						<div className={styles.evidenceAction}>
							{uploadLoader ? (
								<Loader />
							) : (
								<SvgSprite
									spriteID={"send"}
									className={cn(styles.evidenceIcon, {
										[styles.blueSend]: file.length > 0,
									})}
									onClick={addAttachment}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
			<Popup
				bigger
				isOpenedPopup={breakdown}
				closePopup={() => setBreakdown(false)}
			>
				<Breakdown
					framework={activeFramework}
					activeControl={activeControl}
					submission={submission}
					closePopup={() => setBreakdown(false)}
				/>
			</Popup>
		</>
	)
}

export default DocumentFormControlAndEvidence
