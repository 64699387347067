import React, { useEffect, useRef } from "react"
import cn from "classnames"

// Styles
import styles from "./index.module.scss"
import { useMobile } from "../../hooks/useMobile"
import { useToggle } from "../../hooks/useToggle"
import { SvgSprite } from "../SvgSprite/SvgSprite"
import { ProfileImage } from "../ProfileImage/ProfileImage"

export const Actions = ({
	actions,
	className,
	setActiveItem,
	activeData,
	noIcon,
	isOpenedActions,
	toggleIsOpenedActions,
	users,
	listMembers,
	report,
}) => {
	const isTablet = useMobile(769)
	const { isOpened, toggleIsOpened } = useToggle(isOpenedActions)
	const action = useRef()

	const handleClick = e => {
		if (isOpened && !action.current.contains(e.target)) {
			toggleIsOpened()
			toggleIsOpenedActions && toggleIsOpenedActions()
		}
	}

	useEffect(() => {
		document.addEventListener("click", handleClick)

		return () => document.removeEventListener("click", handleClick)
	})

	const assignedTo = (e, user) => {
		if (e?.target?.checked && !listMembers?.includes(user)) {
			setActiveItem([...listMembers, user])
		} else {
			setActiveItem([...listMembers?.filter(i => i?.id !== user?.id)])
		}
	}

	return (
		<div
			className={cn(styles.actions, {
				[styles.listeUser]: users,
				[styles.listReport]: report,
			})}
			ref={action}
		>
			{!noIcon && (
				<div
					className={styles.actionsBtn}
					onClick={toggleIsOpened}
				>
					{isTablet ? <SvgSprite spriteID={"arrow"} /> : <SvgSprite spriteID={"more"} />}
				</div>
			)}
			{(isOpened || isOpenedActions) && (
				<div
					className={cn(styles.actionsContent, {
						[styles.actionUser]: className,
						[styles.actionContentReport]: report,
					})}
				>
					{/* {header && <div className={styles.headerAction}>
                            <div className={styles.addUser}>Add Stakeholder</div>
                            <SvgSprite spriteID={'add'}  className={styles.addIcon}/>
                        </div>} */}
					{actions?.map(
						actionItem =>
							actionItem && (
								<div
									key={actionItem.id}
									className={cn(styles.actionsContentBtn, {
										[styles.actionsContentBtnDelete]: actionItem.delete,
									})}
									onClick={() => {
										!users && actionItem.onClick()
										!users && setActiveItem && setActiveItem(activeData)
									}}
								>
									{!users ? (
										<>
											<div>{actionItem.text}</div>
											{actionItem.icon && <SvgSprite spriteID={actionItem.icon} />}
										</>
									) : (
										<>
											<label
												className={cn(styles.addAdssignListUser, styles.ownerStyles)}
												key={actionItem.id}
											>
												<div
													className={styles.addAdssignListUser}
													htmlFor={actionItem.id}
													onClick={() => {
														assignedTo(actionItem.id)
													}}
												>
													<ProfileImage name={actionItem.firstName + " " + actionItem.lastName} />
													<div className={styles.listUserName}>
														{actionItem.firstName + " " + actionItem.lastName}{" "}
														<span className={styles.listUserNameRole}>{actionItem?.role}</span>
													</div>
												</div>
												<div>
													<input
														type="checkbox"
														name={actionItem.id}
														defaultChecked={listMembers?.find(s => s.id === actionItem.id)}
														onChange={e => assignedTo(e, actionItem)}
													/>
												</div>
											</label>
										</>
									)}
								</div>
							),
					)}
				</div>
			)}
		</div>
	)
}
