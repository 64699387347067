import React, { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { config } from "../../utils/Constants"
import { getCookie } from "../../utils/cookie"
import { FileType } from "./FileType"
import { ProfileImage } from "../ProfileImage/ProfileImage"
import styles from "./index.module.scss"
import cn from "classnames"
import { axiosInstance } from "../../config/https"
import { SvgSprite } from "../SvgSprite/SvgSprite"
import { Popup } from "../Popup/Popup"
import { RemoveFile } from "./RemoveFile"
import { useDispatch } from "react-redux"
import { toast } from "../../functions/toast"
import AttachmentDropdown from "../AttachmentDropdown/AttachmentDropdown"
import dots from "../../../assets/images/controls/dots.png"
import ReactMarkdown from "react-markdown"

export const Img = ({
	path,
	className,
	name,
	date,
	controlLogId,
	img,
	noAction,
	filename,
	setAttachments,
	attachments,
	submission_id,
	frameworkId,
	canEvaluate,
}) => {
	const [nameFile, setNameFile] = useState("")
	const [dateFile, setDateFile] = useState("")
	const [removeFile, setRemoveFile] = useState(false)
	const dispatch = useDispatch()
	const [deleteFileLoader, setDeleteFileLoader] = useState(false)
	const [isOpenDropdown, setIsOpenDropdown] = useState(false)
	const [isOpenPopup, setIsOpenPopup] = useState(false)
	const [evaluateResult, setEvaluateResult] = useState(null)

	let contentType = {
		pdf: "application/pdf",
		xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		xls: "application/vnd.ms-excel",
		doc: "application/msword",
		docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		csv: "text/csv",
		jpg: "image/jpeg",
		jpeg: "image/jpeg",
		png: "image/png",
		svg: "image/svg+xml",
		ppt: "application/vnd.ms-powerpoint",
		pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentatio",
		odt: "application/vnd.oasis.opendocument.text",
		ods: "application/vnd.oasis.opendocument.spreadsheet",
		odp: "application/vnd.oasis.opendocument.presentation",
	}

	const [isEditing, setIsEditing] = useState(false)

	function handleTextClick() {
		setIsEditing(true)
	}

	function handleTextChange(event) {
		setNameFile(event.target.value)
	}

	async function handleRemove() {
		setDeleteFileLoader(true)
		await axiosInstance
			.patch(`${config.url.API_URL}/api/public/file`, {
				fileName: null,
				path: path,
				controllogId: controlLogId,
				extension: path.substring(path.lastIndexOf(".") + 1),
			})
			.then(() => {
				if (attachments) {
					setAttachments([...attachments?.filter(a => a.attachment !== path)])
				}
				dispatch(toast("success", "Attachment Name Removed Successfully"))
			})
			.catch(err => {
				dispatch(toast("error", err?.response?.data?.message))
			})
			.finally(() => {
				setRemoveFile(false)
				setDeleteFileLoader(false)
			})
	}

	async function handleEvaluate() {
		dispatch(toast("loading", "Evaluating..."))
		setIsOpenDropdown(false)
		await axiosInstance
			.post(`${config.url.API_URL}/api/evaluate-attachment`, {
				attachment: path,
				control_submission_id: submission_id,
				framework_id: frameworkId,
			})
			.then(res => {
				if (res.data.evaluation) {
					setEvaluateResult(res.data.evaluation)
					setIsOpenPopup(true)
				}
				dispatch(toast("success", "Evaluating Successfully"))
			})
			.catch(err => {
				dispatch(toast("error", err?.response?.data?.message))
			})
	}

	async function handleTextBlur() {
		setIsEditing(false)
		await axiosInstance
			.patch(`${config.url.API_URL}/api/public/file`, {
				fileName: nameFile,
				path: path,
				controllogId: controlLogId,
				extension: path.substring(path.lastIndexOf(".") + 1),
			})
			.then(() => {
				dispatch(toast("success", "Attachment Name Updated Successfully"))
			})
			.catch(err => {
				dispatch(toast("error", err?.response?.data?.message))
			})
	}

	const {
		data: imageBlob,
		isLoading,
		isError,
	} = useQuery({
		queryKey: ["fetchImageData", path],
		queryFn: async () => {
			const res = await fetch(`${config.url.API_URL}/api/${path}`, {
				headers: {
					Authorization: `Bearer ${getCookie("access_token")}`,
				},
			})
			if (!res.ok) {
				throw new Error("Failed to fetch image")
			}
			return await res.blob()
		},
		enabled: !!path, // Only fetch data when `path` exists
		refetchOnWindowFocus: false, // Disable refetching on window focus
	})

	const imprimi = () => {
		try {
			var blob = new Blob([imageBlob], { type: contentType[path.substring(path.lastIndexOf(".") + 1)] })
			var blobURL = URL.createObjectURL(blob)
			window.open(blobURL, "_blank")
		} catch (error) {
			dispatch(toast("error", "An error occurred. Please try again later."))
			console.log(error)
		}
	}

	React.useEffect(() => {
		setNameFile(filename || path?.substring(path.lastIndexOf("/") + 1, path.lastIndexOf("_")))
		setDateFile(new Date(date).toUTCString())
	}, [path, date, filename])

	return (
		!isError && (
			<div className={cn(styles.imageOwner)}>
				{!isLoading ? (
					<>
						{["jpg", "jpeg", "svg", "png", "jfif"].includes(path.substring(path.lastIndexOf(".") + 1)) ? (
							<img
								className={cn(className, styles.img)}
								src={imageBlob ? URL.createObjectURL(imageBlob) : ""}
								onClick={imprimi}
								alt={"Attachment"}
							/>
						) : ["csv", "pptx", "ppt", "txt", "odt", "docx", "doc", "pdf", "xlsx", "xls"].includes(
							path.substring(path.lastIndexOf(".") + 1),
						  ) ? (
								<SvgSprite
									onClick={imprimi}
									spriteID={path.substring(path.lastIndexOf(".") + 1)}
									className={cn(styles.fileTypeCard, styles.transparentStyle)}
								/>
							) : (
								<FileType
									onClick={imprimi}
									type={path.substring(path.lastIndexOf(".") + 1)}
								/>
							)}
						{!img && (
							<>
								<ProfileImage
									name={name}
									className={styles.ownerImageComp}
								/>
								{isEditing ? (
									<div className={cn(styles.imageName, styles.imageFileName)}>
										<input
											type="text"
											value={nameFile}
											onChange={handleTextChange}
											onBlur={handleTextBlur}
											autoFocus
										/>
									</div>
								) : (
									<div className={cn(styles.imageNameContainer, styles.imageFileName)}>
										<div className={cn(styles.imageName, styles.fixedImageFile)}>{filename || nameFile}</div>
										{!noAction && (
											<AttachmentDropdown
												setIsOpen={setIsOpenDropdown}
												isOpen={isOpenDropdown}
												buttonContent={
													<img
														src={dots}
														className={styles.dots}
														alt="menu"
													/>
												}
												dropdownContent={
													<div className={styles.dropdownContainer}>
														{!filename && (
															<div
																className={styles.dropdownItem}
																onClick={handleTextClick}
															>
																<SvgSprite
																	spriteID="update"
																	className={cn(styles.fileIcon, styles.editIcon)}
																/>
																<span>Edit</span>
															</div>
														)}
														{canEvaluate && (
															<div
																className={styles.dropdownItem}
																onClick={() => handleEvaluate()}
															>
																<SvgSprite
																	spriteID="evaluate"
																	className={cn(styles.fileIcon, styles.editIcon)}
																/>
																<span>Evaluate</span>
															</div>
														)}
														{!filename && (
															<div
																className={styles.dropdownItem}
																onClick={() => setRemoveFile(true)}
															>
																<SvgSprite
																	spriteID="remove"
																	className={cn(styles.fileIcon, styles.removeIcon)}
																/>
																<span className={styles.removeText}>Remove</span>
															</div>
														)}
													</div>
												}
											/>
										)}
									</div>
								)}
								<div className={cn(styles.imageName, styles.imageFileDate)}>
									{new Date(dateFile).toDateString()}
									<br /> {new Date(dateFile).toLocaleTimeString()}
								</div>
								<Popup
									small
									isOpenedPopup={removeFile}
									closePopup={() => setRemoveFile(false)}
								>
									<RemoveFile
										closePopup={() => setRemoveFile(false)}
										fileName={nameFile}
										handleRemove={handleRemove}
										deleteFileLoader={deleteFileLoader}
									/>
								</Popup>
							</>
						)}
						<Popup
							bigger
							isOpenedPopup={isOpenPopup}
							closePopup={() => {
								setEvaluateResult(null)
								setIsOpenPopup(false)
							}}
						>
							<div className={styles.controlPopupHeader}>
								<div>{nameFile}</div>
								<div className={styles.BreakdownLeftHeader}>
									<SvgSprite
										spriteID={"close"}
										className={styles.closeIconPopup}
										onClick={() => {
											setEvaluateResult(null)
											setIsOpenPopup(false)
										}}
									/>
								</div>
							</div>
							<div className={styles.markdownContainer}>
								<div className={styles.markdownContent}>
									<ReactMarkdown>{evaluateResult}</ReactMarkdown>
								</div>
							</div>
						</Popup>
					</>
				) : (
					!isError && (
						<div className={cn(className, styles.img)}>
							<div className={styles.loadingImg}></div>
						</div>
					)
				)}
			</div>
		)
	)
}
