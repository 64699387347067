export const Datastates = [
	{
		id: 0,
		name: "Alabama",
		value: "Alabama",
	},
	{
		id: 1,
		name: "Alaska",
		value: "Alaska",
	},
	{
		id: 2,
		name: "Arizona",
		value: "Arizona",
	},
	{
		id: 3,
		name: "Arkansas",
		value: "Arkansas",
	},
	{
		id: 4,
		name: "California",
		value: "California",
	},
	{
		id: 5,
		name: "Colorado",
		value: "Colorado",
	},
	{
		id: 6,
		name: "Connecticut",
		value: "Connecticut",
	},
	{
		id: 7,
		name: "Delaware",
		value: "Delaware",
	},
	{
		id: 8,
		name: "Florida",
		value: "Florida",
	},
	{
		id: 9,
		name: "Georgia",
		value: "Georgia",
	},
	{
		id: 10,
		name: "Hawaii",
		value: "Hawaii",
	},
	{
		id: 11,
		name: "Idaho",
		value: "Idaho",
	},
	{
		id: 12,
		name: "Illinois",
		value: "Illinois",
	},
	{
		id: 13,
		name: "Indiana",
		value: "Indiana",
	},
	{
		id: 14,
		name: "Iowa",
		value: "Iowa",
	},
	{
		id: 15,
		name: "Kansas",
		value: "Kansas",
	},
	{
		id: 16,
		name: "Kentucky",
		value: "Kentucky",
	},
	{
		id: 17,
		name: "Louisiana",
		value: "Louisiana",
	},
	{
		id: 18,
		name: "Maine",
		value: "Maine",
	},
	{
		id: 19,
		name: "Maryland",
		value: "Maryland",
	},
	{
		id: 20,
		name: "Massachusetts",
		value: "Massachusetts",
	},
	{
		id: 21,
		name: "Michigan",
		value: "Michigan",
	},
	{
		id: 22,
		name: "Minnesota",
		value: "Minnesota",
	},
	{
		id: 23,
		name: "Mississippi",
		value: "Mississippi",
	},
	{
		id: 24,
		name: "Missouri",
		value: "Missouri",
	},
	{
		id: 25,
		name: "Montana",
		value: "Montana",
	},
	{
		id: 26,
		name: "Nebraska",
		value: "Nebraska",
	},
	{
		id: 27,
		name: "Nevada",
		value: "Nevada",
	},
	{
		id: 28,
		name: "New Hampshire",
		value: "New Hampshire",
	},
	{
		id: 29,
		name: "New Jersey",
		value: "New Jersey",
	},
	{
		id: 30,
		name: "New Mexico",
		value: "New Mexico",
	},
	{
		id: 31,
		name: "New York",
		value: "New York",
	},
	{
		id: 32,
		name: "North Carolina",
		value: "North Carolina",
	},
	{
		id: 33,
		name: "North Dakota",
		value: "North Dakota",
	},
	{
		id: 34,
		name: "Ohio",
		value: "Ohio",
	},
	{
		id: 35,
		name: "Oklahoma",
		value: "Oklahoma",
	},
	{
		id: 36,
		name: "Oregon",
		value: "Oregon",
	},
	{
		id: 37,
		name: "Pennsylvania",
		value: "Pennsylvania",
	},
	{
		id: 38,
		name: "Rhode Island",
		value: "Rhode Island",
	},
	{
		id: 39,
		name: "South Carolina",
		value: "South Carolina",
	},
	{
		id: 40,
		name: "South Dakota",
		value: "South Dakota",
	},
	{
		id: 41,
		name: "Tennessee",
		value: "Tennessee",
	},
	{
		id: 42,
		name: "Texas",
		value: "Texas",
	},
	{
		id: 43,
		name: "Utah",
		value: "Utah",
	},
	{
		id: 44,
		name: "Vermont",
		value: "Vermont",
	},
	{
		id: 45,
		name: "Virginia",
		value: "Virginia",
	},
	{
		id: 46,
		name: "Washington",
		value: "Washington",
	},
	{
		id: 47,
		name: "West Virginia",
		value: "West Virginia",
	},
	{
		id: 48,
		name: "Wisconsin",
		value: "Wisconsin",
	},
	{
		id: 49,
		name: "Wyoming",
		value: "Wyoming",
	},
]
