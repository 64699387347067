import React from "react"
import { Bar } from "react-chartjs-2"
import { DotsLoader } from "../Loader"
import styles from "./index.module.scss"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import { useQuery } from "@tanstack/react-query"
import { api } from "../../services/api"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
	indexAxis: "y",
	elements: {
		bar: {
			borderWidth: 0,
		},
	},
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: false,
		},
	},
	scales: {
		y: {
			grid: {
				display: false,
			},
			ticks: {
				crossAlign: "far",
			},
		},
		x: {
			grid: {
				display: false,
			},
			ticks: {
				precision: 0,
			},
		},
	},
}

export const ChartBar = () => {
	const chartDataQuery = useQuery({
		queryKey: ["ChartData"],
		queryFn: () => api.fetchData("ChartData"),
	})

	const colors = {
		high: "#E02020",
		significant: "#F7B500",
		minor: "#6DD400",
		negligible: "#2EE6CA",
		moderate: "#FAFA00",
	}

	return chartDataQuery.isSuccess ? (
		<Bar
			height={200}
			options={options}
			data={{
				labels: chartDataQuery.data.chartData["labels"],
				datasets: [
					{
						barPercentage: 0.3,
						label: "Risk",
						data: chartDataQuery.data.chartData["data"],
						borderColor: chartDataQuery.data.chartData["colors"]?.map(c => colors[c]),
						backgroundColor: chartDataQuery.data.chartData["colors"]?.map(c => colors[c]),
					},
				],
			}}
		/>
	) : (
		<div className={styles.loaderContainer}>
			<DotsLoader />
		</div>
	)
}
