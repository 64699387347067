import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import styles from "./index.module.scss"
import cn from "classnames"
import { v4 as uuid } from "uuid"

// components
import { Btn } from "../../ui/Btn"
import { Loader } from "../../ui/Loader"
import { Input } from "../../ui/Input"
// hooks
import { useInput } from "../../hooks/useInput"
import { useDispatch } from "react-redux"
// Functions
import { checkErrors } from "../../functions/checkErrors"
// Utils
import { setCookie } from "../../utils/cookie"
// slices
import {
	setAccountPlan,
	setIsAuth,
	setNotificationNabr,
	setQrCode,
	setTeamSize,
	setUserInfo,
} from "../../../store/slices/userSlice"
import { addNotification, removeNotification } from "../../../store/slices/notificationsSlice"

// axois
import { axiosInstance } from "../../config/https"

// QRCode
import QRCode from "qrcode.react"

export const TwoFactor = () => {
	const code = useInput()
	const state = useLocation()
	const [dataState, setDataState] = useState(null)
	const [path, setPath] = useState(null)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [login, setLogin] = useState(false)
	const [errors, setErrors] = useState({})

	const TwoFactor = async () => {
		setLogin(true)
		const inputsInfo = [{ el: code, errorName: "code", errorText: "Enter Code" }]

		if ((await checkErrors(setErrors, inputsInfo)) && !path) {
			setLogin(false)
			return
		}
		const data = {
			email: dataState.email,
			code: code.value,
		}

		await axiosInstance
			.post(path, data, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(async res => {
				setCookie("access_token", res.data.access_token, res.data.expires_in, true)
				dispatch(setUserInfo({ ...res.data.user }))
				dispatch(setQrCode(res.data.qr_code_url))
				dispatch(setAccountPlan(res.data?.account_plan))
				dispatch(setNotificationNabr(res.data.notifications_nbr))
				dispatch(setTeamSize(res.data.team_size))
				dispatch(setIsAuth(true))
				navigate("/dashboard")
			})
			.catch(error => {
				setErrors({ code: "The given data was invalid." })
				setErrors({ email: "The given data was invalid." })
				const errorID = uuid()
				dispatch(addNotification({ id: errorID, type: "error", text: error.response.data.message }))
				setTimeout(() => {
					dispatch(removeNotification({ id: errorID }))
				}, 3000)
			})
			.finally(() => {
				setLogin(false)
			})
	}

	useEffect(() => {
		setDataState(state?.state)
		setPath(state?.state?.Two_Factor === "google" ? "login/verify2fa" : "login/verify")
	}, [setDataState, state])
	return (
		<div className={styles.container}>
			<div className={styles.form}>
				<div className={styles.logo}></div>
				{dataState?.qr_code_url ? (
					<div>
						<p className={styles.headerStyleText}>Scan the QR code below with Google Authenticator:</p>
						<QRCode value={dataState?.qr_code_url} />
						<div className={styles.loginInputs}>
							<Input
								onChange={code.onChange}
								type={"number"}
								className={styles.input}
								placeholder={"Two Factor Code"}
								error={errors.code}
							/>
						</div>
					</div>
				) : (
					<>
						<div className={styles.headerStyleText}>
							{" "}
							Enter Your Two Factor Code :{" "}
							{state?.state?.Two_Factor?.charAt(0)?.toUpperCase() +
								state?.state?.Two_Factor?.slice(1, (state?.state?.Two_Factor).length)?.toLowerCase()}
						</div>

						<div className={styles.loginInputs}>
							<Input
								onChange={code.onChange}
								type={"number"}
								className={styles.input}
								placeholder={"Two Factor Code"}
								error={errors.code}
							/>
						</div>
					</>
				)}
				<div className={styles.loginBtn}>
					<Btn
						onClick={TwoFactor}
						className={cn(styles.btn, styles.btnSubmit)}
						disable={login}
					>
						<div className={styles.submitText}>Login</div>
						{login && <Loader />}
					</Btn>
				</div>
				<div className={styles.signUpLink}>
					Don't have an account? <Link to={"/auth/register"}>Create account</Link>
				</div>
				<div className={styles.signUpLink}>
					<Link to={"/auth/forgot-password"}>Forgot your password?</Link>
				</div>

				<footer className={styles.footer}>
					<nav>
						<ul className={styles.footerNav}>
							<li>
								<a href="https://app.termly.io/document/terms-of-use-for-website/f78e9e9b-bba1-4a94-983e-68a1005f28b0">
									Terms of Service
								</a>
							</li>
							<li>
								<a href="https://app.termly.io/document/privacy-policy/3754276d-7c3f-40ef-931d-584c9bc61e74">
									Privacy Policy
								</a>
							</li>
						</ul>
					</nav>
					<br></br>
					<h5>© 2023 SecureLabs Inc </h5>
				</footer>
			</div>
		</div>
	)
}
