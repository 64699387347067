import React, { useCallback, useEffect, useState } from "react"
import styles from "./index.module.scss"
import cn from "classnames"
import { Btn } from "../../ui/Btn"
import { Search } from "../../ui/Search/Search"
import { useDispatch } from "react-redux"
import { axiosInstance } from "../../config/https"
import { toast } from "../../functions/toast"
import { SvgSprite } from "../../ui/SvgSprite"
import { DotsLoader, Loader } from "../../ui/Loader"
import { useLocation, useNavigate } from "react-router-dom"
import { getControlSubmissions } from "../../functions/getControlSubmission"
import { Popup } from "../../ui/Popup"
import { setControl } from "../../../store/slices/controlSlice"

export const ControlsAssigned = () => {
	const [controls, setControls] = useState(null)
	const [nbrControls, setNbrControls] = useState(0)
	const [sortItem, setSortItem] = useState("default")
	const [searchData, setSearchData] = useState([])
	const [search, setSearch] = React.useState("")
	const [selectedItems, setSelectedItems] = useState([])
	const [loader, setLoader] = useState(false)
	const [data, setData] = useState()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const dataAssigned = location.state
	const [showAssignControls, setShowAssignControls] = useState(false)
	const compareBy = key => {
		return function (a, b) {
			if (a[key] < b[key]) return -1
			if (a[key] > b[key]) return 1
			return 0
		}
	}

	const sortBy = (key, direc) => {
		let arrayCopy = [...controls]
		if (direc === -1) {
			arrayCopy.sort(compareBy(key)).reverse()
		} else {
			arrayCopy.sort(compareBy(key))
		}
		setData([...arrayCopy])
	}

	const svgDeselect = (
		<SvgSprite
			className={styles.svgIconsEmpty}
			spriteID={"list"}
		></SvgSprite>
	)
	const svgSelect = (
		<SvgSprite
			className={styles.CustomSvgIcons}
			spriteID={"check"}
		></SvgSprite>
	)

	const getAllControls = useCallback(() => {
		axiosInstance("controls")
			.then(res => {
				setControls(res.data.controls)
				setNbrControls(res.data.count)
			})
			.catch(err => {
				dispatch(toast("error", err?.response?.data?.message))
			})
	}, [dispatch])

	useEffect(() => {
		Promise.all([getAllControls()])
	}, [getAllControls])

	useEffect(() => {
		setSearchData(controls?.filter(item => item?.name?.toLowerCase()?.includes(search?.toLowerCase())))
	}, [controls, search])

	const assignControls = async () => {
		setLoader(true)
		const postData = {
			owner: dataAssigned.owner,
			stakeholders: dataAssigned.stakeholders,
			controls: selectedItems,
		}

		var path = dataAssigned.bulk.remove ? "controls/bulk/remove" : "controls/bulk/assign"

		await axiosInstance
			.post(path, postData)
			.then(async res => {
				await dispatch(getControlSubmissions())
				navigate(-1)
				dispatch(toast("success", res.data.message))
			})
			.catch(err => {
				dispatch(toast("error", err?.response?.data?.message))
			})
			.finally(() => {
				setLoader(false)
			})
	}

	const controlnonApplicable = async () => {
		setLoader(true)
		const postData = {
			status: true,
			controlsIds: selectedItems,
		}
		await axiosInstance
			.post("controls/nonApplicable", postData)
			.then(async res => {
				dispatch(setControl(controls.filter(c => !selectedItems.includes(c.id))))
				navigate(-1)
				dispatch(toast("success", res.data.message))
			})
			.catch(err => {
				dispatch(toast("error", err?.response?.data?.message))
			})
			.finally(() => {
				setLoader(false)
			})
	}

	return (
		<div className={styles.main}>
			<div className={cn(styles.header, styles.headerDocument)}>
				<div className={cn(styles.rieghtHeader, styles.rieghtHeaderHiegth)}>
					<div className={styles.titleCustomFramework}>All Controls</div>
				</div>
				<div className={styles.leftHeader}>
					<Btn
						disable={loader}
						className={styles.addBtn}
						onClick={() => {
							dataAssigned.nonApplicable ? controlnonApplicable() : setShowAssignControls(true)
						}}
					>
						{loader ? <Loader /> : "Save"}
					</Btn>
				</div>
			</div>
			<div className={styles.subHeader}>
				<div className={styles.controls}>
					{selectedItems?.length} / {nbrControls} Controls
				</div>
				<div className={styles.gridSearch}>
					<div className={styles.search}>
						<Search
							setSearch={setSearch}
							className={styles.search}
							placeholder={"Search Controls"}
						/>
					</div>
				</div>
				<div className={styles.sort}>
					Sort By:
					<div
						className={cn(styles.sortItem, { [styles.sortItemActive]: sortItem === "default" })}
						onClick={() => {
							setSortItem("default")
							sortBy("default")
						}}
					>
						Default
					</div>
					<div
						className={cn(styles.sortItem, { [styles.sortItemActive]: sortItem === "A-Z" })}
						onClick={() => {
							setSortItem("A-Z")
							sortBy("name")
						}}
					>
						A-Z
					</div>
					<div
						className={cn(styles.sortItem, { [styles.sortItemActive]: sortItem === "Z-A" })}
						onClick={() => {
							setSortItem("Z-A")
							sortBy("name", -1)
						}}
					>
						Z-A
					</div>
				</div>
			</div>
			<div className={styles.body}>
				<div className={styles.table}>
					<div className={styles.tableHeader}>
						<div className={cn(styles.tableAction, styles.newFrameworkAction)}>Select</div>
						<div className={cn(styles.name, styles.nameHeader, styles.newFrameworkName, styles.newFrameworkNameAssign)}>
							Name
						</div>
						<div className={cn(styles.description, styles.newFrameworkDescrption)}>Description</div>
					</div>
					<div className={styles.TableBody}>
						{!controls ? (
							<div className={styles.bodyDataControls}>
								<DotsLoader />
							</div>
						) : (
							(search ? searchData : data || controls).map((c, i) => (
								<div
									key={i}
									className={cn(styles.row, styles.newFrameworkRow)}
									onClick={() => {
										if (!selectedItems.includes(c?.id)) {
											setSelectedItems([...selectedItems, c?.id])
										} else {
											setSelectedItems(selectedItems.filter(selectedItem => selectedItem !== c?.id))
										}
									}}
								>
									<div className={cn(styles.tableAction, styles.newFrameworkAction)}>
										{selectedItems.includes(c?.id) ? svgSelect : svgDeselect}
									</div>
									<div className={cn(styles.name, styles.newFrameworkName)}>{c?.name}</div>
									<div className={cn(styles.description, styles.newFrameworkDescrption)}>
										<div
											dangerouslySetInnerHTML={{
												__html: c?.description,
											}}
										/>
									</div>
								</div>
							))
						)}
					</div>
				</div>
			</div>
			<Popup
				small={true}
				isOpenedPopup={showAssignControls}
				closePopup={() => {
					setShowAssignControls(false)
				}}
			>
				<div className={styles.divRepository}>
					<p className={styles.repoMessage}>
						{dataAssigned?.bulk &&
							(dataAssigned?.bulk?.remove
								? "Are you sure you want to remove the specified owner and stakeholders from the selected controls?"
								: "By selecting a new owner and stakeholders, any existing associations with this control will be erased.")}
						<br />
						<br /> <span className={styles.spanRepoMessage}>Are you sure you want to proceed?</span>
					</p>
					<div className={styles.repoBtns}>
						<Btn
							disable={loader}
							className={styles.repositoryBtn}
							onClick={() => setShowAssignControls(false)}
						>
							{" "}
							Cancel{" "}
						</Btn>
						<Btn
							disable={loader}
							className={cn(styles.repositoryBtn, styles.repoDeleteBtn)}
							onClick={() => assignControls()}
						>
							{" "}
							{loader ? <Loader /> : "Save"}{" "}
						</Btn>
					</div>
				</div>
			</Popup>
		</div>
	)
}
