import React from "react"
import styles from "./index.module.scss"
import cn from "classnames"
export const FileType = ({ type, onClick }) => {
	return (
		<div
			onClick={onClick}
			className={cn(styles.fileTypeCard, styles.notFound)}
		>
			{type}
		</div>
	)
}
