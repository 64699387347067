import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	controlSubmission: {},
	isLoading: false,
}

const controlSubmissionSlice = createSlice({
	name: "controlSubmission",
	initialState,
	reducers: {
		setControlSubmissionIsLoading(state, action) {
			state.isLoading = action.payload
		},

		setControlSubmission(state, action) {
			state.controlSubmission = action.payload
		},
	},
})

export default controlSubmissionSlice.reducer
export const { setControlSubmissionIsLoading, setControlSubmission } = controlSubmissionSlice.actions
