import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { InviteRegister } from "../core/modules/InviteRegister"

// Pages
import { Login } from "../core/modules/Login"
import { TwoFactor } from "../core/modules/Login/TwoFactor"
import { Register, RegisterSocial } from "../core/modules/Register"
import { ForgotPassword } from "../core/modules/ResetPassword/ForgotPassword"
import { ResetPassword } from "../core/modules/ResetPassword/ResetPassword"
import { VerifyEmail } from "../core/modules/VerifyEmail/VerifyEmail"
import { Subscription } from "../core/modules/Payment"
import { GoogleCallback } from "../core/modules/GoogleCallback/GoogleCallback"
// import { NotFound } from '../core/status/NotFound'

export const AuthRoutes = () => {
	return (
		<Routes>
			<Route
				path="/auth/login"
				element={<Login />}
			/>
			<Route
				path="/auth/register"
				element={<Register />}
			/>
			<Route
				path="/auth/payment"
				element={<Subscription />}
			/>
			<Route
				path="/auth/invite/register"
				element={<InviteRegister />}
			/>
			<Route
				path="/auth/verify-email"
				element={<VerifyEmail />}
			/>
			<Route
				path="/reset-password"
				element={<ResetPassword />}
			/>
			<Route
				path="/auth/forgot-password"
				element={<ForgotPassword />}
			/>
			<Route
				path="/auth/login/verify"
				element={<TwoFactor />}
			/>
			<Route
				path="auth/google/callback"
				element={<GoogleCallback provider={"google"} />}
			/>
			<Route
				path="auth/microsoft/callback"
				element={<GoogleCallback provider={"microsoft"} />}
			/>
			<Route
				path="/auth/social/register"
				element={<RegisterSocial />}
			/>

			{/* <Route path='/404' element={<NotFound/>} exact/> */}
			<Route
				path="*"
				element={
					<Navigate
						to="/auth/login"
						replace
					/>
				}
			/>
		</Routes>
	)
}
