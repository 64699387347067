import React, { useEffect } from "react"
import styles from "./index.module.scss"
import cn from "classnames"

// components
import { Navbar } from "../Navbar"
import { Sidebar } from "../Sidebar/Sidebar"
import { useDispatch, useSelector } from "react-redux"
import { setOrgsData } from "../../functions/setOrgData"
import { MainRoutes } from "../../../routes/MainRoutes"
import { useLocation } from "react-router-dom"
import { AuditorRoutes } from "../../../routes/AuditorRoutes"

export const DashboardWrapper = ({ expanded, setExpanded }) => {
	const dispatch = useDispatch()
	const org = useSelector(state => state.organization.org)
	const location = useLocation()
	const path = location.pathname
	const role = useSelector(state => state.user.userInfo.role)

	useEffect(() => {
		(async () => {
			await Promise.all([!org?.id && dispatch(setOrgsData())])
		})()
	}, [dispatch, org])

	return (
		<div className={styles.container}>
			<div className={styles.main}>
				<div className={styles.content}>
					<div className={styles.navbar}>
						<Navbar
							expanded={expanded}
							setExpanded={setExpanded}
						/>
					</div>
					<div className={styles.pages}>
						<Sidebar
							expanded={expanded}
							role={role}
						/>
						<div className={cn(styles.body, { [styles.noMargin]: path.startsWith("/help") })}>
							{role === "auditor" ? <AuditorRoutes /> : <MainRoutes />}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
