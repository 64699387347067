import { useState } from 'react'
import { axiosInstance } from '../../../config/https'
import { toast } from '../../../functions/toast'

export const useResourceSearch = (dispatch) => {
  const [searchResults, setSearchResults] = useState(null)
  const [isSearching, setIsSearching] = useState(false)

  const searchResources = async (query) => {
    if (!query.trim() || query.trim().length < 2) {
      setSearchResults(null)
      return
    }

    setIsSearching(true)
    try {
      const response = await axiosInstance.get(`/resources/search?query=${encodeURIComponent(query)}`)
      if (response.data.success) {
        const transformedResults = response.data.data.map(resource => ({
          ...resource,
          repository: resource.repositories?.[0] || null,
          extension: resource.policyDocument.split(".").pop(),
          category: resource.repositories?.[0]?.name || "Uncategorized"
        }))
        setSearchResults(transformedResults)
      } else {
        dispatch(toast('error', 'Search failed'))
        setSearchResults([])
      }
    } catch (err) {
      dispatch(toast('error', err?.response?.data?.message || 'Search failed'))
      setSearchResults([])
    } finally {
      setIsSearching(false)
    }
  }

  const clearSearch = () => {
    setSearchResults(null)
  }

  return {
    searchResults,
    isSearching,
    searchResources,
    clearSearch
  }
} 